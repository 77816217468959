import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { carrierService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { Carrier as CarrierType } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
import Box from "/app/src/components/generic/components/box";
import { useHandleNavigationError } from "/app/src/helpers/api";

export default function Carrier() {
  const { t } = useTranslation();
  const { carrierId } = useParams();
  const handleNavigationError = useHandleNavigationError();

  // Get the single Carrier from URL
  const { data: carrier } = useQuery({
    queryKey: ["carrier", carrierId],
    queryFn: () => {
      return carrierService.getSingle(carrierId).catch(() => {
        handleNavigationError();
      });
    },
    initialData: { carrier: {} },
    retry: false,
    select: (data: { carrier: CarrierType }) => {
      return data.carrier;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{carrier?.name}</title>
      </Helmet>
      {carrier && (
        <Box>
          <h1>
            {t("translation:carrier")}:{carrier?.name}
          </h1>
          <div className="bigItems">
            <div className="bigItem">
              <h4>{t("translation:number")}</h4>
              <span>{carrier?.number}</span>
            </div>
            <div className="bigItem">
              <h4>{t("translation:shelves")}</h4>
              {carrier?.shelves &&
                carrier?.shelves.map((shelf) => (
                  <span key={shelf.id}>{shelf.name}</span>
                ))}
            </div>
          </div>
        </Box>
      )}
    </div>
  );
}
