import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, Select } from "formik-antd";
import { Integration } from "/app/src/models";
import { simpleSchemaBuilder } from "/app/src/helpers";

import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IconToolTip } from "/app/src/components/icons/IconBuilder";
import useSetting from "/app/src/components/settings/setting";

interface FormValues {
  value: string | undefined;
}

/**
 * Component to display the default direction type setting for a single Data Pull Integration
 */
export default function DefaultDirection({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();
  const { settingValue, createUpdateSetting } = useSetting({
    integrationId: integration.id,
    type: "directionType",
  });
  const { settingValue: autoDetectValue } = useSetting({
    integrationId: integration.id,
    type: "autoDetectDirection",
  });

  const onSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      await createUpdateSetting({
        ...values,
        type: "directionType",
        integrationId: integration.id,
      }).then(() => {
        actions.resetForm();
      });
    },
    [createUpdateSetting, integration.id],
  );
  const labelContent = useMemo(
    () => (
      <>
        {t("translation:default_direction_type")}
        <IconToolTip
          content={t("translation:default_direction_tip")}
          className="ml-1"
        />
      </>
    ),
    [t],
  );
  const confirmationForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ isSubmitting, dirty }) => (
        <Form layout="vertical">
          <Row gutter={16}>
            <Col span={20}>
              <Form.Item name="value" label={labelContent}>
                <Select name="value" size="large">
                  <Select.Option value="2">
                    {t("translation:pick_default")}
                  </Select.Option>
                  <Select.Option value="1">
                    {t("translation:put")}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || isSubmitting}
                style={{ marginTop: "30px" }}
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [labelContent, t],
    );
  if (!["1", "2"].includes(autoDetectValue)) {
    return (
      <Formik
        initialValues={{
          value: settingValue,
        }}
        validationSchema={simpleSchemaBuilder([
          { name: "value", type: "string", required: true },
        ])}
        onSubmit={onSubmit}
        component={confirmationForm}
        enableReinitialize
      />
    );
  }
  return null;
}
