//updates an object in a state array
export function updateObjectInStateArray(
  objectId: number | string,
  data: { [key: string]: any },
  stateArray: { [key: string]: any }[],
  setStateArray: (stateArray: { [key: string]: any }[]) => void,
  updateObject: (
    objectId: number | string,
    data: { [key: string]: any },
  ) => Promise<any>,
) {
  let newObject: { id?: number } = {};
  //update object
  return updateObject(objectId, data).then((response: any) => {
    if (response?.errors) {
      return response;
    } else {
      newObject = response[Object.keys(response)[0]];
      //create copy of state array
      const newArray = [...stateArray];
      //find index of updated object
      const index = newArray.findIndex((filter) => {
        return filter.id === newObject?.id;
      });
      //replace old object with updated object
      newArray[index] = newObject;

      setStateArray(newArray);
      return response;
    }
  });
}

export function deleteObjectInStateArray(
  object: { [key: string]: any },
  stateArray: { [key: string]: any }[],
  setStateArray: (stateArray: { [key: string]: any }[]) => void,
  deleteObject?: (id: number) => Promise<any>,
) {
  //check if delete object function is passed
  if (deleteObject !== undefined) {
    return deleteObject(object.id).then((response) => {
      if (response?.deleted) {
        setStateArray(stateArray.filter((o) => o.id !== object.id));
        return response;
      }
    });
  } else {
    setStateArray(stateArray.filter((o) => o.id !== object.id));
  }
  return null;
}

export function addObjectInStateArray(
  data: { [key: string]: any },
  stateArray: { [key: string]: any }[],
  setStateArray: (stateArray: { [key: string]: any }[]) => void,
  createObject: (data: { [key: string]: any }) => Promise<any>,
  toFront = false,
) {
  return createObject(data).then((response: any) => {
    if (response?.errors) {
      return response;
    } else {
      if (toFront) {
        setStateArray([response[Object.keys(response)[0]], ...stateArray]);
      } else {
        setStateArray([...stateArray, response[Object.keys(response)[0]]]);
      }
      return response;
    }
  });
}
