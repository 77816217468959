import { Integration, Mapping } from "/app/src/models";
import NewConfirmationMapping from "./newConfirmationMapping";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { mappingService } from "/app/src/services";
import { handlePromiseError } from "/app/src/helpers/api";
import EditMappings from "/app/src/components/generic/components/apps/data/mappings/editMappings";
/**
 * Component to edit a group of mappings and add a new mapping to the group of mappings
 * Used for the parentless confirmation mappings and child mappings of a grouping confirmation mapping
 */
export default function ConfirmationMappings({
  integration,
}: {
  integration: Integration;
}) {
  const queryClient = useQueryClient();

  const { mutateAsync: addMapping } = useMutation({
    mutationFn: (mapping: Omit<Mapping, "parentMapping" | "children">) => {
      return mappingService
        .createSingle({ ...mapping, integrationId: integration.id })
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["confirmationMappings", integration.id],
        (oldData: { mappings: Mapping[] }) => {
          return {
            mappings: [...oldData.mappings, response.mapping],
          };
        },
      );
    },
  });

  return (
    <>
      <EditMappings
        integration={integration}
        mappingsType={"confirmationMappings"}
      />
      <div className="newMapping">
        <NewConfirmationMapping
          addMapping={addMapping}
          integrationId={integration.id}
        />
      </div>
    </>
  );
}
