export function convertStringToBoolean(string: string | undefined) {
  if (string === undefined) {
    return false;
  }
  switch (string.toLowerCase().trim()) {
    case "true":
    case "yes":
    case "1":
      return true;
    case "false":
    case "no":
    case "0":
    case null:
      return false;
    default:
      return Boolean(string);
  }
}

export function convertIntegerToBoolean(integer: number) {
  switch (integer) {
    case 1:
      return true;
    case 0:
    case null:
      return false;
    default:
      return Boolean(integer);
  }
}

/**
 * Converts a string representation of a number to a percentage string.
 *
 * @param value - The string to be converted to a percentage.
 * @param decimalPlaces - The number of decimal places to include in the result. Defaults to 1.
 * @returns A string representing the percentage value with the specified number of decimal places, or "N/A" if the input is not a valid number.
 */
export function convertStringToPercentage(value: string, decimalPlaces = 1) {
  const percentageValue = parseFloat(value);
  return isNaN(percentageValue)
    ? "N/A"
    : `${percentageValue.toFixed(decimalPlaces)}%`;
}

/**
 * Converts a string to a decimal number with a specified number of decimal places.
 *
 * @param value - The string to be converted to a decimal number.
 * @param decimalPlaces - The number of decimal places to format the decimal number to. Defaults to 2.
 * @returns The formatted decimal number as a string, or "N/A" if the input string cannot be converted to a number.
 */
export function convertStringToDecimal(value: string, decimalPlaces = 2) {
  const decimalValue = parseFloat(value);
  return isNaN(decimalValue) ? "N/A" : decimalValue.toFixed(decimalPlaces);
}

export function convertDirectionType(number: string) {
  switch (parseInt(number)) {
    case 1:
      return "Put";
    case 2:
      return "Pick";
    case 3:
      return "Return";
    case 4:
      return "Transfer";
    case 5:
      return "Count";

    default:
      return "";
  }
}

/**
 * Converts a boolean to a string. If the passed in value is true,
 * it will return "True", otherwise it will return "False"
 */
export function convertBooleanToString(boolean: boolean) {
  return boolean ? "True" : "False";
}
/**
 * Converts array of strings to comma separated string. If
 * the passed in value is not an array, it will be returned
 * @param array Array of strings or string
 * @returns Comma separated string or the passed in value
 */
export function convertArrayToCSV(array: string[] | string) {
  if (Array.isArray(array)) {
    return array.join(",");
  }
  return array;
}

/**
 * Converts a comma separated string to an array of strings.
 * Trims the whitespace from each item in the array
 * @param csv the comma separated string
 * @returns Array of strings
 */
export function convertCSVtoArray(csv: string) {
  if (!csv) {
    return [];
  }
  return csv.split(",").map((item) => item.trim());
}

export function convertArrayToSemicolonSeparated(array: string[] | string) {
  if (Array.isArray(array)) {
    return array.join(";");
  }
  return array;
}

export function convertSemicolonSeparatedToArray(value: string) {
  if (!value) {
    return [];
  }
  return value.split(";").map((item) => item.trim());
}
