import { useFormikContext } from "formik";
import { Cascader } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
interface Option {
  value: string;
  label: string;
  children?: Option[];
}
interface FormValues {
  name: string | undefined;
  type: string | undefined;
  url: string | undefined;
  authentication: string | undefined;
  username: string | undefined;
  password: string | undefined;
  token: string | undefined;
}
/**
 * This component is a cascader field for selecting the type of connection. When
 * the user selects a type, the value is set in the formik context. This component
 * uses formik context to set the field value of the type field. This is done because
 * cascader has an array type, but we want the type field as a string. It's also done
 * to reset the authentication field when the type field is changed.
 */
export default function TypeField() {
  const { setFieldValue, values } = useFormikContext<FormValues>();
  const [typeValue, setTypeValue] = useState<string[] | undefined>();
  useEffect(() => {
    if (["REST", "GraphQL"].includes(values.type)) {
      setTypeValue(["API", values.type]);
    }
    if (["MSSQL", "IBM2"].includes(values.type)) {
      setTypeValue(["SQL", values.type]);
    }
    setTypeValue([values.type]);
  }, [values.type]);
  const handleOnChange = useCallback(
    (value: string[]) => {
      if (values.type !== value[value.length - 1])
        setFieldValue("type", value[value.length - 1]);
      setFieldValue("authentication", undefined);
    },
    [setFieldValue, values.type],
  );
  const options: Option[] = useMemo(
    () => [
      {
        value: "API",
        label: "API",
        children: [
          {
            value: "REST",
            label: "REST",
          },
          {
            value: "GraphQL",
            label: "GraphQL",
          },
        ],
      },
      {
        value: "SQL",
        label: "Database",
        children: [
          { value: "MSSQL", label: "MSSQL" },
          { value: "IBM2", label: "IBM DB2" },
        ],
      },
    ],
    [],
  );

  return (
    <div style={{ marginBottom: "24px" }}>
      <div style={{ paddingBottom: "8px" }}>
        <label>{"Type"}</label>
      </div>
      <Cascader
        size="large"
        style={{ width: "100%" }}
        options={options}
        onChange={handleOnChange}
        value={typeValue}
      />
    </div>
  );
}
