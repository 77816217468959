import React, { useCallback, useEffect, useState } from "react";
import { Row, Col } from "antd";
import SerialNumbers from "./serialNumbers";
import { useTranslation } from "react-i18next";

type Line = {
  serialNumber?: number | string;
  locationId?: string;
  locationName?: string;
  materialName?: string;
};

//this function will group the array of serialNumber objects by consecutive numbers -
//note if a consecutive serial number is from a location found previously, then it will
//create a new grouping. Eg. {Location 1, serialNumber: 1}, {Location 2: serialNumber: 2}, {Location 1: serialNumber: 3}
//will be split into two groups : [{Location 1, serialNumber: 1}, {Location 2: serialNumber: 2}], [{Location 1: serialNumber: 3}]
function GroupByConsecutiveNumber(serialNumbers: Line[]) {
  const result = [];
  let temp = [];
  let difference;
  let locationsC: string[] = [];
  let currLocation: string | undefined;
  let prevLocation: string | undefined;
  for (let i = 0; i < serialNumbers?.length; i += 1) {
    currLocation = serialNumbers[i]["locationName"];
    if (difference !== (serialNumbers[i]["serialNumber"] as number) - i) {
      if (difference !== undefined) {
        //not consecutive serial number, start new grouping
        result.push(temp);
        temp = [];
        prevLocation = undefined;
        currLocation = undefined;
        locationsC = [];
      }
      difference = (serialNumbers[i]["serialNumber"] as number) - i;
    } else if (currLocation !== prevLocation) {
      if (currLocation !== undefined && prevLocation !== undefined) {
        //if this location was previously found, then this should be included in a separate grouping
        if (locationsC.includes(currLocation)) {
          result.push(temp);
          temp = [];
          prevLocation = undefined;
          currLocation = undefined;
          locationsC = [];
        }
      }
    }
    if (currLocation !== undefined && !locationsC.includes(currLocation)) {
      locationsC.push(currLocation);
    }
    temp.push(serialNumbers[i]);
    prevLocation = currLocation;
  }

  if (temp.length) {
    result.push(temp);
  }
  return result;
}

function sortSerialNumbers(
  serialNumbers: Line[],
): { name: string; numbers: number[] }[] {
  //first sort by locationId, then sort by consecutive serial numbers
  const groupedByLocation: { [key: string]: (string | number)[] } = {};

  //for mapping the location id to the location name
  const locationIdToName: { [key: string]: string } = {};

  serialNumbers.forEach((line) => {
    if (!(line.locationId in groupedByLocation)) {
      groupedByLocation[line.locationId] = [line.serialNumber];
      locationIdToName[line.locationId] = line.locationName;
    } else {
      groupedByLocation[line.locationId].push(line.serialNumber);
    }
  });

  //sort by serialNumber
  serialNumbers.sort((a, b) => {
    const aNumber = a["serialNumber"] as number;
    const bNumber = b["serialNumber"] as number;
    return aNumber - bNumber;
  });
  //convert array of objects into 2d array containing groupings of
  //consecutive serial numbers and by location
  const groupedNumbers = GroupByConsecutiveNumber(serialNumbers);

  const finalSorted: {
    name: string;
    numbers: (string | number)[];
  }[][] = [];
  //group groupings by location
  groupedNumbers.forEach((grouping) => {
    const groupedLocations = Object.entries(
      grouping.reduce(
        (acc, { locationName, serialNumber }) => {
          // Group initialization
          if (!(locationName in acc)) {
            acc[locationName] = [serialNumber];
          } else {
            acc[locationName].push(serialNumber);
          }
          return acc;
        },
        {} as { [key: string]: (string | number)[] },
      ),
    ).map(([name, numbers]) => ({ name, numbers }));

    finalSorted.push(
      groupedLocations as {
        name: string;
        numbers: (string | number)[];
      }[],
    );
  });

  //flatten 2d array into a 1d array
  const ret = [] as { name: string; numbers: (string | number)[] }[];
  return ret.concat(...finalSorted) as { name: string; numbers: number[] }[];
}

/**
 * Displays and manages materials and their serial numbers.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.material - The name of the material.
 * @param {Line[]} [props.serialNumbers] - The list of serial numbers.
 * @param {Function} props.changeMaterial - Function to change the current material.
 * @param {number} props.index - The index of the current material.
 * @param {number} props.activeMaterial - The index of the active material.
 * @param {Function} props.nextMaterial - Function to proceed to the next material.
 */
export default function Material({
  material,
  serialNumbers,
  changeMaterial,
  index,
  activeMaterial,
  nextMaterial,
}: {
  material: string;
  serialNumbers?: Line[];
  changeMaterial: (index: number) => void;
  index: number;
  activeMaterial: number;
  nextMaterial: () => void;
}) {
  const { t } = useTranslation();
  const [sortedNumbers, setSortedNumbers] = useState<
    { name: string; numbers: number[] }[]
  >([{ name: "", numbers: [] }]);
  //current grouped tracks the active grouping of serial numbers
  const [currentGrouped, setCurrentGrouped] = useState(0);

  useEffect(() => {
    //go to the next material once the last current grouped is done
    if (currentGrouped === sortedNumbers.length) {
      nextMaterial();
    }
  }, [currentGrouped, sortedNumbers, nextMaterial]);

  useEffect(() => {
    if (index === activeMaterial) {
      setCurrentGrouped(0);
    } else {
      setCurrentGrouped(-1);
    }
  }, [index, activeMaterial]);

  const setGrouping = useCallback(
    (i: number) => {
      changeMaterial(index);
      setCurrentGrouped(i);
    },
    [changeMaterial, index],
  );

  useEffect(() => {
    if (serialNumbers) {
      setSortedNumbers(sortSerialNumbers(serialNumbers));
    }
  }, [serialNumbers]);

  return (
    <Col span={24}>
      <Row className="materialName" align="middle">
        <p className="materialText">
          {" "}
          {t("translation:material")}: {material}
        </p>
      </Row>
      <div>
        {sortedNumbers.map((element, index) => (
          <SerialNumbers
            key={element.name}
            index={index}
            serialNumberGroupings={element}
            currentGrouped={currentGrouped}
            setGrouping={setGrouping}
          />
        ))}
      </div>
    </Col>
  );
}
