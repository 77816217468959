export function isAPIType(connectionType: string | undefined) {
  const apiTypes = ["REST", "GraphQL"];
  return Boolean(connectionType && apiTypes.includes(connectionType));
}

/**
 * Check if the connection type is SQL-based
 */
export function isSQLType(connectionType: string | undefined) {
  const sqlTypes = ["MSSQL", "IBM2"];
  return connectionType && sqlTypes.includes(connectionType);
}
