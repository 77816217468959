import { useTranslation } from "react-i18next";
import {
  Integration,
  Mapping,
  ReportColumnType,
  Setting,
} from "/app/src/models";
import { useCallback } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Cascader, Form, Select, SubmitButton } from "formik-antd";
import { cascadeReportColumnTypes } from "/app/src/helpers/cascadeReportColumnTypes";
import { simpleSchemaBuilder } from "/app/src/helpers";
import { useToggle } from "/app/src/hooks";
import NextButton from "/app/src/components/NextUi/Button";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";

interface PrimaryMappingFormValues {
  mappingKey?: [string, number];
  sqlColumnName?: number;
}

/**
 * Component for creating a new join condition for an integration. The form is displayed when the user
 * clicks the "New Join" button.
 * @param param0 integration, reportColumnTypes, mappings, createJoinCondition
 * @returns PrimaryMapping component
 */
export default function NewJoinCondition({
  integration,
  reportColumnTypes,
  mappings,
  createJoinCondition,
}: {
  integration: Integration;
  reportColumnTypes: ReportColumnType[];
  mappings: Mapping[];
  createJoinCondition: (values: Setting) => Promise<void>;
}) {
  const [toggled, setToggled] = useToggle();
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (values: PrimaryMappingFormValues) => {
      return await createJoinCondition({
        value: `${values.mappingKey[1]},${values.sqlColumnName}`,
        type: "joinCondition",
        integrationId: integration.id,
      }).then(() => {
        setToggled();
      });
    },
    [createJoinCondition, integration.id, setToggled],
  );

  const updatePrimaryMappingForm: (
    props: FormikProps<PrimaryMappingFormValues>,
  ) => JSX.Element = useCallback(
    ({ dirty, isSubmitting, isValid }) => (
      <Form layout="vertical">
        <Row justify="start" gutter={16}>
          <Col span={9}>
            <Form.Item
              name="mappingKey"
              label="Mapping Key"
              hasFeedback={false}
            >
              <Cascader
                name="mappingKey"
                size="large"
                className="larger-cascade"
                options={cascadeReportColumnTypes(
                  reportColumnTypes,
                  integration.baseTable,
                )}
                placeholder={t("translation:select_column")}
                showSearch
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              name="sqlColumnName"
              label="SQL Column Name"
              hasFeedback={false}
            >
              <Select size="large" name="sqlColumnName">
                {mappings?.map((mapping) => (
                  <Select.Option key={mapping.id} value={mapping.id}>
                    {mapping.key}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <SubmitButton
              size="large"
              disabled={!dirty || isSubmitting || !isValid}
              loading={isSubmitting}
              type="primary"
              className="w-full mt-[28px]"
            >
              {t("translation:save")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [integration.baseTable, mappings, reportColumnTypes, t],
  );

  return (
    <Row justify="start" gutter={16}>
      <Col span={3}>
        <NextButton
          variant={!toggled ? "solid" : "bordered"}
          color="primary"
          onClick={setToggled}
          className="w-full mt-[28px]"
        >
          {!toggled ? (
            <span className="flex justify-between items-center">
              {t("translation:new_join")}
              <IconBuilder
                className="ml-1"
                icon="ArrowRight"
                color="#ffffff"
                size={16}
              />
            </span>
          ) : (
            t("translation:cancel")
          )}
        </NextButton>
      </Col>
      <Col offset={5} span={16}>
        {toggled && (
          <Formik
            component={updatePrimaryMappingForm}
            initialValues={{
              mappinKey: undefined,
              sqlColumnName: undefined,
            }}
            onSubmit={onSubmit}
            enableReinitialize
            validationSchema={simpleSchemaBuilder([
              { name: "mappingKey", type: "array", required: true },
              { name: "sqlColumnName", type: "number", required: true },
            ])}
          />
        )}
      </Col>
    </Row>
  );
}
