import React from "react";
import { extendVariants, Select, SelectItem } from "@nextui-org/react";
import getDisabledStyle from "./helpers/disabledState";

export const CustomSelect = extendVariants(Select, getDisabledStyle("base"));

type SelectedItemProps<T> = {
  key?: React.Key;
  props?: Record<string, any>;
  data?: T | null;
  "aria-label"?: string;
  rendered?: React.ReactNode;
  textValue?: string;
  type?: string;
};

type SelectedItems<T> = Array<SelectedItemProps<T>>;

interface NextSelectProps {
  value?: string;
  items: {
    key: string | number;
    value: string | number;
    label: React.ReactNode;
  }[];
  selectionMode?: "single" | "multiple";
  selectedKeys?: string[];
  disabledKeys?: string[];
  defaultSelectedKeys?: string[];
  variant?: "flat" | "bordered" | "faded" | "underlined";
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "success"
    | "warning"
    | "danger";
  size?: "sm" | "md" | "lg";
  radius?: "none" | "sm" | "md" | "lg" | "full";
  placeholder?: string;
  labelPlacement?: "inside" | "outside" | "outside-left";
  label?: string;
  description?: string;
  errorMessage?: string;
  startContent?: React.ReactNode;
  endContent?: React.ReactNode;
  selectorIcon?: React.ReactNode;
  scrollRef?: React.RefObject<HTMLElement>;
  spinnerRef?: React.RefObject<HTMLElement>;
  fullWidth?: boolean;
  isOpen?: boolean;
  defaultOpen?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  isMultiline?: boolean;
  isInvalid?: boolean;
  showScrollIndicators?: boolean;
  disallowEmptySelection?: boolean;
  disableAnimation?: boolean;
  disableSelectorIconRotation?: boolean;
  popoverProps?: Record<string, string>;
  listboxProps?: Record<string, string>;
  scrollShadowProps?: Record<string, string>;
  classNames?: { [key: string]: string };
  className?: string;
  onClose?: () => void;
  onOpenChange?: () => void;
  onSelectionChange?: () => void;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  renderValue?: (items: SelectedItems<any>) => React.ReactNode;
  ariaLabel: string;
}

/**
 *
 * @param param0 value, items, selectionMode, selectedKeys, disabledKeys, defaultSelectedKeys, variant, color, size, radius, placeholder, labelPlacement, label, description, errorMessage, startContent, endContent, selectorIcon, scrollRef, spinnerRef, fullWidth, isOpen, defaultOpen, isRequired, isDisabled, isMultiline, isInvalid, showScrollIndicators, disallowEmptySelection, disableAnimation, disableSelectorIconRotation, popoverProps, listboxProps, scrollShadowProps, classNames, className, onClose, onOpenChange, onSelectionChange, onChange, renderValue, ariaLabel
 * @returns NextUI Select
 */
const NextSelect: React.FC<NextSelectProps> = ({
  value,
  items,
  selectionMode,
  selectedKeys,
  disabledKeys,
  defaultSelectedKeys,
  variant,
  color,
  size,
  radius,
  placeholder,
  labelPlacement,
  label,
  description,
  errorMessage,
  startContent,
  endContent,
  selectorIcon,
  scrollRef,
  spinnerRef,
  fullWidth,
  isOpen,
  defaultOpen,
  isRequired,
  isDisabled,
  isMultiline,
  isInvalid,
  showScrollIndicators,
  disallowEmptySelection,
  disableAnimation,
  disableSelectorIconRotation,
  popoverProps,
  listboxProps,
  scrollShadowProps,
  classNames,
  className,
  onClose,
  onOpenChange,
  onSelectionChange,
  onChange,
  renderValue,
  ariaLabel,
}) => {
  return (
    <CustomSelect
      items={items}
      value={value}
      selectionMode={selectionMode}
      selectedKeys={selectedKeys}
      disabledKeys={disabledKeys}
      defaultSelectedKeys={defaultSelectedKeys}
      variant={variant}
      color={color}
      size={size}
      radius={radius}
      placeholder={placeholder}
      labelPlacement={labelPlacement}
      label={label}
      description={description}
      errorMessage={errorMessage}
      startContent={startContent}
      endContent={endContent}
      selectorIcon={selectorIcon}
      scrollRef={scrollRef}
      spinnerRef={spinnerRef}
      fullWidth={fullWidth}
      isOpen={isOpen}
      defaultOpen={defaultOpen}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isMultiline={isMultiline}
      isInvalid={isInvalid}
      showScrollIndicators={showScrollIndicators}
      disallowEmptySelection={disallowEmptySelection}
      disableAnimation={disableAnimation}
      disableSelectorIconRotation={disableSelectorIconRotation}
      popoverProps={popoverProps}
      listboxProps={listboxProps}
      scrollShadowProps={scrollShadowProps}
      classNames={classNames}
      className={className}
      onClose={onClose}
      onOpenChange={onOpenChange}
      onSelectionChange={onSelectionChange}
      onChange={onChange}
      renderValue={renderValue}
      aria-label={ariaLabel}
    >
      {items.map((option) => (
        <SelectItem key={option.key} value={option.value}>
          {option.label}
        </SelectItem>
      ))}
    </CustomSelect>
  );
};

export default NextSelect;
