import { Col } from "antd";
import { Form, Input } from "formik-antd";
import { useTranslation } from "react-i18next";

/**
 * Fields for the grouping confirmation type
 */
export default function GroupingType() {
  const { t } = useTranslation();
  return (
    <>
      <Col span={2}>{t("translation:grouping")}:</Col>
      <Col span={5}>
        <Form.Item name="key" hasFeedback={false}>
          <Input
            size="large"
            name="key"
            placeholder={t("translation:enter_key")}
          />
        </Form.Item>
      </Col>
      <Col span={10} />
    </>
  );
}
