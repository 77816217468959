import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { Integration } from "/app/src/models";
import { integrationService } from "/app/src/services";
import { useCallback, useEffect, useState } from "react";
import NextButton from "/app/src/components/NextUi/Button";
import Box from "/app/src/components/generic/components/box";
import NextTextarea from "/app/src/components/NextUi/Textarea";
import { isJSON } from "/app/src/helpers";
import NextSwitch from "/app/src/components/NextUi/Switch";

/**
 * Component to display the settings for a single Data Pull Integration
 */
export default function Test({ integration }: { integration: Integration }) {
  const { t } = useTranslation();
  const [testResponse, setTestResponse] = useState<string | null>(null);
  const [isTesting, setIsTesting] = useState<boolean>(false);
  const [isManual, setIsManual] = useState<boolean>(false);
  const [textValue, setTextValue] = useState<string>("");

  useEffect(() => {
    // If the integration is a delete type, then automatic testing is not possible
    if (integration.type === "delete") {
      setIsManual(true);
    }
  }, [integration.type]);

  const toggleIsManual = useCallback(() => {
    setIsManual(!isManual);
  }, [isManual]);
  /**
   * Test the integration
   */
  const handleTest = useCallback(async () => {
    setIsTesting(true);
    setTestResponse(null);
    let body = {};
    if (isManual) {
      if (!isJSON(textValue)) {
        setTestResponse("Error:\nInvalid JSON");
        setIsTesting(false);
        return;
      }
      body = JSON.parse(textValue);
    }
    try {
      const response = await integrationService.testIntegration(
        integration.id,
        body,
      );
      if (response?.data) {
        setTestResponse(`Data Pulled:\n${JSON.stringify(response?.data)}`);
      } else if (response?.message) {
        setTestResponse(`Result:\n${response?.message}`);
      } else if (response?.ordersToDelete) {
        setTestResponse(
          `Orders Successfully found:\n${JSON.stringify(response?.ordersToDelete)}`,
        );
      }
    } catch (error) {
      setTestResponse(`Error:\n${error.message}`);
    }
    setIsTesting(false);
  }, [integration.id, isManual, textValue]);

  const handleTextChange = useCallback((e) => {
    setTextValue(e.target.value);
  }, []);

  return (
    <Box>
      <div className="flex">
        <div>
          <NextSwitch
            className="ml-2"
            isSelected={!isManual}
            onValueChange={toggleIsManual}
            size="md"
            isDisabled={integration.type === "delete"}
          />
        </div>
        <h4>
          {isManual
            ? t("translation:test_using_json")
            : t("translation:test_using_connection")}
        </h4>
      </div>
      <NextTextarea
        className="mt-4"
        value={textValue}
        onChange={handleTextChange}
        maxRows={20}
        isDisabled={!isManual}
      />
      <Row gutter={16}>
        <Col offset={16} span={4} />
        <Col span={4}>
          <NextButton
            color="primary"
            fullWidth
            onClick={handleTest}
            isDisabled={isTesting}
            className="mt-4"
          >
            {t("translation:test")}
          </NextButton>
        </Col>
      </Row>

      {testResponse && <p>{testResponse}</p>}
    </Box>
  );
}
