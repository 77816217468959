import {
  genericGetSingle,
  genericGetAll,
  genericUpdateSingle,
  genericCreateSingle,
  genericDeleteSingle,
} from "../helpers/apiCall";
import { Error } from "../types";
import { iField } from "../models";

/**
 * Get all iFields
 * @param params URLSearchParams
 * @returns Promise<{ i_fields: iField[] }>
 */
function getAll(params: URLSearchParams = null): Promise<{ fields: iField[] }> {
  return genericGetAll("i_fields", params);
}

/**
 * Get a single iField
 * @param id number | string
 * @returns Promise<{ i_field: iField }>
 */
function getSingle(id: number | string): Promise<{ field: iField }> {
  return genericGetSingle("i_fields", id);
}

/**
 * Get a single iField
 * @param id number | string
 * @param field { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ i_field: iField } | Error>
 */
function updateSingle(
  id: number | string,
  field: { [key: string]: string | number | boolean | Date | undefined },
): Promise<{ field: iField } | Error> {
  return genericUpdateSingle("i_fields", id, field);
}

/**
 * Create a single iField
 * @param field { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ i_field: iField } | Error>
 */
function createSingle(field: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<{ field: iField } | Error> {
  return genericCreateSingle("i_fields", field);
}

/**
 * Delete a single iField
 * @param id number | string
 * @returns Promise<{ deleted: boolean } | { error: string }>
 */
function deleteSingle(
  id: number | string,
): Promise<{ deleted: boolean } | { error: string }> {
  return genericDeleteSingle("i_fields", id);
}

/**
 * Gets the count of iFields
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("i_fields", params);
}

export const iFieldService = {
  getAll,
  getSingle,
  updateSingle,
  createSingle,
  deleteSingle,
  getCount,
};
