import { useCallback } from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, Input } from "formik-antd";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { taskService } from "/app/src/services";
import { Integration, Task } from "/app/src/models";
import Tasks from "./tasks";
import { useTranslation } from "react-i18next";
import { buildParams } from "/app/src/helpers/params";
import { handlePromiseError } from "/app/src/helpers/api";
import { statusSchema } from "/app/src/schemas/apps/epicor/statusSchema";
import Box from "/app/src/components/generic/components/box";

export default function Status({ integration }: { integration: Integration }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  function checkForDuplicateOrders(data: string): boolean {
    data = data.replace(/ /g, "");
    const orders = data.split(",");
    return new Set(orders).size !== orders.length;
  }

  const { mutateAsync: addTask } = useMutation({
    mutationFn: (task: Task) => {
      return taskService.createSingle(task).then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["tasks", { status: 0, integrationId: integration.id }],
        (oldData: { tasks: Task[] }) => {
          return {
            tasks: [...oldData.tasks, response.task],
          };
        },
      );
    },
  });

  const { data: tasks } = useQuery({
    queryKey: ["tasks", { status: 0, integrationId: integration.id }],
    queryFn: () => {
      return taskService.getAll(
        buildParams({
          status: 0,
          integrationId: integration.id,
        }),
      );
    },
    initialData: { tasks: [] },
    select: (data: { tasks: Task[] }) => {
      return data.tasks;
    },
  });

  const addTaskHandler = useCallback(
    async (values: CSVFormValues, actions: FormikHelpers<CSVFormValues>) => {
      if (checkForDuplicateOrders(values.data)) {
        actions.setFieldError("data", "Duplicate orders found");
      } else {
        await addTask(values).then((response) => {
          if (!response?.errors) {
            actions.resetForm();
          }
        });
      }
    },
    [addTask],
  );

  interface CSVFormValues {
    data: string;
    integrationId: number | undefined;
  }

  const exportForm = useCallback(
    () => (
      <Form layout="vertical">
        <Row justify="start" gutter={16}>
          <Col span={24}>
            <SubmitButton type="primary" size="large" block>
              {t("translation:export")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [t],
  );
  const commaSeparatedForm: (props: FormikProps<CSVFormValues>) => JSX.Element =
    useCallback(
      ({ dirty }) => (
        <Form layout="vertical">
          <Row justify="start" gutter={16}>
            <Col span={24}>
              <Form.Item name="data" hasFeedback={false}>
                <Input.TextArea
                  name="data"
                  rows={3}
                  className="data"
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="start" gutter={16}>
            <Col span={24}>
              <SubmitButton type="primary" size="large" block disabled={!dirty}>
                {t("translation:import")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [t],
    );
  return (
    <div className="status">
      {integration.number === "Export" ? (
        <Box>
          <h1>
            {t("translation:manual")} {t("translation:export")}
          </h1>
          <Formik
            component={exportForm}
            initialValues={{
              data: "",
              integrationId: integration.id,
            }}
            onSubmit={addTaskHandler}
          />
        </Box>
      ) : (
        <Box>
          <h1>
            {t("translation:manual")} {integration.number}
          </h1>
          <p>{t("translation:manual_export_tip")}</p>
          <Formik
            component={commaSeparatedForm}
            initialValues={{
              data: "",
              integrationId: integration.id,
            }}
            validationSchema={statusSchema}
            onSubmit={addTaskHandler}
          />
        </Box>
      )}

      <Box>
        <h2>{t("translation:queued_tasks")}</h2>
        <Tasks tasks={tasks} />
      </Box>
    </div>
  );
}
