import React, { useCallback } from "react";
import { Col } from "antd";
import { Form, Select, TimePicker, InputNumber } from "formik-antd";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

/**
 * Time component for Day, Hour frequency -> displays time picker
 */
export function TimeSelection({
  format = "HH:mm",
}: {
  format?: "HH:mm" | "HH:mm:ss" | "mm";
}) {
  const { t } = useTranslation();
  const { setFieldTouched, validateField } = useFormikContext();
  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        //On close of time picker, set field touched and validate. Validation
        //should be performed manually because clicking 'Now' button does not
        //properly trigger validation
        setFieldTouched("triggerTime", true, false);
        validateField("triggerTime");
      }
    },
    [setFieldTouched, validateField],
  );

  return (
    <>
      <Col span={2}>
        <span className="blockText">{t("translation:at")}</span>
      </Col>
      <Col span={8}>
        <Form.Item name="triggerTime">
          <TimePicker
            onOpenChange={handleOpenChange}
            size="large"
            name="triggerTime"
            format={format}
          />
        </Form.Item>
      </Col>
      <Col span={3} />
    </>
  );
}

/**
 * Time component for Week frequency -> displays time picker and select for days
 * of the week
 */
export function Week() {
  const { t } = useTranslation();
  const format = "HH:mm";
  const { setFieldTouched, validateField } = useFormikContext();
  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        //On close of time picker, set field touched and validate. Validation
        //should be performed manually because clicking 'Now' button does not
        //properly trigger validation
        setFieldTouched("triggerTime", true, false);
        validateField("triggerTime");
      }
    },
    [setFieldTouched, validateField],
  );

  return (
    <>
      <Col span={2}>
        <span className="blockText">{t("translation:on")}</span>
      </Col>
      <Col span={6}>
        <Form.Item name="triggerNumber">
          <Select name="triggerNumber" size="large" mode="multiple">
            <Select.Option value="Sunday">
              {t("translation:sunday")}
            </Select.Option>
            <Select.Option value="Monday">
              {t("translation:monday")}
            </Select.Option>
            <Select.Option value="Tuesday">
              {t("translation:tuesday")}
            </Select.Option>
            <Select.Option value="Wednesday">
              {t("translation:wednesday")}
            </Select.Option>
            <Select.Option value="Thursday">
              {t("translation:thursday")}
            </Select.Option>
            <Select.Option value="Friday">
              {t("translation:friday")}
            </Select.Option>
            <Select.Option value="Saturday">
              {t("translation:saturday")}
            </Select.Option>
          </Select>
        </Form.Item>
      </Col>
      <Col span={2}>
        <span className="blockText">{t("translation:at")}</span>
      </Col>
      <Col span={3}>
        <Form.Item name="triggerTime">
          <TimePicker
            onOpenChange={handleOpenChange}
            size="large"
            name="triggerTime"
            format={format}
          />
        </Form.Item>
      </Col>
    </>
  );
}

/**
 * Time component for Month frequency -> displays time picker and input for day
 * of the month
 */
export function Month() {
  const { t } = useTranslation();
  const format = "HH:mm";
  const { setFieldTouched, validateField } = useFormikContext();
  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        //On close of time picker, set field touched and validate. Validation
        //should be performed manually because clicking 'Now' button does not
        //properly trigger validation
        setFieldTouched("triggerTime", true, false);
        validateField("triggerTime");
      }
    },
    [setFieldTouched, validateField],
  );

  return (
    <>
      <Col span={4}>
        <span className="blockText">{t("translation:on_day")}</span>
      </Col>
      <Col span={4}>
        <Form.Item name="triggerNumber">
          <InputNumber
            name="triggerNumber"
            size="large"
            min={1}
            max={31}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        <span className="blockText">{t("translation:at")}</span>
      </Col>
      <Col span={3}>
        <Form.Item name="triggerTime">
          <TimePicker
            onOpenChange={handleOpenChange}
            size="large"
            name="triggerTime"
            format={format}
          />
        </Form.Item>
      </Col>
    </>
  );
}
