import React, { useCallback } from "react";
import { Col, Row } from "antd";
import { Form, SubmitButton, Input } from "formik-antd";
import { Formik } from "formik";
import { smsSchema } from "/app/src/schemas/apps/sms/smsSchema";
import { useTranslation } from "react-i18next";
import { WorkflowAction, App } from "/app/src/models";
import NextButton from "/app/src/components/NextUi/Button";

export default function SMS({
  app,
  action,
  addWorkflowAction,
  removeWorkflowAction,
  updateAction,
  isNew,
  resetSelectedApp,
}: {
  app: App;
  action: WorkflowAction;
  addWorkflowAction: (action: WorkflowAction) => Promise<any>;
  removeWorkflowAction: (action: WorkflowAction) => Promise<any> | undefined;
  updateAction: (action: WorkflowAction) => Promise<any>;
  isNew: boolean;
  resetSelectedApp: () => void;
}) {
  const { t } = useTranslation();

  const removeWorkflowHandler = useCallback(() => {
    removeWorkflowAction(action);
  }, [removeWorkflowAction, action]);

  const addUpdateWorkflowHandler = useCallback(
    (values, actions) => {
      if (isNew) {
        const newAction = {
          ...values,
          appId: app.id,
        } as WorkflowAction;
        addWorkflowAction(newAction).then((response) => {
          if (!response?.errors) {
            actions.resetForm();
            resetSelectedApp();
          }
        });
      } else {
        if (action?.id) {
          updateAction({ id: action.id, ...values }).then((response) => {
            if (!response?.errors) {
              actions.resetForm();
            }
          });
        }
      }
    },
    [isNew, app, action, addWorkflowAction, updateAction, resetSelectedApp],
  );

  return (
    <Row justify="start" gutter={16}>
      <Col span={24}>
        <Formik
          validationSchema={smsSchema}
          enableReinitialize
          initialValues={{
            ...(isNew ? { to: "" } : { to: action.to }),
            ...(isNew ? { message: "" } : { message: action.message }),
          }}
          onSubmit={addUpdateWorkflowHandler}
        >
          {({ dirty, isValid }) => (
            <Form>
              <Row justify="start" gutter={16}>
                <Col span={3}>
                  <span className="blockText">{t("translation:send")}</span>
                </Col>
                <Col span={15}>
                  <Form.Item name="to">
                    <Input
                      suffix
                      size="large"
                      name="to"
                      placeholder={`${t("translation:enter")} ${t(
                        "translation:phone_number",
                      )}`}
                    />
                  </Form.Item>
                  <Form.Item name="message">
                    <Input
                      suffix
                      size="large"
                      name="message"
                      placeholder={`${t("translation:enter")} ${t(
                        "translation:message",
                      )}`}
                    />
                  </Form.Item>
                </Col>
                <Col flex="auto">
                  <SubmitButton
                    type="primary"
                    size="large"
                    block
                    disabled={!dirty || !isValid}
                  >
                    {isNew
                      ? t("translation:add_action")
                      : t("translation:save")}
                  </SubmitButton>
                </Col>
                {!isNew && (
                  <Col span={3}>
                    <NextButton
                      size="md"
                      variant="bordered"
                      color="default"
                      fullWidth
                      onClick={removeWorkflowHandler}
                      className="hover:border-primary-default hover:text-primary-default bg-white"
                    >
                      {t("translation:remove")}
                    </NextButton>
                  </Col>
                )}
              </Row>
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
}
