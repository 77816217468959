import React, { useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Formik, FormikProps } from "formik";
import { Form, Input, SubmitButton } from "formik-antd";
import { Row, Col } from "antd";
import { userLoginSchema } from "/app/src/schemas";
import {
  loginUser,
  useAuthState,
  useAuthDispatch,
} from "/app/src/contexts/authentication";
import { useTranslation } from "react-i18next";
import { useStatusContext } from "/app/src/contexts/hooks/useStatusContext";
interface FormValues {
  username: string;
  password: string;
}

interface LocationType {
  state: {
    from?: string;
  };
}

export default function LoginForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message, loading } = useStatusContext();
  const { isAuthenticated, errorMessage } = useAuthState(); //read the values of loading and errorMessage from context
  const { state } = useLocation() as LocationType;
  const dispatch = useAuthDispatch(); //get the dispatch method from the useDispatch custom hook
  // redirect to home if already logged in
  useEffect(() => {
    if (isAuthenticated && !loading && message !== "loading") {
      //If user redirected to login because not signed in,
      //send them back to where redirected from. Otherwise
      //go to the dashboard
      navigate(state?.from || "/dashboard");
    }
  }, [navigate, state, message, loading, isAuthenticated]);

  const loginHandler = useCallback(
    ({ username, password }, actions) => {
      actions.setSubmitting(true);
      loginUser(dispatch, username, password, actions.setSubmitting);
    },
    [dispatch],
  );

  const LoginForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ errors, touched, isValid, dirty }) => (
        <Form>
          <Form.Item name="username">
            <Input
              suffix
              name="username"
              placeholder={`${t("translation:please_enter")} ${t(
                "translation:your_username",
              )}`}
              className={
                errors.username && touched.username ? "input-error" : undefined
              }
              size="large"
            />
          </Form.Item>
          <Form.Item name="password">
            <Input.Password
              name="password"
              placeholder={`${t("translation:please_enter")} ${t(
                "translation:your_password",
              )}`}
              size="large"
              className={
                errors.password && touched.password ? "input-error" : undefined
              }
            />
          </Form.Item>
          <SubmitButton
            type="primary"
            block
            size="large"
            disabled={!(dirty && isValid)}
          >
            {t("translation:login")}
          </SubmitButton>
        </Form>
      ),
      [t],
    );

  return (
    <div className="login">
      <Helmet>
        <title>{t("translation:login")} - ItemPath</title>
      </Helmet>
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 20, offset: 2 }}
          lg={{ span: 8, offset: 8 }}
        >
          <div className="box mt-4">
            <h3 style={{ textAlign: "center", margin: "20px 0 30px" }}>
              {t("translation:please_login_to_itempath")}
            </h3>
            <Formik
              component={LoginForm}
              initialValues={{
                username: "",
                password: "",
              }}
              validationSchema={userLoginSchema}
              onSubmit={loginHandler}
            />
            {errorMessage ? (
              <p style={{ color: "red" }}>{errorMessage}</p>
            ) : null}
          </div>
        </Col>
      </Row>
    </div>
  );
}
