import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { binService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { Bin as BinType } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
import Box from "/app/src/components/generic/components/box";
import { useHandleNavigationError } from "/app/src/helpers/api";

export default function Bin() {
  const { t } = useTranslation();
  const { binId } = useParams();
  const handleNavigationError = useHandleNavigationError();

  // Get the single Bin from URL
  const { data: bin } = useQuery({
    queryKey: ["bin", binId],
    queryFn: () => {
      return binService.getSingle(binId).catch(() => {
        handleNavigationError();
      });
    },
    initialData: { bin: {} },
    retry: false,
    select: (data: { bin: BinType }) => {
      return data.bin;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{bin?.name}</title>
      </Helmet>
      {bin?.name ? (
        <Box>
          <h1>{bin?.name}</h1>
          <div className="bigItems">
            <div className="bigItem">
              <h4>{t("translation:width")}</h4>
              <span>{bin?.width}</span>
            </div>
            <div className="bigItem">
              <h4>{t("translation:depth")}</h4>
              <span>{bin?.depth}</span>
            </div>
            <div className="bigItem">
              <h4>{t("translation:height")}</h4>
              <span>{bin?.height}</span>
            </div>
          </div>
        </Box>
      ) : (
        <div />
      )}
    </div>
  );
}
