import { mappingService, reportColumnTypeService } from "/app/src/services";
import NewMapping from "./newMapping";
import { Integration, Mapping, ReportColumnType } from "/app/src/models";
import { buildParams } from "/app/src/helpers";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { useTranslation } from "react-i18next";
import Box from "/app/src/components/generic/components/box";
import { themes } from "/app/src/constants/themes";
import EditMappings from "./editMappings";

/**
 * Component to display the mappings for a single Integration - Data Pull or Data Push
 */
export default function Mappings({
  integration,
  box = true,
}: {
  integration: Integration;
  box?: boolean;
}) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { data: columnTypes } = useQuery({
    queryKey: ["columnTypes", integration.baseTable],
    queryFn: () => {
      const params = { baseTable: integration.baseTable };
      if (integration.baseTable === "Material") {
        params["table"] = "Material";
      }
      if (params.baseTable === "Order" && integration.type === "delete") {
        params.baseTable = "Order Deletion";
      }
      return reportColumnTypeService.getAll(buildParams(params));
    },
    enabled: Boolean(
      themes.map((map) => map.value).includes(integration.baseTable) ||
        integration.baseTable === "Order",
    ),
    initialData: { report_column_types: [] },
    select: (data: { report_column_types: ReportColumnType[] }) => {
      return data.report_column_types;
    },
  });

  const { mutateAsync: addMapping } = useMutation({
    mutationFn: (
      mapping: Omit<Mapping, "parentMapping" | "children"> & {
        dataType?: "DateTime" | "String" | "Number";
      },
    ) => {
      if (integration.appName === "Variance") {
        //after creating a mapping, we need to recreate a report column type
        const dataType = mapping?.dataType;
        if (dataType) {
          delete mapping.dataType;
        }
        return mappingService
          .createSingle(mapping)
          .then(handlePromiseError)
          .then((response) => {
            response.mapping["dataType"] = dataType;
            const reportColumnType: ReportColumnType = {
              name: mapping.key,
              table: "Variance",
              baseTable: integration.baseTable,
              integrationId: integration.id,
              mappingId: response.mapping.id,
              custom: "variance",
              type: dataType,
            };
            return reportColumnTypeService
              .createSingle(reportColumnType)
              .then(handlePromiseError)
              .then(() => response);
          });
      }
      return mappingService.createSingle(mapping).then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["mappings", integration.id],
        (oldData: { mappings: Mapping[] }) => {
          return {
            mappings: [...oldData.mappings, response.mapping],
          };
        },
      );
    },
  });

  const MappingWrapper = box ? Box : "div";
  return (
    <MappingWrapper>
      <h1>{t("translation:mappings")}</h1>
      <EditMappings integration={integration} />
      <div className="newMapping">
        <NewMapping
          integration={integration}
          addMapping={addMapping}
          columnTypes={columnTypes}
        />
      </div>
    </MappingWrapper>
  );
}
