import React, { useCallback, useState } from "react";

import { Helmet } from "react-helmet";
import { Checkbox, Col, Row } from "antd";
import InnerControls from "./_innerControls";
import { transactionService, settingService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { Integration, Transaction } from "/app/src/models";
import { buildParams } from "/app/src/helpers";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Pagination from "/app/src/components/generic/tables/pagination";
import TransactionRow from "./transactionRow";
import { buildCreationDateQuery } from "/app/src/helpers/time";
import { handlePromiseError } from "/app/src/helpers/api";

/**
 * Builds the params for the transaction query
 * @returns
 */
function buildTransactionParams(
  historyTypes: string | undefined,
  motiveTypes: string | undefined,
  searchString: string,
  afterTime: string,
  beforeTime: string,
  exportStateType: string,
) {
  const creationDate = buildCreationDateQuery(beforeTime, afterTime);
  const params = buildParams({
    search: searchString,
    creationDate,
    exportStateType,
  });
  if (motiveTypes) {
    params.append("motiveType", `[or]${motiveTypes}`);
  }
  if (historyTypes) {
    params.append("type", `[or]${historyTypes}`);
  }
  return params;
}

/**
 * Shows the failed attempts of a Data Push integration
 * @param param0 object containing the integration object
 */
export default function TransactionsList({
  integration,
  exportStateType,
}: {
  integration: Integration;
  exportStateType: 5 | 12;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [searchString, setSearchString] = useState("");
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [historyTypeFilter, setHistoryTypeFilter] = useState<string>();
  const [motiveTypeFilter, setMotiveTypeFilter] = useState<string>();

  const [ordersRetrying, setOrdersRetrying] = useState<string[]>([]);
  const [beforeTime, setBeforeTime] = useState("");
  const [afterTime, setAfterTime] = useState("");

  const { isFetched: settingsFetched } = useQuery({
    queryKey: ["settings", integration.id],
    queryFn: () => {
      return settingService
        .getAll(buildParams({ integrationId: integration.id, type: "filter" }))
        .then((response) => {
          if (response.settings.length > 0) {
            response.settings.forEach((settingFilter) => {
              if (settingFilter.number === 1) {
                const types = settingFilter.value.replace(/,/g, ";");
                setHistoryTypeFilter(types);
              } else if (settingFilter.number === 2) {
                const motiveTypes = settingFilter.value.replace(/,/g, ";");
                setMotiveTypeFilter(motiveTypes);
              }
            });
          }
        });
    },
    enabled: Boolean(integration.id),
  });
  const {
    data: transactions,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: [
      "transactions",
      page,
      pageSize,
      searchString,
      historyTypeFilter,
      motiveTypeFilter,
      beforeTime,
      afterTime,
      exportStateType,
    ],
    queryFn: () => {
      setSelectedIds([]);
      const params = buildTransactionParams(
        historyTypeFilter,
        motiveTypeFilter,
        searchString,
        afterTime,
        beforeTime,
        `[or]${exportStateType};10`,
      );
      params.append("limit", pageSize.toString());
      params.append("page", page.toString());
      return transactionService.getAll(params);
    },
    enabled: settingsFetched,
    initialData: { transactions: [] },
    select: (data: { transactions: Transaction[] }) => {
      return data.transactions;
    },
  });

  const { data: transactionsCount, refetch: refetchCount } = useQuery({
    queryKey: [
      "transactionsCount",
      searchString,
      historyTypeFilter,
      motiveTypeFilter,
      beforeTime,
      afterTime,
      exportStateType,
    ],
    queryFn: () => {
      const params = buildTransactionParams(
        historyTypeFilter,
        motiveTypeFilter,
        searchString,
        afterTime,
        beforeTime,
        `[or]${exportStateType};10`,
      );
      return transactionService.getCount(params);
    },
    enabled: settingsFetched,
    initialData: { count: 0 },
  });

  const { mutateAsync: updateTransaction } = useMutation({
    mutationFn: (transactionId: string) => {
      return transactionService
        .updateSingle(transactionId, {
          exportStateType: 10, //use unused powerpick export state to track transactions marked for retry
        })
        .then(handlePromiseError);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(
        ["failedTransaction", data.transaction.id],
        data,
      );
    },
  });

  /**
   * Refetches the failed transactions and the count of failed transactions
   */
  const refetchFailedTransactions = useCallback(() => {
    refetch();
    refetchCount();
  }, [refetch, refetchCount]);

  /**
   * Handles the submit of the retry button
   * Goes through each selected order and updates the export state to 10
   */
  const handleSubmit = useCallback(() => {
    const ordersToResend: string[] = selectedIds;
    setOrdersRetrying(selectedIds);
    //for each selected order, update the export state to 10
    for (const element of ordersToResend) {
      updateTransaction(element);
    }

    setSelectedIds([]);
  }, [selectedIds, updateTransaction]);

  /**
   * Goes through the cache of each failed transaction and
   * returns the count of transactions matching exportStateType prop(ignores successful retries)
   */
  const getNumberofTransactions = useCallback(() => {
    if (transactions.length === 0) {
      return 0;
    }
    let count = 0;
    transactions.forEach((transaction) => {
      const data: undefined | { transaction: Transaction } =
        queryClient.getQueryData(["failedTransaction", transaction.id]);
      if (data && data.transaction.exportStateType === exportStateType) {
        count++;
      }
    });
    return count;
  }, [transactions, queryClient, exportStateType]);

  const actionsClicked = useCallback(() => {
    if (selectedIds.length === getNumberofTransactions()) {
      setSelectedIds([]);
      return;
    }
    const allIds: string[] = [];
    transactions.forEach((transaction: Transaction) => {
      const data: undefined | { transaction: Transaction } =
        queryClient.getQueryData(["failedTransaction", transaction.id]);
      if (data && data.transaction.exportStateType === exportStateType) {
        allIds.push(transaction.id);
      }
    });
    setSelectedIds(allIds);
  }, [
    selectedIds.length,
    getNumberofTransactions,
    transactions,
    queryClient,
    exportStateType,
  ]);

  return (
    <div className="app orderProcessor">
      <Helmet>
        <title>{t("translation:data_push")} - ItemPath</title>
      </Helmet>
      <Row gutter={20}>
        <Col span={24}>
          <InnerControls
            count={selectedIds.length}
            setSearchString={setSearchString}
            updateTransactions={handleSubmit}
            ordersRetrying={ordersRetrying}
            refetch={refetchFailedTransactions}
            setBeforeTime={setBeforeTime}
            setAfterTime={setAfterTime}
          />
          <Row gutter={20} className="transaction-row-header">
            <Col span={6} className="name">
              {t("translation:order_id")}
            </Col>
            <Col span={6} className="name">
              {t("translation:order_name")}
            </Col>
            <Col span={4}>{t("translation:type")}</Col>
            <Col span={2}>{t("translation:status")}</Col>
            <Col span={1} />

            <Col span={4}>{t("translation:creation_date")}</Col>
            <Col span={1} className="actions">
              {" "}
              {!isFetching && (
                <Checkbox
                  checked={selectedIds.length === transactions.length}
                  onClick={actionsClicked}
                />
              )}
            </Col>
          </Row>
          {transactions.map((transaction) => {
            return (
              <TransactionRow
                transaction={transaction}
                selectedRowIds={selectedIds}
                setSelectedRowIds={setSelectedIds}
                ordersRetrying={ordersRetrying}
                setOrdersRetrying={setOrdersRetrying}
                key={transaction.id}
                exportStateType={exportStateType}
              />
            );
          })}
          <Pagination
            length={transactionsCount?.count}
            pageSize={pageSize}
            setPageSize={setPageSize}
            currentPage={page}
            setPage={setPage}
          />
        </Col>
      </Row>
    </div>
  );
}
