import React, { useCallback } from "react";
import { Col, Row } from "antd";
import { Form } from "formik-antd";
import { Formik } from "formik";
import { WorkflowAction, App } from "/app/src/models";
import HueForm from "./form";

/**
 * Hue action component
 */
export default function Hue({
  app,
  action,
  addWorkflowAction,
  removeWorkflowAction,
  updateAction,
  isNew,
  resetSelectedApp,
}: {
  app: App;
  action: WorkflowAction;
  addWorkflowAction: (
    action: WorkflowAction,
  ) => Promise<{ workflow_action: WorkflowAction }>;
  removeWorkflowAction: (
    action: WorkflowAction,
  ) => Promise<{ deleted: boolean }> | undefined;
  updateAction: (
    action: WorkflowAction,
  ) => Promise<{ workflow_action: WorkflowAction }>;
  isNew: boolean;
  resetSelectedApp: () => void;
}) {
  const handleSubmit = useCallback(
    (values, actions) => {
      if (isNew) {
        addWorkflowAction({ ...values, appId: app.id }).then(() => {
          actions.resetForm();
          resetSelectedApp();
        });
      } else {
        updateAction({ id: action.id, ...values }).then(() => {
          actions.resetForm();
          actions.setSubmitting(false);
        });
      }
    },
    [
      action.id,
      addWorkflowAction,
      app.id,
      isNew,
      resetSelectedApp,
      updateAction,
    ],
  );
  const getInitialValues = useCallback(() => {
    return isNew
      ? { to: "", value: "", message: "" }
      : { to: action.to, value: action.value, message: action.message };
  }, [action.message, action.to, action.value, isNew]);

  return (
    <Row justify="start" gutter={16}>
      <Col span={24}>
        <Formik
          enableReinitialize
          initialValues={getInitialValues()}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <HueForm
                action={action}
                isNew={isNew}
                removeWorkflowAction={removeWorkflowAction}
              />
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
}
