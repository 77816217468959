import React from "react";
import { Integration } from "/app/src/models";
import LineFields from "./lineFields";
import OrderFields from "./orderFields";
import DataHook from "./dataHook";
/**
 * Component to display the settings for a single Data Pull or Data Push Integration
 */
export default function Fields({ integration }: { integration: Integration }) {
  const { lineFields, orderFields, updateField, createField, deleteField } =
    DataHook(integration);
  return (
    <>
      <OrderFields
        integration={integration}
        orderFields={orderFields}
        updateField={updateField}
        createField={createField}
        deleteField={deleteField}
      />
      <LineFields
        integration={integration}
        lineFields={lineFields}
        updateField={updateField}
        createField={createField}
        deleteField={deleteField}
      />
    </>
  );
}
