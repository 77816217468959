import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Descriptions from "/app/src/components/generic/components/descriptions";
import { costCenterService } from "/app/src/services";
import { useTranslation } from "react-i18next";

import { PowerPickTimeZoneToLocal } from "/app/src/components/generic/formatting/dateTime";
import { CostCenter as CostCenterType } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
import Box from "/app/src/components/generic/components/box";
import { useHandleNavigationError } from "/app/src/helpers/api";

export default function CostCenter() {
  const { t } = useTranslation();
  const { costCenterId } = useParams();
  const handleNavigationError = useHandleNavigationError();

  // Get the Single Cost Center from URL
  const { data: costCenter } = useQuery({
    queryKey: ["costCenter", costCenterId],
    queryFn: () => {
      return costCenterService.getSingle(costCenterId).catch(() => {
        handleNavigationError();
      });
    },
    initialData: { cost_center: {} },
    retry: false,
    select: (data: { cost_center: CostCenterType }) => {
      return data.cost_center;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{costCenter?.name}</title>
      </Helmet>
      {costCenter?.name ? (
        <>
          <Box>
            <h1>{costCenter?.name}</h1>
            <div className="bigItems">
              <div className="bigItem">
                <h4>{t("translation:created")}</h4>
                <span>
                  <PowerPickTimeZoneToLocal
                    date={costCenter?.creationDate}
                    format={"MMM YYYY"}
                  />
                </span>
              </div>
            </div>
          </Box>
          <Box>
            <h3>{t("translation:information_fields")}</h3>

            <Descriptions
              items={[
                {
                  label: t("translation:description"),
                  value: costCenter?.description,
                },
              ]}
            />
          </Box>
        </>
      ) : (
        <div />
      )}
    </div>
  );
}
