import { useCallback } from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, Input, SubmitButton } from "formik-antd";
import { settingService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { buildParams } from "/app/src/helpers/params";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { Setting, Integration } from "/app/src/models";
import { handlePromiseError } from "/app/src/helpers/api";
import { simpleSchemaBuilder } from "/app/src/helpers";
import NextButton from "/app/src/components/NextUi/Button";
import Box from "/app/src/components/generic/components/box";

interface NewMappingFormValues {
  name: string;
  value: string;
  integrationId: number | undefined;
  type: string;
  number: number;
}

interface MappingFormValues {
  name: string | undefined;
  value: string | undefined;
}
export function Mapping({
  mapping,
  removeMapping,
  integrationType,
  updateMapping,
}: {
  mapping: Setting;
  removeMapping: (mapping: Setting) => void;
  integrationType: string | number | undefined;
  updateMapping: (mapping: Setting) => Promise<any>;
}) {
  const { t } = useTranslation();

  const updateMappingHandler = useCallback(
    async (
      values: MappingFormValues,
      actions: FormikHelpers<MappingFormValues>,
    ) => {
      if (mapping?.id) {
        await updateMapping({ id: mapping.id, ...values }).finally(() => {
          actions.resetForm();
        });
      }
    },
    [mapping, updateMapping],
  );

  const removeMappingHandler = useCallback(() => {
    removeMapping(mapping);
  }, [removeMapping, mapping]);

  const mappingForm: (props: FormikProps<MappingFormValues>) => JSX.Element =
    useCallback(
      ({ dirty }) => (
        <Form>
          <Row justify="start" gutter={16}>
            <Col span={8}>
              <Form.Item name="value" hasFeedback={false}>
                <Input size="large" name="value" />
              </Form.Item>
            </Col>
            <Col span={2}>
              {integrationType === "Export" ? (
                <div className="arrowLeft" />
              ) : (
                <div className="arrowRight" />
              )}
            </Col>
            <Col span={8}>
              <Form.Item name="name" hasFeedback={false}>
                <Input size="large" name="name" />
              </Form.Item>
            </Col>
            <Col span={3}>
              <SubmitButton disabled={!dirty} type="primary" size="large" block>
                {t("translation:save")}
              </SubmitButton>
            </Col>
            <Col span={3}>
              <NextButton
                color="default"
                variant="bordered"
                size="md"
                onClick={removeMappingHandler}
                className="hover:border-primary-default hover:text-primary-default"
                fullWidth
              >
                {t("translation:remove")}
              </NextButton>
            </Col>
          </Row>
        </Form>
      ),
      [t, integrationType, removeMappingHandler],
    );
  return (
    <div className="mapping">
      <Formik
        component={mappingForm}
        enableReinitialize
        initialValues={{
          name: mapping.name,
          value: mapping.value,
        }}
        validationSchema={simpleSchemaBuilder([
          { name: "name", type: "string", required: true },
          { name: "value", type: "string", required: true },
        ])}
        onSubmit={updateMappingHandler}
      />
    </div>
  );
}

export function NewMapping({
  integration,
  addMapping,
  integrationType,
}: {
  integration: Integration;
  addMapping: (mapping: Setting) => Promise<any>;
  integrationType: string | number | undefined;
}) {
  const { t } = useTranslation();

  const addMappingHandler = useCallback(
    async (
      values: NewMappingFormValues,
      actions: FormikHelpers<NewMappingFormValues>,
    ) => {
      await addMapping(values).then((response) => {
        if (response?.errors) {
          actions.resetForm();
        }
      });
    },
    [addMapping],
  );

  const newMappingForm: (
    props: FormikProps<NewMappingFormValues>,
  ) => JSX.Element = useCallback(
    ({ dirty }) => (
      <Form>
        <Row justify="start" gutter={16}>
          <Col span={8}>
            <Form.Item name="value" hasFeedback={false}>
              <Input size="large" name="value" />
            </Form.Item>
          </Col>
          <Col span={2}>
            {integrationType === "Export" ? (
              <div className="arrowLeft" />
            ) : (
              <div className="arrowRight" />
            )}
          </Col>
          <Col span={8}>
            <Form.Item name="name" hasFeedback={false}>
              <Input size="large" name="name" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <SubmitButton disabled={!dirty} type="primary" size="large" block>
              {t("translation:new_mapping")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [t, integrationType],
  );
  const initialValues: NewMappingFormValues = {
    value: "",
    name: "",
    integrationId: integration.id,
    type: "mapping",
    number: 0,
  };
  return (
    <div className="newMapping">
      <Formik
        component={newMappingForm}
        initialValues={initialValues}
        validationSchema={simpleSchemaBuilder([
          { name: "value", type: "string", required: true },
          { name: "name", type: "string", required: true },
        ])}
        onSubmit={addMappingHandler}
      />
    </div>
  );
}

/**
 *
 * @param param0 integration
 * @returns Component that returns all of the epicor mappings for a integration
 */
export default function Mappings({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  // Get settings
  const { data: mappings } = useQuery({
    queryKey: ["mappings", { integrationId: integration.id }],
    queryFn: () => {
      return settingService.getAll(
        buildParams({
          integrationId: integration.id,
          type: "mapping",
          orderBy: "name",
        }),
      );
    },
    enabled: Boolean(integration.id),
    initialData: { settings: [] },
    select: (data: { settings: Setting[] }) => {
      return data.settings;
    },
  });

  const { mutateAsync: addMapping } = useMutation({
    mutationFn: (mapping: Setting) => {
      return settingService.createSingle(mapping).then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["mappings", { integrationId: integration.id }],
        (oldData: { settings: Setting[] }) => {
          return { settings: [...oldData.settings, response.setting] };
        },
      );
    },
  });

  const { mutateAsync: removeMapping } = useMutation({
    mutationFn: (mapping: Setting) => {
      return settingService.deleteSingle(mapping.id).then(() => {
        const mappingId = mapping.id;
        return { mappingId };
      });
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["mappings", { integrationId: integration.id }],
        (oldData: { settings: Setting[] }) => {
          return {
            settings: oldData.settings.filter(
              (mapping) => mapping.id !== response.mappingId,
            ),
          };
        },
      );
    },
  });

  const { mutateAsync: updateMapping } = useMutation({
    mutationFn: (updatedMapping: Setting) => {
      return settingService
        .updateSingle(updatedMapping.id, updatedMapping)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["mappings", { integrationId: integration.id }],
        (oldData: { settings: Setting[] }) => {
          return {
            settings: oldData.settings.map((mapping) => {
              if (mapping.id === response.setting.id) {
                return response.setting;
              }
              return mapping;
            }),
          };
        },
      );
    },
  });

  return (
    <Box>
      <h1>
        {integration.number} {t("translation:mappings")}
      </h1>
      {mappings.map((mapping) => (
        <Mapping
          mapping={mapping}
          key={mapping.id}
          removeMapping={removeMapping}
          integrationType={integration.number}
          updateMapping={updateMapping}
        />
      ))}
      <NewMapping
        integration={integration}
        addMapping={addMapping}
        integrationType={integration.number}
      />
    </Box>
  );
}
