import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, Input, Select } from "formik-antd";
import { Connection, Integration, Mapping } from "/app/src/models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { settingService } from "/app/src/services";
import { buildParams, simpleSchemaBuilder } from "/app/src/helpers";
import { handlePromiseError } from "/app/src/helpers/api";
import getInitialValues from "/app/src/components/generic/components/apps/data/settings/connectionSettings/formHelper";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import DynamicMappedPath from "../settings/dynamicMappedPath";

interface FormValues {
  value: string | undefined;
  connectionId: number | undefined;
}

/**
 * Component to display the confirmation connection setting for a single Data Pull Integration
 */
export default function ConfirmationConnection({
  integration,
  connections,
  mappings,
}: {
  integration: Integration;
  connections: Connection[];
  mappings: Mapping[];
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const confirmationSettingQuery = useQuery({
    queryKey: ["setting", "confirmation", integration.id],
    queryFn: () =>
      settingService.getAll(
        buildParams({ integrationId: integration.id, type: "confirmation" }),
      ),
  });

  const { mutateAsync } = useMutation({
    mutationFn: (values: FormValues) => {
      if (confirmationSettingQuery.data?.settings.length < 1) {
        return settingService
          .createSingle({
            name: "confirmation",
            value: values.value,
            type: "confirmation",
            integrationId: integration.id,
            connectionId: values.connectionId,
          })
          .then(handlePromiseError);
      } else {
        return settingService
          .updateSingle(confirmationSettingQuery.data?.settings[0]?.id, {
            value: values.value,
            connectionId: values.connectionId,
          })
          .then(handlePromiseError);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries([
        "setting",
        "confirmation",
        integration.id,
      ]);
    },
  });

  const onSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      await mutateAsync(values).then(() => {
        actions.resetForm();
      });
    },
    [mutateAsync],
  );
  const confirmationForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ isSubmitting, dirty }) => (
        <Form layout="vertical">
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                name="connectionId"
                label={t("translation:connection")}
              >
                <Select
                  name="connectionId"
                  size="large"
                  placeholder={t("translation:select_connection")}
                >
                  {connections.map((c) => (
                    <Select.Option value={c.id} key={c.id}>
                      {c.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="value" label={t("translation:path")}>
                <Input.TextArea name="value" size="large" autoSize />
              </Form.Item>
            </Col>
            <Col style={{ paddingTop: "30px" }} span={3}>
              <DynamicMappedPath mappings={mappings} />
            </Col>
            <Col span={3}>
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || isSubmitting}
                style={{ marginTop: "30px" }}
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [connections, mappings, t],
    );
  return (
    <Formik
      initialValues={{
        value: getInitialValues(
          confirmationSettingQuery.data?.settings[0]?.value,
        ),
        connectionId: confirmationSettingQuery.data?.settings[0]?.connectionId,
      }}
      validationSchema={simpleSchemaBuilder([
        { name: "value", type: "string", required: true },
        { name: "connectionId", type: "number", required: true },
      ])}
      onSubmit={onSubmit}
      component={confirmationForm}
      enableReinitialize
    />
  );
}
