import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { batchService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { Batch as BatchType } from "/app/src/models";
import { PowerPickTimeZoneToLocal } from "/app/src/components/generic/formatting/dateTime";
import { useQuery } from "@tanstack/react-query";
import Box from "/app/src/components/generic/components/box";
import { useHandleNavigationError } from "/app/src/helpers/api";

export default function Batch() {
  const { t } = useTranslation();
  const { batchId } = useParams();
  const handleNavigationError = useHandleNavigationError();

  //Get the single batch from URL
  const { data: batch } = useQuery({
    queryKey: ["batch", batchId],
    queryFn: () => {
      return batchService.getSingle(batchId).catch(() => {
        handleNavigationError();
      });
    },
    initialData: { batch: {} },
    retry: false,
    select: (data: { batch: BatchType }) => {
      return data.batch;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{batch?.name}</title>
      </Helmet>
      {batch?.name ? (
        <Box>
          <h1>{batch?.name}</h1>
          <div className="bigItems">
            <div className="bigItem">
              <h4>{t("translation:created")}</h4>
              <span>
                <PowerPickTimeZoneToLocal
                  date={batch?.creationDate}
                  format={"dddd, MMMM Do YYYY, h:mm a"}
                />
              </span>
            </div>
            <div className="bigItem">
              <h4>{t("translation:priority")}</h4>
              <span>{batch?.priority}</span>
            </div>
            <div className="bigItem">
              <h4>{t("translation:deadline")}</h4>
              <span>{batch?.deadline}</span>
            </div>
            <div className="bigItem">
              <h4>{t("translation:creationDate")}</h4>
              <span>{batch?.creationDate}</span>
            </div>
          </div>
        </Box>
      ) : (
        <div />
      )}
    </div>
  );
}
