import React from "react";
import { Chip, extendVariants } from "@nextui-org/react";

export const CustomChip = extendVariants(Chip, {
  variants: {
    color: {
      orange: {
        base: "bg-secondary-default text-[#ffffff]",
      },
    },
  },
});

interface NextChipProps {
  children: React.ReactNode;
  variant?:
    | "solid"
    | "bordered"
    | "light"
    | "flat"
    | "faded"
    | "shadow"
    | "dot";
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "success"
    | "warning"
    | "danger"
    | "orange";
  size?: "sm" | "md" | "lg";
  radius?: "none" | "sm" | "md" | "lg" | "full";
  avatar?: React.ReactNode;
  startContent?: React.ReactNode;
  endContent?: React.ReactNode;
  isDisabled?: boolean;
  classNames?: { [key: string]: string };
  className?: string;
  onClose?: () => void;
}

interface TagProps {
  children: React.ReactNode;
  variant?:
    | "solid"
    | "bordered"
    | "light"
    | "flat"
    | "faded"
    | "shadow"
    | "dot";
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "success"
    | "warning"
    | "danger"
    | "orange";
  size?: "sm" | "md" | "lg";
  radius?: "none" | "sm" | "md" | "lg" | "full";
  className?: string;
}

/**
 *
 * @param param0 children, variant, color, size, radius, avatar, startContent, endContent, isDisabled, classNames, className, onClose
 * @returns Chip component
 */
const NextChip: React.FC<NextChipProps> = ({
  children,
  variant = "solid",
  color = "default",
  size = "md",
  radius = "full",
  avatar,
  startContent,
  endContent,
  isDisabled = false,
  classNames,
  className,
  onClose,
}) => {
  return (
    <CustomChip
      variant={variant}
      color={color}
      size={size}
      radius={radius}
      avatar={avatar}
      startContent={startContent}
      endContent={endContent}
      isDisabled={isDisabled}
      onClose={onClose}
      classNames={classNames}
      className={className}
    >
      {children}
    </CustomChip>
  );
};

const Tag: React.FC<TagProps> = ({
  children,
  variant = "bordered",
  color = "default",
  size = "sm",
  radius = "none",
  className = "",
}) => {
  const baseClass = "border-1 bg-white";
  const classes = `${baseClass} ${className}`;
  return (
    <CustomChip
      variant={variant}
      color={color}
      size={size}
      radius={radius}
      className={classes}
    >
      {children}
    </CustomChip>
  );
};

export { NextChip, Tag };
