import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { App, Integration } from "/app/src/models";
import useSetting from "/app/src/components/settings/setting";
import Box from "/app/src/components/generic/components/box";

/**
 * Component for Data Push integration menu. Shows settings, mappings, logs
 * manually send, and failed attempts.
 * @param app Data Push app
 * @param integration Data Push integration
 */
export default function IntegrationMenu({
  app,
  integration,
}: {
  app: App;
  integration: Integration;
}) {
  const { t } = useTranslation();
  const { settingValue: enableManual } = useSetting({
    integrationId: integration.id,
    type: "enableManual",
  });
  return (
    <div className="sideMenu">
      <Box>
        <ul>
          <li>
            <NavLink end to={`/apps/${app.id}/integrations/${integration.id}`}>
              {t("translation:settings")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/apps/${app.id}/integrations/${integration.id}/tasks`}
            >
              {t("translation:tasks")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/apps/${app.id}/integrations/${integration.id}/mappings`}
            >
              {t("translation:mappings")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/apps/${app.id}/integrations/${integration.id}/confirmation`}
            >
              {t("translation:confirmation")}
            </NavLink>
          </li>
          <li>
            <NavLink to={`/apps/${app.id}/integrations/${integration.id}/run`}>
              {t("translation:manual_run")}
            </NavLink>
          </li>
          {enableManual === "True" && (
            <li>
              <NavLink
                to={`/apps/${app.id}/integrations/${integration.id}/manual`}
              >
                {t("translation:manually_pull")}
              </NavLink>
            </li>
          )}
          <li>
            <NavLink to={`/apps/${app.id}/integrations/${integration.id}/test`}>
              {t("translation:test")}
            </NavLink>
          </li>
        </ul>
      </Box>
    </div>
  );
}
