import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStatusContext } from "/app/src/contexts/hooks/useStatusContext";
import "./_noConnection.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";

interface LocationType {
  state: {
    from?: string;
  };
}

/**
 *
 * @returns No connection screen
 */
export default function NoConnection() {
  const { t } = useTranslation();
  const { loading, connected } = useStatusContext();
  const navigate = useNavigate();
  const { state } = useLocation() as LocationType;

  useEffect(() => {
    if (!loading && connected) {
      navigate(state?.from || "/dashboard");
    }
  }, [connected, loading, navigate, state?.from]);

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="flex items-center justify-center border !border-[#D3D9E0] rounded-[90px] px-[32px] py-[12px] bg-[#EBEEF2] ">
        <IconBuilder icon="WifiOff" size={24} color="#82878e" />
        <div className="text-2xl font-bold ml-2">
          {t("translation:no_connection")}
        </div>
      </div>
      <div className="pt-xxlarge">
        <div className="text-neutral-charcoal font-semibold text-xlarge">
          {t("translation:no_connection_details")}
        </div>
        <div className="text-[20px] flex flex-col text-center">
          <span>{t("translation:no_connection_instructions")}</span>
          <span className="text-[#3B8BEA] text-[20px]">
            <a className="no-underline" href="mailto:support@itempath.com">
              {t("translation:itempath_support")}
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}
