import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";
import ConvertToLocalTimezone from "/app/src/components/generic/formatting/dateTime";
import { fileService } from "/app/src/services";
import { File as FileType } from "/app/src/models";
import NextButton from "/app/src/components/NextUi/Button";
import NextModal from "/app/src/components/NextUi/Modal";
import ModalBuilder from "/app/src/components/NextUi/helpers/ModalBuilder";
import { useDisclosure } from "@nextui-org/react";
import NextDivider from "/app/src/components/NextUi/Divider";

type RefType = {
  locationFound: boolean;
  setLocationFound: (value: boolean) => void;
};

export default function File({
  file,
  removeFile,
  updateFile,
}: {
  file: FileType;
  removeFile: (file: FileType) => Promise<any> | undefined;
  updateFile: (file: FileType) => void;
}) {
  const { t } = useTranslation();
  const [locationFound, setLocationFound] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { onOpenChange } = useDisclosure();

  //refs needed when using setInterval
  const refVisible = useRef<RefType | null>(null);
  refVisible.current = { locationFound, setLocationFound };

  useEffect(() => {
    setLocationFound(Boolean(file.location));
  }, [file]);

  useEffect(() => {
    const id = setInterval(() => {
      if (refVisible?.current && !refVisible.current.locationFound) {
        if (!file.id) return;
        fileService.getSingle(file.id).then((response) => {
          if (response.file.location) {
            updateFile(response.file);
          }
        });
      }
    }, 10000); //10 seconds
    return () => {
      clearInterval(id);
    };
  }, [updateFile, file.id]);

  const HandleDelete = useCallback(() => {
    removeFile(file);
    onOpenChange();
  }, [file, removeFile, onOpenChange]);

  const closeDeleteModalHandler = useCallback(() => {
    setIsDeleteModalOpen(false);
  }, []);

  const openDeleteModalHandler = useCallback(() => {
    setIsDeleteModalOpen(true);
  }, []);

  const closeDownloadModalHandler = useCallback(() => {
    setIsDownloadModalOpen(false);
  }, []);

  const openDownloadModalHandler = useCallback(() => {
    setIsDownloadModalOpen(true);
  }, []);

  const HandleDownloadClick = useCallback(async () => {
    //retrieve file and download popup
    // xlsx files have an octet stream buffer response type
    // axios needs to know the expected response
    // type is different
    let buffer = false;
    if (file.fileType === ".xlsx") {
      buffer = true;
    }
    return await fileService.downloadFile(file.id, buffer).then((response) => {
      //code based off of https://gist.github.com/bierik/0baa0de30cc4ee6d3fbf8485c4d12bb8
      const contentDisposition = response.headers[
        "content-disposition"
      ] as string;
      const contentType = response.headers["content-type"];
      const filename = contentDisposition.split("filename=")[1].split(";")[0];
      const file = new Blob([response.data], { type: contentType });
      if ("msSaveOrOpenBlob" in window.navigator) {
        (window.navigator as any).msSaveOrOpenBlob(file, filename);
      }
      // For Firefox and Chrome
      else {
        // Bind blob on disk to ObjectURL
        const data = URL.createObjectURL(file);
        const aTag = document.createElement("a");
        aTag.setAttribute("style", "display: none");
        aTag.href = data;
        aTag.download = filename;
        document.body.appendChild(aTag);
        aTag.click();
        // For Firefox
        setTimeout(() => {
          document.body.removeChild(aTag);
          // Release resource on disk after triggering the download
          window.URL.revokeObjectURL(data);
        }, 100);
      }
    });
  }, [file]);

  const onClick = useCallback(() => {
    HandleDownloadClick().then(() => {
      closeDownloadModalHandler();
    });
  }, [HandleDownloadClick, closeDownloadModalHandler]);

  return (
    <>
      <Row justify="start" gutter={16}>
        <Col span={16}>
          <h3>
            <ConvertToLocalTimezone
              {...(file.creationDate && { date: file.creationDate })}
            />
          </h3>
          <p>{file.name}</p>
        </Col>
        {file.location ? (
          <Col span={4}>
            <NextButton
              size="md"
              variant="bordered"
              color="default"
              onClick={openDownloadModalHandler}
              fullWidth
              className="hover:border-primary-default hover:text-primary-default"
            >
              {t("translation:download")} {t("translation:file")}
            </NextButton>
            <NextModal
              disableAnimation={false}
              isOpen={isDownloadModalOpen}
              onOpenChange={setIsDownloadModalOpen}
              placement="top"
            >
              {ModalBuilder({
                warning: false,
                modalHeader: t("translation:confirm_file_download"),
                modalFooter: (
                  <>
                    <NextButton
                      size="md"
                      color="default"
                      variant="bordered"
                      onClick={closeDownloadModalHandler}
                    >
                      {t("translation:cancel")}
                    </NextButton>
                    <NextButton size="md" color="primary" onClick={onClick}>
                      {t("translation:download")}
                    </NextButton>
                  </>
                ),
              })}
            </NextModal>
          </Col>
        ) : (
          <Col span={4}>
            <h4>{t("translation:generating_wait")}</h4>
          </Col>
        )}
        <Col span={4}>
          {file.location ? (
            <>
              <NextButton
                size="md"
                color="red"
                onClick={openDeleteModalHandler}
                fullWidth
              >
                {t("translation:delete")} {t("translation:file")}
              </NextButton>
              <NextModal
                disableAnimation={false}
                isOpen={isDeleteModalOpen}
                onOpenChange={setIsDeleteModalOpen}
                placement="top"
              >
                {ModalBuilder({
                  warning: false,
                  modalHeader: t("translation:confirm_file_delete"),
                  modalFooter: (
                    <>
                      <NextButton
                        size="md"
                        color="default"
                        variant="bordered"
                        onClick={closeDeleteModalHandler}
                      >
                        {t("translation:cancel")}
                      </NextButton>
                      <NextButton size="md" color="red" onClick={HandleDelete}>
                        {t("translation:delete")}
                      </NextButton>
                    </>
                  ),
                })}
              </NextModal>
            </>
          ) : (
            <>
              <NextButton
                size="md"
                color="red"
                onClick={openDeleteModalHandler}
                fullWidth
              >
                {t("translation:cancel")}
              </NextButton>
              <NextModal
                disableAnimation={false}
                isOpen={isDeleteModalOpen}
                onOpenChange={setIsDeleteModalOpen}
                placement="top"
              >
                {ModalBuilder({
                  warning: false,
                  modalHeader: t("translation:confirm_file_delete"),
                  modalFooter: (
                    <>
                      <NextButton
                        size="md"
                        color="default"
                        variant="bordered"
                        onClick={closeDeleteModalHandler}
                      >
                        {t("translation:cancel")}
                      </NextButton>
                      <NextButton size="md" color="red" onClick={HandleDelete}>
                        {t("translation:delete")}
                      </NextButton>
                    </>
                  ),
                })}
              </NextModal>
            </>
          )}
        </Col>
      </Row>
      <NextDivider />
    </>
  );
}
