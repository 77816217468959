import { Col, Row } from "antd";
import Role from "./role";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Role as RoleType } from "/app/src/models";
import Box from "/app/src/components/generic/components/box";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";
import { useCallback } from "react";

export default function Roles({ roles }: { roles: RoleType[] }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToNewRole = useCallback(() => {
    navigate("/users/roles/new");
  }, [navigate]);

  return (
    <div className="userGroupMenu sideMenu">
      <Box>
        <Row>
          <Col span={18}>
            <h2>{t("translation:role_details")}</h2>
          </Col>
          <Col span={6}>
            <IconBuilder
              icon="PlusCircle"
              color="#1890ff"
              onClick={navigateToNewRole}
              size={28}
              className="float-right"
            />
          </Col>
        </Row>
        <ul>
          {roles.map((role) => (
            <Role role={role} key={role.id} />
          ))}
        </ul>
      </Box>
    </div>
  );
}
