import { Col, Row } from "antd";
import NextDivider from "/app/src/components/NextUi/Divider";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import homeIcon from "/app/src/images/home-alt.svg";
import "./_updateLicense.scss";
import { useStatusContext } from "/app/src/contexts/hooks/useStatusContext";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import NextButton from "/app/src/components/NextUi/Button";
import Box from "/app/src/components/generic/components/box";

/**
 *
 * @returns Update License page
 */
export default function UpdateLicense() {
  const { t } = useTranslation();
  const { activeWarehouses } = useStatusContext();
  const navigate = useNavigate();

  const goToLicenseForm = useCallback(() => {
    navigate("/update-license/form");
  }, [navigate]);

  return (
    <div>
      <Helmet>
        <title>{t("translation:update_license")} - ItemPath</title>
      </Helmet>
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 20, offset: 2 }}
          lg={{ span: 6, offset: 9 }}
        >
          <Box>
            <IconBuilder icon="Warning" color="#a65900" />
            <span className="header">
              {t("translation:update_your_license")}
            </span>
            <div className="paragraph">
              {t("translation:warehouse_exceeded")}
            </div>
            <NextDivider className="my-6" />
            <div className="homeIconContainer">
              <img src={homeIcon} alt="Home" className="homeIcon" />
            </div>
            <div className="subHeader">
              {t("translation:active_warehouses")}
            </div>
            <div className="licenseInfo">
              <span className="licenseLimit">{activeWarehouses}</span>
              <span className="licenseReached">
                {t("translation:license_limit_reached")}
              </span>
            </div>
            <NextDivider className="my-6" />
            <div className="buttonContainer">
              <NextButton
                size="md"
                variant="bordered"
                radius="md"
                color="default"
                onClick={goToLicenseForm}
                className="hover:border-primary-default hover:text-primary-default"
              >
                {t("translation:update_your_license")}{" "}
                <IconBuilder icon="ArrowRight" />
              </NextButton>
            </div>
          </Box>
        </Col>
      </Row>
    </div>
  );
}
