import React, { forwardRef } from "react";

import { Action, ActionProps } from "../Action";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";

export const Handle = forwardRef<HTMLButtonElement, ActionProps>(
  (props, ref) => {
    return (
      <Action
        ref={ref}
        cursor="grab"
        data-cypress="draggable-handle"
        {...props}
      >
        <IconBuilder icon="Drag" />
      </Action>
    );
  },
);
