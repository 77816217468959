import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { kitService } from "/app/src/services";

import Search from "../search";
import getOrderByQuery from "/app/src/helpers/table";

import { useTranslation } from "react-i18next";
import { Kit as KitType } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";

export default function Kits() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const columnUpgrade = createColumnHelper<KitType>();
  const columns = useMemo(
    () => [
      columnUpgrade.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: t("translation:name"),
      }),
    ],
    [t, columnUpgrade],
  );

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");

  const goToKit = useCallback(
    (kitId: number) => {
      navigate(`/explorer/kits/${kitId}`);
    },
    [navigate],
  );

  const { data: kits, isFetching } = useQuery({
    queryKey: ["kits", page, pageSize, searchString, sort],
    queryFn: () => {
      return kitService.getAll(
        buildParams({
          limit: pageSize,
          page,
          search: searchString,
          orderby: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { kits: [] },
    select: (data: { kits: KitType[] }) => {
      return data.kits;
    },
  });

  const { data: kitsCount } = useQuery({
    queryKey: ["kitsCount", searchString],
    queryFn: () => {
      return kitService.getCount(buildParams({ search: searchString }));
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:kits")} - ItemPath</title>
      </Helmet>
      <div className="search box">
        <Search setSearchString={setSearchString} />
      </div>
      <Table
        loading={isFetching}
        rows={kits}
        tableColumns={columns}
        length={kitsCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage,
          setPageSize,
        }}
        enableRowSelection
        rowClicked={goToKit}
        emptyText={t("translation:no_kits_found")}
      />
    </div>
  );
}
