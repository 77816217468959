import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { storageRuleService } from "/app/src/services";

import Search from "../search";

import { useTranslation } from "react-i18next";
import { StorageRule as StorageRuleType } from "/app/src/models";
import getOrderByQuery from "/app/src/helpers/table";
import { buildParams } from "/app/src/helpers/params";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";

export default function StorageRules() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");

  const columnHelper = createColumnHelper<StorageRuleType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("description", {
        id: "description",
        cell: (info) => info.getValue(),
        header: () => t("translation:description"),
      }),
      columnHelper.accessor("requiredCapacity", {
        id: "requiredCapacity",
        cell: (info) => info.getValue(),
        header: () => t("translation:capacity"),
      }),
    ],
    [columnHelper, t],
  );

  const goToStorageRule = useCallback(
    (storageRuleId: number) => {
      navigate(`/explorer/storage_rules/${storageRuleId}`);
    },
    [navigate],
  );

  const { data: storageRules, isFetching } = useQuery({
    queryKey: ["storageRules", page, pageSize, searchString, sort],
    queryFn: () => {
      return storageRuleService.getAll(
        buildParams({
          limit: pageSize,
          page,
          search: searchString,
          orderby: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { storage_rules: [] },

    select: (data: { storage_rules: StorageRuleType[] }) => {
      return data.storage_rules;
    },
  });

  const { data: storageRulesCount } = useQuery({
    queryKey: ["storageRulesCount", searchString],
    queryFn: () => {
      return storageRuleService.getCount(buildParams({ search: searchString }));
    },
    initialData: { count: 0 },

    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:storage_rules")} - ItemPath</title>
      </Helmet>
      <div className="search box">
        <Search setSearchString={setSearchString} />
      </div>
      <Table
        loading={isFetching}
        rows={storageRules}
        tableColumns={columns}
        length={storageRulesCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage,
          setPageSize,
        }}
        enableRowSelection
        rowClicked={goToStorageRule}
        emptyText={t("translation:no_storage_rules")}
      />
    </div>
  );
}
