import { useTranslation } from "react-i18next";
import { useAccessContext } from "/app/src/contexts/hooks/useAccessContext";
import { IconToolTip } from "/app/src/components/icons/IconBuilder";

/**
 * Component that displays the edit and delete icons for a report. The icons will be green if the user has access
 * to the action and red if they do not
 * @param accessPermissions permissions for the current user
 */
export default function PermissionIcons() {
  const { canEdit, canDelete } = useAccessContext();
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-end ml-auto ">
      <IconToolTip
        content={
          canEdit
            ? t("translation:edit_access")
            : t("translation:no_edit_access")
        }
        icon="Edit"
        size={16}
        color={canEdit ? "#23ce6b" : "#e00000"}
        tipPlacement="bottom-end"
      />

      <IconToolTip
        content={
          canDelete
            ? t("translation:delete_access")
            : t("translation:no_delete_access")
        }
        icon="Delete"
        size={16}
        color={canDelete ? "#23ce6b" : "#e00000"}
        tipPlacement="bottom-end"
      />
    </div>
  );
}
