export default function getDisabledStyle(target: "base" | "input" | "wrapper") {
  return {
    variants: {
      isDisabled: {
        true: {
          [target]: "opacity-50 cursor-not-allowed pointer-events-auto",
        } as { [key in "base" | "input" | "wrapper"]: string },
      },
    },
  };
}
