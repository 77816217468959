import React, { useMemo } from "react";

import NewFilterForm from "./newFilterForm";
import Filter from "./filter";
import { useTranslation } from "react-i18next";
import { Report, ReportColumn, ReportFilter } from "/app/src/models";

export default function Filters({
  report,
  filters,
  columns,
}: {
  report: Report;
  filters: ReportFilter[];
  columns: ReportColumn[];
}) {
  const { t } = useTranslation();
  const sortedFilters = useMemo(() => {
    return [...filters].sort((a, b) => a.filterGroup - b.filterGroup);
  }, [filters]);
  return (
    <div className="reportFilters">
      <h3>
        {t("translation:data")} {t("translation:filters")}
      </h3>
      {sortedFilters.map((filter) => (
        <Filter filter={filter} key={filter.id} reportColumns={columns} />
      ))}
      <NewFilterForm report={report} reportColumns={columns} />
    </div>
  );
}
