import React, { useCallback, useState } from "react";
import { Helmet } from "react-helmet";
import { Col, Row, message } from "antd";
import CurrentOrder from "./orderHeader";
import OrderLines from "./orderLines";
import { OrderBuilderLine } from "./orderLine/type";
import { orderService } from "/app/src/services";
import { Order } from "/app/src/models";
import { useTranslation } from "react-i18next";

export default function OrderBuilder() {
  const [orderLines, setOrderLines] = useState<OrderBuilderLine[]>([]);
  const { t } = useTranslation();

  function formatForm(order: Order, lines: OrderBuilderLine[]): Order {
    //
    const formatted = {
      name: order.name,
      directionType: order.type,
      priority: order.priority,
      deadline: order.deadline,
      Info1: order.Info1,
      Info2: order.Info2,
      warehouseName: order.warehouseName,
      lines,
    };
    return formatted;
  }

  const addLine = useCallback(
    (newLine: OrderBuilderLine) => {
      if (orderLines.length === 0) {
        newLine.id = 1;
      } else {
        const id = orderLines[orderLines.length - 1].id;
        newLine.id = id + 1;
      }
      setOrderLines((lines) => [...lines, newLine]);
    },
    [orderLines],
  );

  const updateLineQuantity = useCallback(
    (lineId: number, newQuantity: number) => {
      setOrderLines(
        orderLines.map((orderLine) =>
          orderLine.id === lineId
            ? { ...orderLine, quantity: newQuantity }
            : orderLine,
        ),
      );
    },
    [orderLines],
  );

  const removeLine = useCallback((lineId: number) => {
    setOrderLines((lines) => lines.filter((c) => c.id !== lineId));
  }, []);

  const submitOrder = useCallback(
    (
      order: Order,
      setFieldError: (field: string, message: string | undefined) => void,
    ) => {
      return orderService
        .createSingle(formatForm(order, orderLines))
        .then(() => setOrderLines([]))
        .then(() => {
          message.success(t("translation:order_created"));
        })
        .catch((error) => {
          setFieldError("name", error.message);
          message.error(error.message);
        });
    },
    [orderLines, t],
  );

  return (
    <div className="app " id="order_builder">
      <Helmet>
        <title>Order Builder - ItemPath</title>
      </Helmet>
      <Row gutter={20}>
        <Col sm={{ span: 24, order: 2 }} lg={{ span: 16, order: 1 }}>
          <OrderLines
            lines={orderLines}
            addLine={addLine}
            updateLineQuantity={updateLineQuantity}
            removeLine={removeLine}
          />
        </Col>
        <Col sm={{ span: 24, order: 1 }} lg={{ span: 8, order: 2 }}>
          <CurrentOrder submit={submitOrder} />
        </Col>
      </Row>
    </div>
  );
}
