import APIStatusChart from "./apiStatusChart";
import StatusHook from "/app/src/components/status/statusHook";
import Box from "/app/src/components/generic/components/box";

/**
 * Component for displaying api status
 * @returns JSX.Element
 *
 */
export default function ApiStatus() {
  const { apiConnectionCounts } = StatusHook();

  return (
    <Box>
      <APIStatusChart data={apiConnectionCounts} />
    </Box>
  );
}
