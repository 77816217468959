import React, { useEffect, useState, useMemo } from "react";

import { logService } from "/app/src/services";
import Status from "./status";
import { useTranslation } from "react-i18next";
import getOrderByQuery from "/app/src/helpers/table";
import { buildParams } from "/app/src/helpers/params";
import { useQuery } from "@tanstack/react-query";
import { Log as LogType } from "/app/src/models";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";

/**
 * Shows the logs for a workflow run
 * @param param object containing workflowHistoryId
 */
export default function ActionLogs({
  workflowHistoryId,
}: {
  workflowHistoryId: number | undefined;
}) {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<LogType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("status", {
        id: "status",
        cell: (info) => <Status value={info.getValue()} />,
        header: "",
      }),
      columnHelper.accessor("appName", {
        id: "appName",
        cell: (info) => info.getValue(),
        header: t("translation:action"),
      }),
      columnHelper.accessor("description", {
        id: "description",
        cell: (info) => info.getValue(),
        header: t("translation:description"),
      }),
      columnHelper.accessor("error", {
        id: "error",
        cell: (info) => info.getValue(),
        header: t("translation:error"),
      }),
    ],
    [columnHelper, t],
  );
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);
  const notFoundText = t("translation:logs_not_found_workflow_history");
  const notSelectedText = t("translation:workflow_record_not_selected");
  const [emptyText, setEmptyText] = useState(notSelectedText);

  useEffect(() => {
    if (workflowHistoryId) {
      setEmptyText(notFoundText);
    }
  }, [workflowHistoryId, notFoundText]);

  const { data: logs, isFetching } = useQuery({
    queryKey: ["logs", page, pageSize, workflowHistoryId, sort],
    queryFn: () => {
      return logService.getAll(
        buildParams({
          limit: pageSize,
          page,
          workflowHistoryId,
          orderby: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { logs: [] },
    select: (data: { logs: LogType[] }) => {
      return data.logs;
    },
  });

  const { data: logsCount } = useQuery({
    queryKey: ["logsCount", workflowHistoryId],
    queryFn: () => {
      return logService.getCount(
        buildParams({
          workflowHistoryId,
        }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return (
    <div className="workflow">
      <Table
        loading={isFetching}
        rows={logs}
        tableColumns={columns}
        length={logsCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage,
          setPageSize,
        }}
        enableRowSelection={false}
        emptyText={emptyText}
      />
    </div>
  );
}
