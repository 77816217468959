import React, { useCallback } from "react";

import { Formik, FormikHelpers, FormikProps } from "formik";
import { Form, Select, SubmitButton } from "formik-antd";
import { widgetDatasetService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { SearchFilter } from "/app/src/components/generic/components/searchFilter";
import { Widget, WidgetDataset, Snapshot } from "/app/src/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";

interface FormValues {
  snapshotId: number | undefined;
  widgetId?: number;
}

/**
 * Component to add a new dataset to a widget
 * @param param0 props containing widget, activeForm, toggleForm, createDataset, getData
 */
export default function NewDataset({
  widget,
  snapshots,
}: {
  widget: Widget;
  snapshots: Snapshot[];
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  // Get Snapshots

  const { mutateAsync: createDataset } = useMutation({
    mutationFn: (newDataset: Omit<WidgetDataset, "Snapshot">) => {
      return widgetDatasetService
        .createSingle(newDataset)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["widgetDatasets", widget.id],
        (oldData: { widget_datasets: WidgetDataset[] }) => {
          return {
            widget_datasets: [
              ...oldData.widget_datasets,
              response.widget_dataset,
            ],
          };
        },
      );
    },
  });

  /**
   * Map snapshots to options for the select
   */
  const mapSnapshots = useCallback((c: Snapshot) => {
    return (
      <Select.Option key={c.id} value={c.id}>
        {c.name} <span style={{ fontStyle: "italic" }}>({c.reportName})</span>
      </Select.Option>
    );
  }, []);
  /**
   * Form handler for adding new dataset
   */
  const addDatasetHandler = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      await createDataset({
        widgetId: widget.id,
        snapshotId: values.snapshotId,
      }).then(() => {
        actions.resetForm();
      });
    },
    [createDataset, widget.id],
  );

  /**
   * Form for adding a new dataset
   * @param param0 Formik props
   */
  const newDataSetForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isSubmitting }) => (
        <Form>
          <div className="newDatasetForm">
            <div className="snapshotSelect">
              <Form.Item name="snapshotId">
                <SearchFilter
                  list={snapshots}
                  mapOptionsFn={mapSnapshots}
                  name="snapshotId"
                  placeholder={t("translation:select_snapshot")}
                />
              </Form.Item>
            </div>
            <div className="newDatasetSubmit">
              <SubmitButton
                type="primary"
                size="large"
                disabled={!dirty || isSubmitting}
              >
                {t("translation:add")}
              </SubmitButton>
            </div>
          </div>
        </Form>
      ),
      [snapshots, t, mapSnapshots],
    );

  return (
    <div className="newDataset">
      <Formik
        component={newDataSetForm}
        initialValues={{
          snapshotId: undefined,
        }}
        onSubmit={addDatasetHandler}
      />
    </div>
  );
}
