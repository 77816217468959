import React, { CSSProperties, memo } from "react";
import type { UniqueIdentifier } from "@dnd-kit/core";
import { AnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { TreeItem, Props as TreeItemProps } from "./TreeItem";
import { iOS } from "../../utilities";

interface Props extends TreeItemProps {
  id: UniqueIdentifier;
}

/**
 * Determines whether to animate layout changes based on the sorting and dragging state.
 *
 * @param {Object} params - The parameters for the function.
 * @param {boolean} params.isSorting - Indicates if the item is currently being sorted.
 * @param {boolean} params.wasDragging - Indicates if the item was previously being dragged.
 * @returns {boolean} - Returns `false` if the item is sorting or was dragging, otherwise `true`.
 */
const animateLayoutChanges: AnimateLayoutChanges = ({
  isSorting,
  wasDragging,
}) => (isSorting || wasDragging ? false : true);

const SortableTreeItem = memo(function SortableTreeItem({
  id,
  depth,
  ...props
}: Props) {
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges,
  });
  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <TreeItem
      ref={setDraggableNodeRef}
      wrapperRef={setDroppableNodeRef}
      style={style}
      depth={depth}
      ghost={isDragging}
      disableSelection={iOS}
      disableInteraction={isSorting}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      {...props}
    />
  );
});

export default SortableTreeItem;
