import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { materialService, dynamicFieldService } from "/app/src/services";
import Search from "../search";
import { useTranslation } from "react-i18next";
import { buildParams } from "/app/src/helpers/params";
import { DynamicField, Material as MaterialType } from "/app/src/models";
import getOrderByQuery from "/app/src/helpers/table";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";

export default function Materials() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");

  const goToMaterial = useCallback(
    (materialId: number) => {
      navigate(`/explorer/materials/${materialId}`);
    },
    [navigate],
  );

  const { data: materials, isFetching } = useQuery({
    queryKey: ["materials", page, pageSize, searchString, sort],
    queryFn: () => {
      return materialService.getAll(
        buildParams({
          limit: pageSize,
          page,
          search: searchString,
          orderby: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { materials: [] },
    select: (data: { materials: MaterialType[] }) => {
      return data.materials;
    },
  });

  const { data: materialsCount } = useQuery({
    queryKey: ["materialsCount", searchString],
    queryFn: () => {
      return materialService.getCount(
        buildParams({
          search: searchString,
        }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  const { data: dynamicFields } = useQuery({
    queryKey: ["dynamicFields", "Materialbase"],
    queryFn: () => {
      return dynamicFieldService.getAll(
        buildParams({
          baseTable: "Materialbase",
        }),
      );
    },
    initialData: { dynamic_fields: [] },
    select: (data: { dynamic_fields: DynamicField[] }) => {
      return data.dynamic_fields;
    },
  });

  const columnHelper = createColumnHelper<MaterialType | DynamicField>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: () => t("translation:name"),
      }),
      ...dynamicFields.map((dynamicField) =>
        columnHelper.accessor(dynamicField.field, {
          id: dynamicField.field,
          cell: (info) => info.getValue(),
          header: () => dynamicField.name,
        }),
      ),
    ],
    [columnHelper, t, dynamicFields],
  );

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:materials")} - ItemPath</title>
      </Helmet>
      <div className="search box">
        <Search setSearchString={setSearchString} />
      </div>
      <Table
        loading={isFetching}
        rows={materials}
        tableColumns={columns}
        length={materialsCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage,
          setPageSize,
        }}
        rowClicked={goToMaterial}
        emptyText={t("translation:no_materials")}
      />
    </div>
  );
}
