import React from "react";
import { Switch, cn, extendVariants } from "@nextui-org/react";
import getDisabledStyle from "./helpers/disabledState";

export const CustomSwitch = extendVariants(Switch, getDisabledStyle("wrapper"));

interface NextSwitchProps {
  children?: React.ReactNode;
  value?: string;
  name?: string;
  size?: "sm" | "md" | "lg";
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "success"
    | "warning"
    | "danger";
  thumbIcon?: React.ReactNode;
  startContent?: React.ReactNode;
  endContent?: React.ReactNode;
  isSelected?: boolean;
  defaultSelected?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  disableAnimation?: boolean;
  classNames?: { [key: string]: string };
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onValueChange?: (isSelected: boolean) => void;
  isMiddle?: boolean;
}

/**
 *
 * @param param0 children, value, name, size, color, thumbIcon, startConent, endContent, isSelected, defaultSelected, isRequired, isReadOnly, isDisabled, disableAnimation, classNames, onChange, onValueChange
 * @returns NextUI Switch component
 */
const NextSwitch: React.FC<NextSwitchProps> = ({
  children,
  value,
  name,
  size = "md",
  color = "primary",
  thumbIcon,
  startContent,
  endContent,
  isSelected,
  defaultSelected,
  isReadOnly,
  isDisabled = false,
  disableAnimation = false,
  classNames,
  className,
  onChange,
  onValueChange,
  isMiddle,
}) => {
  return (
    <CustomSwitch
      value={value}
      name={name}
      size={size}
      color={color}
      thumbIcon={thumbIcon}
      startContent={startContent}
      endContent={endContent}
      isSelected={isSelected}
      defaultSelected={defaultSelected}
      isReadOnly={isReadOnly}
      isDisabled={isDisabled}
      disableAnimation={disableAnimation}
      classNames={
        isMiddle ? { thumb: cn("group-data-[selected=true]:ml-[.66rem]") } : {}
      }
      className={className}
      onChange={onChange}
      onValueChange={onValueChange}
    >
      {children}
    </CustomSwitch>
  );
};

export default NextSwitch;
