import { useEffect, useState, useContext, useCallback } from "react";

import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, InputNumber } from "formik-antd";
import Time from "./time";
import TimeZoneContext from "/app/src/contexts/TimeZoneContext";
import { getDateFormat, reverseDateFormat } from "/app/src/helpers/time";
import { Trigger } from "/app/src/models";
import { triggerService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { handleSubmissionErrors } from "/app/src/helpers/forms";
import { triggerSchema } from "/app/src/schemas/triggerSchema";

interface FormValues {
  triggerFrequency: string | undefined;
  triggerTime: string;
  triggerInterval: string | undefined;
  triggerNumber: string[] | string;
}

export default function Triggers({
  object,
  updateTrigger,
  objectType = "workflow",
}: {
  object: { id?: number; trigger?: Trigger };
  updateTrigger: (trigger: Trigger) => void;
  objectType?: "integration" | "workflow";
}) {
  const { t } = useTranslation();
  const [triggerNumbers, setTriggerNumbers] = useState<string | string[]>([]);
  const { timeZone } = useContext(TimeZoneContext);

  /**
   * Format form values to match the trigger model
   * @param values FormValues
   * @param timeZone string
   */
  const formatForm = useCallback(
    (values: FormValues, timeZone?: string) => {
      if (Array.isArray(values.triggerNumber)) {
        values.triggerNumber = values.triggerNumber.join(" ");
      }
      const time = reverseDateFormat(
        values.triggerTime,
        "YYYY-MM-DDTHH:mm:ssZ",
        timeZone,
      );
      const ret = {
        triggerFrequency: values.triggerFrequency,
        triggerInterval: values.triggerInterval,
        triggerTime: time,
        triggerNumber: values.triggerNumber,
      };
      if (object?.trigger?.id) {
        return ret;
      }
      if (objectType === "workflow") {
        ret["workflowId"] = object.id;
      } else if (objectType === "integration") {
        ret["integrationId"] = object.id;
      }
      return ret;
    },
    [object, objectType],
  );

  useEffect(() => {
    //need to convert the trigger type object into json string, which is used by
    //the dropdown for the value prop
    if (!object?.trigger) {
      return;
    }

    //trigger numbers track which weekdays the weekly trigger occurs -
    //need to split from a space-separated list into an array
    if (
      object.trigger?.triggerFrequency === "week" &&
      object.trigger?.triggerNumber
    ) {
      setTriggerNumbers(object.trigger.triggerNumber.split(" "));
    } else {
      setTriggerNumbers(object.trigger.triggerNumber);
    }
  }, [object]);

  const handleSubmit = useCallback(
    (
      object: { id?: number; trigger?: Trigger },
      values: FormValues,
      setSubmitting: (isSubmitting: boolean) => void,
      updateTrigger: (trigger: Trigger) => void,
      setFieldError: (field: string, message: string | undefined) => void,
      timeZone?: string,
    ) => {
      //conditionally update or create trigger
      if (object?.trigger?.id) {
        triggerService
          .updateSingle(object.trigger.id, formatForm(values, timeZone))
          .then((response) => {
            if ("errors" in response) {
              handleSubmissionErrors(response.errors, setFieldError);
            } else {
              updateTrigger(response.trigger);
            }
            setSubmitting(false);
          });
      } else {
        triggerService.createSingle(formatForm(values)).then((response) => {
          if ("errors" in response) {
            handleSubmissionErrors(response.errors, setFieldError);
          } else {
            updateTrigger(response.trigger);
          }
          setSubmitting(false);
        });
      }
    },
    [formatForm],
  );

  const onSubmitHandler = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      await handleSubmit(
        object,
        values,
        actions.setSubmitting,
        updateTrigger,

        actions.setFieldError,
        timeZone,
      );
    },
    [handleSubmit, object, timeZone, updateTrigger],
  );

  const triggerForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form>
          <Row justify="start" gutter={16}>
            <Col span={3}>
              <span className="blockText">{t("translation:every")}</span>
            </Col>
            <Col span={2}>
              <Form.Item name="triggerInterval">
                <InputNumber
                  name="triggerInterval"
                  size="large"
                  min={1}
                  max={31}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Time />
            <Col span={3}>
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || !isValid}
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [t],
    );
  return (
    <div className="triggers">
      <Row justify="start" gutter={16}>
        <Col span={24}>
          <Formik
            component={triggerForm}
            enableReinitialize
            validationSchema={triggerSchema}
            initialValues={{
              triggerFrequency: object.trigger?.triggerFrequency,
              triggerInterval: object.trigger?.triggerInterval,
              triggerTime: getDateFormat(
                object.trigger?.triggerTime,
                "YYYY-MM-DDTHH:mm:ssZ",
                timeZone,
              ),
              triggerNumber: triggerNumbers,
            }}
            onSubmit={onSubmitHandler}
          />
        </Col>
      </Row>
    </div>
  );
}
