import { useQuery } from "@tanstack/react-query";
import { connectionService } from "/app/src/services";
import { Connection } from "/app/src/models";
import { buildParams } from "/app/src/helpers";
import { Row, Col } from "antd";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

/**
 * Component for displaying list of connections
 */
export default function ConnectionsList() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  //query the list of connections
  const { data: connectionList } = useQuery({
    queryKey: ["connectionsList"],
    queryFn: () => {
      return connectionService.getAll(
        buildParams({
          type: "[nor]PowerPick SQL;PowerPick Web Services",
        }),
      );
    },
    initialData: { connections: [] },
    select: (data: { connections: Connection[] }) => {
      return data.connections;
    },
  });

  const connectionClick = useCallback(() => {
    navigate("/settings/connections");
  }, [navigate]);

  return (
    <Row gutter={[48, 40]} style={{ whiteSpace: "nowrap" }}>
      {connectionList.map((connection: Connection) => (
        <Col key={connection.id} span={24}>
          <div style={{ textAlign: "left" }} key={connection.id}>
            <span className="title" style={{ paddingRight: 20 }}>
              {connection.name}
            </span>
            {connection.status === 1 ? (
              <span
                onClick={connectionClick}
                aria-hidden
                className="connected inline-flex items-center"
              >
                {t("translation:connected")}
                <IconBuilder
                  icon="Checkmark"
                  size={18}
                  color="#3caf96"
                  className="ml-1"
                />
              </span>
            ) : (
              <span
                onClick={connectionClick}
                aria-hidden
                className="disconnected inline-flex items-center"
              >
                {t("translation:disconnected")}{" "}
                <IconBuilder
                  icon="ExclamationCircle"
                  size={18}
                  color="#ea3b3b"
                  className="ml-1"
                />
              </span>
            )}
          </div>
        </Col>
      ))}
    </Row>
  );
}
