import React, { useCallback } from "react";
import { sortPermissions } from "./helpers";
import { accessService, rolePermissionService } from "/app/src/services";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { buildParams } from "/app/src/helpers";
import PermissionSwitch from "./permissionSwitch";
import { Access, Permission } from "/app/src/models";
import { useTranslation } from "react-i18next";

/**
 * Displays the permission level for a given role for the given resource
 * @param param0 props containing the role id, the code of the permission, the resource name and the list of all permissions
 */
export default function PermissionLevel({
  code,
  roleId,
  resource,
  allPermissions,
  view = true,
  edit = true,
  create = true,
  delete: del = true,
}: {
  code: string;
  roleId: number;
  accessEnabled?: boolean;
  resource: string;
  allPermissions: { permissions: Permission[] };
  view?: boolean;
  edit?: boolean;
  create?: boolean;
  delete?: boolean;
}) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { data } = useQuery({
    queryKey: ["permissions", roleId, code],
    queryFn: () =>
      rolePermissionService
        .getRolePermissions(
          roleId,
          buildParams({
            search: code,
            roleId,
          }),
        )
        .then((response) => {
          return sortPermissions(response.permissions, code);
        }),
    enabled: roleId !== undefined,
    initialData: {},
  });

  const handleSwitchChange = useCallback(
    async (checked: boolean, level) => {
      const fullCode = `${code}-${level}`;
      const permission = allPermissions.permissions.find(
        (permission) => permission.code === fullCode,
      );
      if (!checked) {
        rolePermissionService
          .removeRolePermission(roleId, permission.id)
          .then(() => {
            queryClient.invalidateQueries(["permissions", roleId]);
          });

        queryClient.setQueryData(["permissions", roleId, code], (oldData) => {
          //revoking 001 will also remove all other permissions
          if (level === "001") {
            return { [code]: [] };
          }
          return {
            [code]: oldData[code].filter((permission) => permission !== level),
          };
        });
      } else {
        rolePermissionService
          .addRolePermission(roleId, permission.id)
          .then(() => {
            queryClient.invalidateQueries(["permissions", roleId]);
          });

        queryClient.setQueryData(
          ["permissions", roleId, code],
          (oldData: object) => {
            if (!(code in oldData)) {
              return {
                [code]: [level],
              };
            }
            return {
              [code]: [...oldData[code], level],
            };
          },
        );
      }
      //create permissions not available for accesses
      if (level === "002") {
        return;
      }
      //update the access for this resourceType
      const resourceAccesses: { accesses: Access[] } = queryClient.getQueryData(
        ["modifiedResources", roleId, resource],
      );
      if (!resourceAccesses) return;
      const levels = { "001": "view", "003": "edit", "004": "delete" };
      for (const access of resourceAccesses.accesses) {
        let actionList = [];
        if (!checked) {
          if (level === "001") {
            actionList = [];
          } else {
            actionList = access.actionList.filter(
              (action) => action !== levels[level],
            );
          }
        } else {
          if (!access.actionList.includes(levels[level])) {
            access.actionList.push(levels[level]);
          }
          actionList = access.actionList;
        }
        //don't update "fake" accesses - accesses that don't belong to role
        if (access.id) {
          await accessService.updateSingle(access.id, {
            action: actionList.join(","),
          });
        }
      }
      queryClient.invalidateQueries(["modifiedResources", roleId, resource]);
    },
    [allPermissions, code, queryClient, resource, roleId],
  );

  const handleViewSwitchChange = useCallback(
    async (checked: boolean) => {
      return await handleSwitchChange(checked, "001");
    },
    [handleSwitchChange],
  );

  const handleCreateSwitchChange = useCallback(
    async (checked: boolean) => {
      return await handleSwitchChange(checked, "002");
    },
    [handleSwitchChange],
  );

  const handleEditSwitchChange = useCallback(
    async (checked: boolean) => {
      return await handleSwitchChange(checked, "003");
    },
    [handleSwitchChange],
  );

  const handleDeleteSwitchChange = useCallback(
    async (checked: boolean) => {
      return await handleSwitchChange(checked, "004");
    },
    [handleSwitchChange],
  );

  const calculateIsMiddle = useCallback(
    (level) => {
      //if at least one access has a different permission level than isChecked, then it's in the middle
      const resourceAccesses: { accesses: Access[] } = queryClient.getQueryData(
        ["modifiedResources", roleId, resource],
      );
      if (!resourceAccesses) return false;

      //if any two accesses have different permission levels, then it's in the middle
      let foundAction;
      for (const access of resourceAccesses.accesses) {
        // first time through, set foundAction to the first action
        if (foundAction === undefined) {
          foundAction = access.actionList.includes(level);
        } else {
          if (access.actionList.includes(level) !== foundAction) {
            return true;
          }
        }
      }
      return false;
    },
    [queryClient, roleId, resource],
  );

  return (
    <>
      {view ? (
        <div>
          <h3>{t("translation:view")}</h3>
          <PermissionSwitch
            isChecked={data?.[code]?.includes("001") && view}
            handleSwitchChange={handleViewSwitchChange}
            isMiddle={calculateIsMiddle("view")}
            disabled={false}
          />{" "}
        </div>
      ) : (
        <div />
      )}
      {create ? (
        <div>
          <h3>{t("translation:create")}</h3>
          <PermissionSwitch
            disabled={!data?.[code]?.includes("001") && view}
            isChecked={data?.[code]?.includes("002")}
            handleSwitchChange={handleCreateSwitchChange}
          />
        </div>
      ) : (
        <div />
      )}
      {edit ? (
        <div>
          <h3>{t("translation:edit")}</h3>
          <PermissionSwitch
            disabled={!data?.[code]?.includes("001") && view}
            isChecked={data?.[code]?.includes("003")}
            handleSwitchChange={handleEditSwitchChange}
            isMiddle={calculateIsMiddle("edit")}
          />
        </div>
      ) : (
        <div />
      )}
      {del ? (
        <div>
          <h3>{t("translation:delete")}</h3>
          <PermissionSwitch
            disabled={!data?.[code]?.includes("001") && view}
            isChecked={data?.[code]?.includes("004")}
            handleSwitchChange={handleDeleteSwitchChange}
            isMiddle={calculateIsMiddle("delete")}
          />
        </div>
      ) : (
        <div />
      )}
    </>
  );
}
