import { Integration, Mapping } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
import { connectionService, mappingService } from "/app/src/services";
import { buildParams } from "/app/src/helpers";

/**
 * Hook to handle the confirmation mappings for a single Data Pull Integration
 */
export default function ConfirmationHook({
  integration,
}: {
  integration: Integration;
}) {
  const { data: mappings } = useQuery({
    queryKey: ["mappingsForConfirmation", integration.id],
    queryFn: () => {
      return mappingService.getAll(
        buildParams({
          integrationId: integration.id,
          orderby: "key",
          type: "[or]column;id",
        }),
      );
    },
    enabled: Boolean(integration.id),
    initialData: { mappings: [] },
    select: (data: { mappings: Mapping[] }) => {
      return data.mappings;
    },
  });
  const { data: connections } = useQuery({
    queryKey: ["connections", "[or]REST;GraphQL;MSSQL;IBM2"],
    queryFn: () => {
      return connectionService.getAll(
        buildParams({ type: "[or]REST;GraphQL;MSSQL;IBM2" }),
      );
    },
    initialData: { connections: [] },
    select: (data = { connections: [] }) => {
      return data.connections;
    },
  });

  return {
    mappings,
    connections,
  };
}
