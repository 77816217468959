import React, { useCallback, useState } from "react";
import NextSwitch from "/app/src/components/NextUi/Switch";

/**
 * Component to render a switch with the ability to be toggled to the middle
 * @param disabled: boolean to determine if the switch should be disabled
 * @param isChecked: boolean to determine if the switch should be checked
 * @param isMiddle: boolean to determine if the switch should be toggled to the middle
 * @param handleSwitchChange: function to handle the switch change
 * @returns
 */
export default function PermissionSwitch({
  disabled = false,
  isChecked,
  isMiddle = false,
  handleSwitchChange,
}: {
  disabled?: boolean;
  isChecked: boolean;
  isMiddle?: boolean;
  handleSwitchChange: (checked: boolean) => void;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onChange = useCallback(async () => {
    setIsSubmitting(true);
    await handleSwitchChange(!isChecked);
    setIsSubmitting(false);
  }, [handleSwitchChange, isChecked]);

  return (
    <NextSwitch
      color={isMiddle ? "warning" : "primary"}
      isSelected={isChecked}
      isDisabled={disabled || isSubmitting}
      onValueChange={onChange}
      isMiddle={isMiddle}
    />
  );
}
