import React, { useCallback } from "react";
import { Col, Row } from "antd";
import { Form, SubmitButton, Select } from "formik-antd";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { WorkflowAction, App, Integration } from "/app/src/models";
import { simpleSchemaBuilder } from "/app/src/helpers";
import NextButton from "/app/src/components/NextUi/Button";

export default function File({
  app,
  action,
  addWorkflowAction,
  integrations,
  removeWorkflowAction,
  updateAction,
  isNew,
  resetSelectedApp,
}: {
  app: App;
  action: WorkflowAction;
  addWorkflowAction: (action: WorkflowAction) => Promise<any>;
  integrations: Integration[];
  removeWorkflowAction: (action: WorkflowAction) => Promise<any> | undefined;
  updateAction: (action: WorkflowAction) => Promise<any>;
  isNew: boolean;
  resetSelectedApp: () => void;
}) {
  const { t } = useTranslation();

  const removeWorkflowHandler = useCallback(() => {
    removeWorkflowAction(action);
  }, [action, removeWorkflowAction]);

  const addUpdateWorkflowHandler = useCallback(
    (values, actions) => {
      if (isNew) {
        const newAction = { ...values, appId: app.id } as WorkflowAction;
        addWorkflowAction(newAction).then((response) => {
          if (!response?.errors) {
            actions.resetForm();
            resetSelectedApp();
          }
        });
      } else {
        if (action?.id) {
          updateAction({ id: action.id, ...values }).then((response) => {
            if (!response?.errors) {
              actions.resetForm();
            }
          });
        }
      }
    },
    [isNew, app, action, addWorkflowAction, updateAction, resetSelectedApp],
  );

  return (
    <Row justify="start" gutter={16}>
      <Col span={24}>
        <Formik
          enableReinitialize
          initialValues={{
            ...(isNew
              ? { integrationId: undefined }
              : { integrationId: action.integrationId }),
          }}
          validationSchema={simpleSchemaBuilder([
            { name: "integrationId", type: "number", required: true },
          ])}
          onSubmit={addUpdateWorkflowHandler}
        >
          {({ dirty }) => (
            <Form>
              <Row justify="start" gutter={16}>
                <Col span={3}>
                  <span className="blockText">{t("translation:run")}</span>
                </Col>
                <Col span={15}>
                  <Form.Item name="integrationId">
                    <Select
                      name="integrationId"
                      size="large"
                      placeholder={t("translation:select_integration")}
                    >
                      {integrations.map((c) => (
                        <Select.Option key={c.id} value={c.id}>
                          {c.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col flex="auto">
                  <SubmitButton
                    type="primary"
                    size="large"
                    block
                    disabled={!dirty}
                  >
                    {isNew
                      ? t("translation:add_action")
                      : t("translation:save")}
                  </SubmitButton>
                </Col>
                {!isNew && (
                  <Col span={3}>
                    <NextButton
                      size="md"
                      variant="bordered"
                      color="default"
                      fullWidth
                      onClick={removeWorkflowHandler}
                      className="hover:border-primary-default hover:text-primary-default bg-white"
                    >
                      {t("translation:remove")}
                    </NextButton>
                  </Col>
                )}
              </Row>
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
}
