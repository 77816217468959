import { IconBuilder } from "/app/src/components/icons/IconBuilder";
import { useCallback } from "react";

/**
 * Component to display a star icon that can be clicked to favourite/unfavourite an object
 * @param param0
 * favouriteFn: (id: number) => void;
 * unfavouriteFn: (id: number) => void;
 * isFavourite: boolean;
 * objectId: number;
 */
export default function FavouriteStar({
  favouriteFn,
  unfavouriteFn,
  isFavourite,
  objectId,
}: {
  favouriteFn: (id: number) => void;
  unfavouriteFn: (id: number) => void;
  isFavourite: boolean;
  objectId: number;
}) {
  const handleFavouriteClick = useCallback(() => {
    if (isFavourite) {
      unfavouriteFn(objectId);
    } else {
      favouriteFn(objectId);
    }
  }, [isFavourite, favouriteFn, unfavouriteFn, objectId]);

  if (isFavourite) {
    return (
      <IconBuilder
        icon="StarFilled"
        size={24}
        color="#FFD700"
        className="flex items-center justify-center"
        onClick={handleFavouriteClick}
      />
    );
  } else {
    return (
      <IconBuilder
        icon="StarOutlined"
        size={24}
        color="#FFD700"
        className="flex items-center justify-center"
        onClick={handleFavouriteClick}
      />
    );
  }
}
