import { useMemo } from "react";
import { useFormikContext } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, Input } from "formik-antd";
import { useTranslation } from "react-i18next";
import { IconToolTip } from "/app/src/components/icons/IconBuilder";
import DynamicButtons from "/app/src/components/generic/components/apps/data/settings/connectionSettings/dynamicButtons";

interface FormValues {
  value: string | undefined;
}

/**
 *
 * @returns RequestForm
 */
export default function RequestForm() {
  const { isSubmitting, dirty } = useFormikContext<FormValues>();
  const { t } = useTranslation();

  const requestBodyContent = useMemo(
    () => (
      <>
        {t("translation:request_body")}
        <IconToolTip
          content={t("translation:request_body_tip")}
          className="ml-1"
        />
      </>
    ),
    [t],
  );

  return (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col span={20}>
          <Form.Item
            name="value"
            label={requestBodyContent}
            hasFeedback={false}
          >
            <Input.TextArea name="value" size="large" />
            <DynamicButtons />
          </Form.Item>
        </Col>
        <Col span={4}>
          <SubmitButton
            type="primary"
            size="large"
            block
            disabled={!dirty || isSubmitting}
            style={{ marginTop: "30px" }}
          >
            {t("translation:save")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );
}
