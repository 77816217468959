import React, { useCallback } from "react";
import NextButton from "/app/src/components/NextUi/Button";
import { useTranslation } from "react-i18next";
import { App } from "/app/src/models";
export default function Controls({
  app,
  toggleNew,
  activeNew,
}: {
  app: App;
  toggleNew: () => void;
  activeNew: boolean;
}) {
  const { t } = useTranslation();

  const toggleNewDisplay = useCallback(() => {
    toggleNew();
  }, [toggleNew]);

  return (
    <div className="controls">
      <div className="icon"> </div>
      <div className="title">{app && <h1>{app.name}</h1>}</div>

      <div className="controlButtons">
        {activeNew ? (
          <NextButton
            color="primary"
            variant="bordered"
            onClick={toggleNewDisplay}
            size="sm"
          >
            {t("translation:cancel")}
          </NextButton>
        ) : (
          <NextButton color="primary" size="sm" onClick={toggleNewDisplay}>
            {t("translation:new_display")}
          </NextButton>
        )}
      </div>
    </div>
  );
}
