import { useFormikContext } from "formik";
import { useCallback } from "react";
import { Input } from "formik-antd";

/**
 *
 * @returns NewPassword form item
 */
export default function NewPassword() {
  const { setFieldTouched } = useFormikContext();

  const passwordOnChange = useCallback(() => {
    setFieldTouched("currentPassword", true, true);
  }, [setFieldTouched]);

  return (
    <Input.Password name="password" onChange={passwordOnChange} size="large" />
  );
}
