import React, { useState, useMemo, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Row, Col } from "antd";
import { zoneService, locationService } from "/app/src/services";
import { buildParams } from "/app/src/helpers/params";
import { useTranslation } from "react-i18next";
import { Zone as ZoneType, Location as LocationType } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import Box from "/app/src/components/generic/components/box";
import { useHandleNavigationError } from "/app/src/helpers/api";

export default function SingleZone() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleNavigationError = useHandleNavigationError();
  const { zoneId } = useParams();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useSortUpgrade([]);

  const goToLocation = useCallback(
    (locationId: number) => {
      navigate(`/explorer/locations/${locationId}`);
    },
    [navigate],
  );

  const columnHelper = createColumnHelper<LocationType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: t("translation:name"),
      }),
    ],
    [columnHelper, t],
  );

  // Get the Single Zone from URL
  const { data: zone } = useQuery({
    queryKey: ["zone", zoneId],
    queryFn: () => {
      return zoneService.getSingle(zoneId).catch(() => {
        handleNavigationError();
      });
    },
    initialData: { zone: {} },
    retry: false,
    select: (data: { zone: ZoneType }) => {
      return data.zone;
    },
  });

  //Get the count of locations in the zone
  const { data: locationCount } = useQuery({
    queryKey: ["locations", zoneId, "countOnly"],
    queryFn: () => {
      return locationService.getCount(buildParams({ zoneId }));
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  //Get the locations in the zone
  const { data: locations, isFetching: isFetchingLocations } = useQuery({
    queryKey: ["locations", zoneId, page, pageSize],
    queryFn: () => {
      return locationService.getAll(
        buildParams({
          limit: pageSize,
          page,
          zoneId,
          orderby: "name",
        }),
      );
    },
    initialData: { locations: [] },
    select: (data: { locations: LocationType[] }) => {
      return data.locations;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{zone?.name}</title>
      </Helmet>
      <Box>
        <Row>
          <Col span={6} className="bigItem">
            <h4>
              {t("translation:zone")} {t("translation:name")}
            </h4>
            <span>{zone?.name}</span>
          </Col>
        </Row>
        <Row>
          <Col span={6} className="bigItem">
            <h4>{t("translation:type")}</h4>
            <p>{zone?.typeDescription}</p>
          </Col>
          <Col span={6} className="bigItem">
            <h4>
              {t("translation:pick")} {t("translation:type")}
            </h4>
            <p>{zone?.pickType}</p>
          </Col>
          <Col span={6} className="bigItem">
            <h4>
              {t("translation:put")} {t("translation:type")}
            </h4>
            <p>{zone?.putType}</p>
          </Col>
          <Col span={6} className="bigItem">
            <h4>{t("translation:warehouse")}</h4>
            <p>{zone?.warehouseName}</p>
          </Col>
        </Row>
      </Box>
      <Row>
        <h2>
          {t("translation:locations")} {t("translation:using")} {zone?.name}:
        </h2>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            loading={isFetchingLocations}
            rowClicked={goToLocation}
            rows={locations}
            tableColumns={columns}
            length={locationCount}
            sort={sort}
            setSort={setSort}
            paginationEnabled={{
              currentPage: page,
              pageSize,
              setPage,
              setPageSize,
            }}
            emptyText={t("translation:no_locations_use_that_zone")}
          />
        </Col>
      </Row>
    </div>
  );
}
