import { useFormikContext } from "formik";
import ConnectionType from "./connectionType";

interface FormValues {
  name: string | undefined;
  type: string | undefined;
  url: string | undefined;
  authentication: string | undefined;
  username: string | undefined;
  password: string | undefined;
  token: string | undefined;
}
/**
 * Formik Wrapper for New ConnectionType. Uses the formik context to get the values
 * for determining which fields to display
 */
export default function NewFormikTypeWrapper() {
  const { values } = useFormikContext<FormValues>();

  return (
    <ConnectionType
      includeAuth={["Password", "Basic", "OAuth2", "TBA"].includes(
        values.authentication,
      )}
      includeDatabase={["MSSQL", "IBM2"].includes(values.type)}
      hidePassword
      hideToken
      includeLogin={["TBA", "OAuth2"].includes(values.authentication)}
      includeTokenDuration={["TBA", "OAuth2"].includes(values.authentication)}
    />
  );
}
