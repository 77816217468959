import { Helmet } from "react-helmet";
import { Routes, Route } from "react-router-dom";
import Controls from "./controls";
import Columns from "./columns";
import Filters from "./filters";
import Snapshots from "./snapshots";
import Export from "./export";
import Data from "./data";
import {
  reportService,
  reportFilterService,
  snapshotService,
  reportColumnService,
} from "/app/src/services";
import { useQuery } from "@tanstack/react-query";
import { buildParams } from "/app/src/helpers/params";
import { Report as ReportType } from "/app/src/models";
import { useHandleNavigationError } from "/app/src/helpers/api";

/**
 * Component for displaying report data - columns, filters, snapshots, export, and data
 * @param param0 props contain report id
 */
export default function Report({ reportId }: { reportId: number }) {
  const handleNavigationError = useHandleNavigationError();
  const reportQuery = useQuery({
    queryKey: ["report", reportId],
    queryFn: () => {
      return reportService.getSingle(reportId).catch(() => {
        handleNavigationError();
      });
    },
    initialData: {
      report: { columns: [], snapshots: [], favouritedUsers: [] },
    },
    enabled: Boolean(reportId),
    retry: false,
    select: (data: { report: ReportType }) => data.report,
  });
  const reportFiltersQuery = useQuery({
    queryKey: ["reportFilters", reportId],
    queryFn: () => reportFilterService.getAllFromReport(reportId),
    initialData: { report_filters: [] },
    enabled: Boolean(reportId),
    select: (data) => data.report_filters,
  });
  const snapshotsQuery = useQuery({
    queryKey: ["snapshots", reportId],
    queryFn: () => snapshotService.getAll(buildParams({ reportId })),
    initialData: { snapshots: [] },
    enabled: Boolean(reportId),
    select: (data) => data.snapshots,
  });

  const columnsQuery = useQuery({
    queryKey: ["reportColumns", reportId],
    queryFn: () => reportColumnService.getAllFromReport(reportId),
    initialData: { report_columns: [] },
    enabled: Boolean(reportId),
    select: (data) => data.report_columns,
  });

  return (
    <div className="report">
      <Helmet>
        <title>{reportQuery.data?.name}</title>
      </Helmet>
      <Controls
        report={reportQuery.data}
        filtersCount={reportFiltersQuery.data.length}
        snapshotsCount={snapshotsQuery.data.length}
        columnsCount={columnsQuery.data.length}
      />
      <Routes>
        <Route
          path="columns"
          element={
            <Columns report={reportQuery.data} columns={columnsQuery.data} />
          }
        />
        <Route
          path="snapshots"
          element={
            <Snapshots
              report={reportQuery.data}
              snapshots={snapshotsQuery.data}
              columns={columnsQuery.data}
            />
          }
        />
        <Route path="export" element={<Export report={reportQuery.data} />} />
        <Route
          path="filters"
          element={
            <Filters
              report={reportQuery.data}
              filters={reportFiltersQuery.data}
              columns={columnsQuery.data}
            />
          }
        />
        <Route
          path="/"
          element={
            <Data report={reportQuery.data} columns={columnsQuery.data} />
          }
        />
      </Routes>
    </div>
  );
}
