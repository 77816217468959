import React from "react";
import { Routes, Route } from "react-router-dom";

import Integration from "./integration";
import NewIntegration from "./newIntegration";
import IntegrationsList from "/app/src/components/generic/components/integration/integrationsView";
import Controls from "/app/src/components/generic/components/integration/_controls";
import { useToggle } from "/app/src/hooks";
import { App } from "/app/src/models";

/**
 * Component to set the Data Pull routes
 * @param param0 data pull app
 */
export default function DataPull({ app }: { app: App }) {
  const [toggled, setToggled] = useToggle();
  const themes = [
    { name: "Material", value: "Material" },
    { name: "Order", value: "Order" },
  ];
  return (
    <Routes>
      <Route
        path={"/"}
        element={
          <Controls
            app={app}
            name="data_pull_app"
            toggled={toggled}
            setToggled={setToggled}
          >
            <NewIntegration app={app} setToggled={setToggled} />
          </Controls>
        }
      >
        <Route
          path="integrations/:integrationId/*"
          element={<Integration app={app} />}
        />
        <Route
          path="/"
          element={
            <IntegrationsList
              app={app}
              themes={themes}
              includeStatus
              includeType={false}
              customAccessors={[
                {
                  position: 2,
                  key: "type",
                  id: "type",
                  header: () => "Type",
                  cell: ({ getValue }) => {
                    return getValue() === "delete" ? "Delete" : "Create";
                  },
                },
              ]}
            />
          }
        />
      </Route>
    </Routes>
  );
}
