import React from "react";
import {
  ItemPathLogo,
  ItemPathText,
  ItemPathLogoWithText,
} from "/app/src/components/icons/icons";

interface IconBuilderProps {
  icon: "ItemPathLogo" | "ItemPathText" | "ItemPathLogoWithText";
  theme: "default" | "light" | "dark";
}

// This function will return the correct logo based on the icon name and theme
const getLogo = (iconName: string, theme: string) => {
  switch (iconName) {
    case "ItemPathLogo":
      switch (theme) {
        case "light":
          return <ItemPathLogo color="#FFFFFF" />;
        case "dark":
          return <ItemPathLogo color="#000000" />;
        default:
          return <ItemPathLogo color="#2491FF" />;
      }
    case "ItemPathText":
      switch (theme) {
        case "light":
          return <ItemPathText color="#FFFFFF" />;
        case "dark":
          return <ItemPathText color="#000000" />;
        default:
          return <ItemPathText color="#000000" />;
      }
    case "ItemPathLogoWithText":
      switch (theme) {
        case "light":
          return (
            <ItemPathLogoWithText logoColor="#FFFFFF" textColor="#FFFFFF" />
          );
        case "dark":
          return (
            <ItemPathLogoWithText logoColor="#000000" textColor="#000000" />
          );
        default:
          return (
            <ItemPathLogoWithText logoColor="#2491FF" textColor="#000000" />
          );
      }
    default:
      return null;
  }
};

// This component will return the correct logo based on the icon name and theme
const LogoBuilder: React.FC<IconBuilderProps> = ({ icon, theme }) => {
  return getLogo(icon, theme);
};

export { LogoBuilder };
export type { IconBuilderProps };
