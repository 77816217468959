import { useTranslation } from "react-i18next";
import { iField } from "/app/src/models";
import { useCallback } from "react";
import { Form, Select, SubmitButton } from "formik-antd";
import { Formik, FormikProps } from "formik";
import { Col, Row } from "antd";

interface FieldFormValues {
  name: string;
}

/**
 * NewField component allows users to create a new field by selecting from a list of available fields.
 *
 * @param {Object} props - The properties object.
 * @param {Array<{ value: string; label: string }>} [props.fields] - Optional array of field objects with value and label.
 * @param {Function} props.createField - Function to create a new field, returns a Promise.
 * @param {"line" | "order"} props.level - The level at which the field is being created, either "line" or "order".
 *
 * @returns {JSX.Element} The rendered NewField component.
 */
export default function NewField({
  fields,
  createField,
  level,
}: {
  fields?: { value: string; label: string }[];
  createField: (data: iField) => Promise<void>;
  level: "line" | "order";
}) {
  const { t } = useTranslation();

  const onSubmit = useCallback(
    (values: FieldFormValues, actions) => {
      return createField({
        name: values.name,
        type: "notMapped",
        level,
      }).then(() => {
        actions.resetForm();
      });
    },
    [createField, level],
  );

  const fieldForm: (props: FormikProps<FieldFormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isSubmitting }) => (
        <Form layout="vertical">
          <Row className="pt-6" justify="end">
            <Col span={6}>
              <Form.Item className="w-full" name="name">
                <Select
                  name="name"
                  placeholder={t("translation:add_field")}
                  size="large"
                >
                  {fields.map((field) => (
                    <Select.Option key={field.value} value={field.value}>
                      {field.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4} offset={14}>
              <Row justify="end">
                <SubmitButton
                  disabled={!dirty || isSubmitting}
                  loading={isSubmitting}
                >
                  {t("translation:add")}
                </SubmitButton>
              </Row>
            </Col>
          </Row>
        </Form>
      ),
      [fields, t],
    );

  return (
    <Formik
      component={fieldForm}
      enableReinitialize
      initialValues={{ name: undefined }}
      onSubmit={onSubmit}
    />
  );
}
