import { Col } from "antd";
import { Form, Input, DatePicker, TimePicker, Select } from "formik-antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export function During({
  setFilterDateType,
}: {
  setFilterDateType: (value: string) => void;
}) {
  const { t } = useTranslation();

  const filterDateTypeSetter = useCallback(
    (value) => {
      setFilterDateType(value);
    },
    [setFilterDateType],
  );

  return (
    <Col span={11}>
      <Form.Item name="value">
        <Select name="value" size="large" onChange={filterDateTypeSetter}>
          <Select.Option value="today">{t("translation:today")}</Select.Option>
          <Select.Option value="yesterday">
            {t("translation:yesterday")}
          </Select.Option>
          <Select.Option value="thisWeek">
            {t("translation:this_week")}
          </Select.Option>
          <Select.Option value="lastWeek">
            {t("translation:last_week")}
          </Select.Option>
          <Select.Option value="thisMonth">
            {t("translation:this_month")}
          </Select.Option>
          <Select.Option value="lastMonth">
            {t("translation:last_month")}
          </Select.Option>
          <Select.Option value="thisYear">
            {t("translation:this_year")}
          </Select.Option>
          <Select.Option value="lastYear">
            {t("translation:last_year")}
          </Select.Option>
        </Select>
      </Form.Item>
    </Col>
  );
}

export function BeforeAfter({
  setFilterDateType,
  filterDateType,
}: {
  setFilterDateType: (value: string) => void;
  filterDateType: string;
}) {
  const { t } = useTranslation();

  const filterDateTypeSetter = useCallback(
    (value) => {
      setFilterDateType(value);
    },
    [setFilterDateType],
  );
  return (
    <>
      <Col span={5}>
        <Form.Item name="dateRange">
          <Select name="dateRange" size="large" onChange={filterDateTypeSetter}>
            <Select.Option value="specificDate">
              {t("translation:specific_date")}:
            </Select.Option>
            <Select.Option value="specificTime">
              {t("translation:specific_time")}:
            </Select.Option>
            <Select.Option value="yearsAgo">
              {t("translation:years")}
              {t("translation:_ago")}:
            </Select.Option>
            <Select.Option value="monthsAgo">
              {t("translation:months")}
              {t("translation:_ago")}:
            </Select.Option>
            <Select.Option value="weeksAgo">
              {t("translation:weeks")}
              {t("translation:_ago")}:
            </Select.Option>
            <Select.Option value="daysAgo">
              {t("translation:days")}
              {t("translation:_ago")}:
            </Select.Option>
            <Select.Option value="hoursAgo">
              {t("translation:hours")}
              {t("translation:_ago")}:
            </Select.Option>
            <Select.Option value="minutesAgo">
              {t("translation:minutes")}
              {t("translation:_ago")}:
            </Select.Option>
          </Select>
        </Form.Item>
      </Col>
      <Col span={6}>
        {filterDateType === "specificDate" && (
          <Form.Item name="value">
            <DatePicker size="large" name="value" />
          </Form.Item>
        )}
        {filterDateType === "specificTime" && (
          <Form.Item name="value">
            <TimePicker size="large" name="value" format="HH:mm" />
          </Form.Item>
        )}
        {filterDateType !== "specificDate" &&
          filterDateType !== "specificTime" && (
            <Form.Item name="value">
              <Input
                name="value"
                placeholder={t("translation:enter_number")}
                size="large"
              />
            </Form.Item>
          )}
      </Col>
    </>
  );
}
