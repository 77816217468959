import React, { useCallback } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { Form, Select } from "formik-antd";

interface FormValues {
  mapppingType: string;
}

/**
 *
 * @returns MappingType component
 */
export default function MappingType() {
  const { setFieldValue } = useFormikContext<FormValues>();
  const { t } = useTranslation();

  const onChangeMappingType = useCallback(
    (e) => {
      setFieldValue("mappingType", e);
    },
    [setFieldValue],
  );

  return (
    <Form.Item className="w-full !mb-0 " name="mappingType" hasFeedback={false}>
      <Select
        name="mappingType"
        className="w-full"
        onChange={onChangeMappingType}
        size="large"
      >
        <Select.Option value="notMapped" key={"Not Mapped"}>
          {t("translation:not_mapped")}
        </Select.Option>
        <Select.Option value="text" key={"Static Text"}>
          {t("translation:static_text")}
        </Select.Option>
        <Select.Option value="column" key={"Report Column"}>
          {t("translation:report_column")}
        </Select.Option>
        <Select.Option value="advanced" key={"Advanced"}>
          {t("translation:advanced")}
        </Select.Option>
      </Select>
    </Form.Item>
  );
}
