import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Button, Col } from "antd";
import { processService } from "/app/src/services";
import SingleOrder from "./singleOrder";
import { useTranslation } from "react-i18next";
import { App } from "/app/src/models";
import Spin from "/app/src/components/NextUi/Spin";
import ProgressBar from "/app/src/components/NextUi/ProgressBar";
import NextButton from "/app/src/components/NextUi/Button";

export default function ProcessingOrders({ app }: { app: App }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { integrationId, processId } = useParams();
  const [step, setStep] = useState<string>("Waiting");
  const [orders, setOrders] = useState<string[]>([]);
  const [currentOrder, setCurrentOrder] = useState(0);
  const [progress, setProgress] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const settingCurrentOrder = useCallback(() => {
    setCurrentOrder(currentOrder + 1);
  }, [currentOrder]);

  const returnToOrderList = useCallback(() => {
    // Remove the Process if we've cancelled it
    setStep("Cancelled");
    if (processId) {
      processService
        .deleteSingle(processId)
        .then(() => {
          navigate(`/apps/${app.id}/integrations/${integrationId}`);
        })
        .catch(() => {
          navigate(`/apps/${app.id}/integrations/${integrationId}`);
        });
    }
  }, [app, integrationId, navigate, processId]);

  useEffect(() => {
    let value = (currentOrder + 1) / orders.length;
    value = value * 100;
    setProgress(value);
  }, [currentOrder, orders]);

  // Poll for PPG process changes. It needs to wait until PowerPick is ready
  useEffect(() => {
    if (
      step !== "ActiveProcessesChanged" &&
      step !== "Finished" &&
      step !== "Cancelled"
    ) {
      const poll = setInterval(() => {
        if (processId) {
          processService.getSingle(processId).then((response) => {
            setStep(response.process.step as string);
            if (
              response.process.step === "ActiveProcessesChanged" ||
              response.process.step === "Finished"
            ) {
              setOrders(response.process.orders);
              clearInterval(poll);
            }
          });
        }
      }, 2000);
      return () => {
        clearInterval(poll);
      };
    }
    return null;
  }, [step, app, processId]);

  switch (step) {
    default:
      return null;
    case "Finished":
      return (
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 20, offset: 2 }}
            lg={{ span: 10, offset: 7 }}
          >
            <div className="box mt-4">
              <h3 style={{ textAlign: "center", margin: "20px 0 30px" }}>
                {t("translation:processing_no_lines_available")}
              </h3>
              <Button
                block
                size="large"
                type="primary"
                danger
                onClick={returnToOrderList}
              >
                {t("translation:cancel")}
              </Button>
            </div>
          </Col>
        </Row>
      );
    case "NewProcessInitialized":
      return (
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 20, offset: 2 }}
            lg={{ span: 10, offset: 7 }}
          >
            <div className="box mt-4">
              <h3 style={{ textAlign: "center", margin: "20px 0 30px" }}>
                {t("translation:processing_waiting_for_ppg")}...
              </h3>
              <div className="flex justify-center p-10 h-[400px]">
                <Spin size="lg" />
              </div>
              <NextButton onClick={returnToOrderList} fullWidth color="red">
                {t("translation:cancel")}
              </NextButton>
            </div>
          </Col>
        </Row>
      );
    case "ActiveProcessesChanged":
      return (
        <div>
          {orders.map((order, index) => (
            <SingleOrder
              orderId={order}
              currentOrder={currentOrder}
              index={index}
              key={index}
              cancelOrder={returnToOrderList}
              setButtonDisabled={setButtonDisabled}
            />
          ))}
          <Row justify="center">
            <ProgressBar
              value={progress}
              color={progress === 100 ? "success" : "primary"}
            />
            {t("translation:order")} {currentOrder + 1} / {orders.length}
            {currentOrder === orders.length - 1 ? (
              <NextButton
                isDisabled={buttonDisabled}
                color="primary"
                size="sm"
                fullWidth
                onClick={returnToOrderList}
              >
                {t("translation:finish_processing")}
              </NextButton>
            ) : (
              <NextButton
                isDisabled={buttonDisabled}
                color="primary"
                size="sm"
                fullWidth
                onClick={settingCurrentOrder}
              >
                {t("translation:go_to_next_order")}
              </NextButton>
            )}
          </Row>
        </div>
      );
  }
}
