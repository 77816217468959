import { useCallback, useState } from "react";
import { ConfirmationMappingTypes } from "./mappingTypes";
import { ConfirmationType, Mapping } from "/app/src/models";
import { Col, Row, Select } from "antd";
import { useTranslation } from "react-i18next";
import { confirmationMappings } from "/app/src/constants/confirmationMappings";

interface FormValues {
  key: string;
  value: string;
  type: `confirmation-${Lowercase<ConfirmationType>}`;
}

/**
 * Component for selecting and creating a new confirmation mapping type
 */
export default function NewConfirmationMapping({
  addMapping,
  integrationId,
}: {
  addMapping: (mapping: Omit<Mapping, "parentMapping" | "children">) => void;
  integrationId: number;
}) {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState<ConfirmationType>(undefined);
  /**
   * Calls the addMapping function with the values from the form
   * Resets the selected type after mapping has been added
   */
  const handleSubmit = useCallback(
    async (values: FormValues) => {
      await addMapping({
        key: values.key,
        value: values.value,
        type: values.type,
      });
      setSelectedType(undefined);
    },
    [addMapping],
  );
  /**
   * Update the selected type state when the select changes
   */
  const handleTypeChange = useCallback(
    (value: ConfirmationType) => {
      setSelectedType(value);
    },
    [setSelectedType],
  );
  return (
    <Row justify="start" gutter={16}>
      <Col span={6}>
        <Select
          value={selectedType}
          onChange={handleTypeChange}
          size="large"
          style={{ width: "100%", marginBottom: "24px" }}
          placeholder={t("translation:add_new_confirmation_mapping")}
        >
          {confirmationMappings.map((mapping) => (
            <Select.Option value={mapping.value} key={mapping.value}>
              {mapping.name}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col span={18}>
        {selectedType && (
          <ConfirmationMappingTypes
            mapping={{
              integrationId,
              id: undefined,
              key: "",
              value: "",
              confirmationType: selectedType,
              type: `confirmation-${selectedType.toLowerCase()}` as `confirmation-${Lowercase<ConfirmationType>}`,
            }}
            onSubmit={handleSubmit}
          />
        )}
      </Col>
    </Row>
  );
}
