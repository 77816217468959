import { iFieldService } from "/app/src/services";
import { iField, Integration } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { useAuthState } from "/app/src/contexts/authentication";

/**
 * DataHook for the fields of the integration
 */
export default function DataHook(integration: Integration) {
  const queryClient = useQueryClient();
  const { user } = useAuthState();

  const { data: lineFields } = useQuery({
    queryKey: ["lineFields", integration.id],
    queryFn: () => {
      return iFieldService.getAll(
        buildParams({ integrationId: integration.id, level: "line" }),
      );
    },
    initialData: { fields: [] },
    select: (data: { fields: iField[] }) => {
      return data.fields;
    },
  });
  const { data: orderFields } = useQuery({
    queryKey: ["orderFields", integration.id],
    queryFn: () => {
      return iFieldService.getAll(
        buildParams({ integrationId: integration.id, level: "order" }),
      );
    },
    initialData: { fields: [] },
    select: (data: { fields: iField[] }) => {
      return data.fields;
    },
  });

  const { mutateAsync: createField } = useMutation({
    mutationFn: (values: iField) => {
      return iFieldService
        .createSingle({
          ...values,
          integrationId: integration.id,
          userId: user.id,
        })
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      // Calculate which query to update
      let level = "orderFields";
      if (response.field.level === "line") {
        level = "lineFields";
      }
      queryClient.setQueryData(
        [level, integration.id],
        (old: { fields: iField[] }) => {
          return {
            fields: [...old.fields, response.field],
          };
        },
      );
    },
  });

  const { mutateAsync: deleteField } = useMutation({
    mutationFn: (iField: iField) => {
      return iFieldService
        .deleteSingle(iField.id)
        .then(handlePromiseError)
        .then(() => iField);
    },
    onSuccess: (iField: iField) => {
      // Calculate which query to update
      let level = "orderFields";
      if (iField.level === "line") {
        level = "lineFields";
      }
      queryClient.setQueryData(
        [level, integration.id],
        (old: { fields: iField[] }) => {
          return {
            fields: old.fields.filter((field) => field.id !== iField.id),
          };
        },
      );
    },
  });

  const { mutateAsync: updateField } = useMutation({
    mutationFn: (values: iField) => {
      const { id: iFieldId, ...rest } = values;
      return iFieldService
        .updateSingle(iFieldId, rest)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      // Calculate which query to update
      let level = "orderFields";
      if (response.field.level === "line") {
        level = "lineFields";
      }
      queryClient.setQueryData(
        [level, integration.id],
        (old: { fields: iField[] }) => {
          return {
            fields: old.fields.map((field) => {
              if (field.id === response.field.id) {
                return response.field;
              }
              return field;
            }),
          };
        },
      );
    },
  });

  return {
    lineFields,
    orderFields,
    createField,
    updateField,
    deleteField,
  };
}
