import { AuthProvider } from "./contexts/authentication";

/**
 * Authenticated wrappers for the application
 * Includes  authentication context
 */
export default function AuthWrappers({
  children,
}: {
  children: JSX.Element[];
}) {
  return <AuthProvider>{children}</AuthProvider>;
}
