import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import NextButton from "/app/src/components/NextUi/Button";

export default function Controls() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateToNewUser = useCallback(() => {
    navigate("/users/new");
  }, [navigate]);

  return (
    <div className="controls">
      <Row>
        <Col span={8}>
          <h1>{t("translation:all_users")}</h1>
        </Col>

        <Col span={16}>
          <div className="controlButtons">
            <NextButton color="primary" size="sm" onClick={navigateToNewUser}>
              {t("translation:new_user")}
            </NextButton>
          </div>
        </Col>
      </Row>
    </div>
  );
}
