import React, { useCallback, useState } from "react";
import { Row, Col, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { statusService } from "/app/src/services";
import NextSelect from "/app/src/components/NextUi/Select";
import NextButton from "/app/src/components/NextUi/Button";

/**
 * Search and filtering controls for the orders table
 * found in the Order Builder integration
 */
export default function InnerControls({
  handleSearch,
  setStatusIds,
  updateStatusIds,
  deleteOrders,
}: {
  handleSearch: (searchString: string) => void;
  setStatusIds: (statusIds: string[]) => void;
  updateStatusIds: (statusId: number) => void;
  deleteOrders: () => void;
}) {
  const { t } = useTranslation();
  const [selectedStatus, setSelectedStatus] = useState<string>(undefined);
  const { data: statuses, isLoading } = useQuery({
    queryKey: ["statuses"],
    queryFn: () => statusService.getAll(),
    select: (data) => data.statuses,
    initialData: { statuses: [] },
  });
  const handleSelectStatus = useCallback(
    (e) => {
      setSelectedStatus(e.target.value);
    },
    [setSelectedStatus],
  );

  const handleStatusChange = useCallback(
    (value) => {
      //CSV should be converted to a list of strings
      const statuses = value.target.value.split(",");
      setStatusIds(statuses);
    },
    [setStatusIds],
  );

  const handleUpdateClick = useCallback(() => {
    updateStatusIds(parseInt(selectedStatus));
  }, [selectedStatus, updateStatusIds]);

  const handleDeleteClick = useCallback(() => {
    deleteOrders();
  }, [deleteOrders]);
  return (
    <Row gutter={[10, 10]} style={{ paddingBottom: "10px" }}>
      <Col span={6}>
        <Input.Search
          onSearch={handleSearch}
          size="large"
          placeholder={t("translation:search")}
        />
      </Col>
      <Col span={4}>
        <NextSelect
          isDisabled={isLoading}
          selectionMode="multiple"
          placeholder={t("translation:filter_by_status")}
          onChange={handleStatusChange}
          items={statuses.map((status) => {
            return {
              key: status.id,
              value: status.id,
              label: status.name,
            };
          })}
          ariaLabel={t("translation:filter_by_status")}
        />
      </Col>
      <Col span={6} offset={1}>
        <NextSelect
          isDisabled={isLoading}
          placeholder={t("translation:update_selected_status")}
          onChange={handleSelectStatus}
          items={statuses
            .filter(
              (status) =>
                status.name !== "Sent to Power Pick" && status.name !== "Error",
            )
            .map((status) => {
              return {
                key: status.id,
                value: status.id,
                label: status.name,
              };
            })}
          ariaLabel={t("translation:update_selected_status")}
        />
      </Col>
      <Col span={3}>
        <NextButton
          onClick={handleUpdateClick}
          isDisabled={selectedStatus === undefined}
          size="md"
          fullWidth
        >
          {t("translation:update")}
        </NextButton>
      </Col>
      <Col span={3} offset={1}>
        <NextButton onClick={handleDeleteClick} size="md" fullWidth color="red">
          {t("translation:delete")}
        </NextButton>
      </Col>
    </Row>
  );
}
