import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import NextButton from "/app/src/components/NextUi/Button";
import NextModal from "/app/src/components/NextUi/Modal";
import { useDisclosure } from "@nextui-org/react";
import ModalBuilder from "/app/src/components/NextUi/helpers/ModalBuilder";

export default function ClearLogs({ clearLogs }: { clearLogs: () => void }) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const handleClearLogs = useCallback(() => {
    clearLogs();
    onOpenChange(); // Close the modal after clearing logs
  }, [clearLogs, onOpenChange]);
  return (
    <>
      <NextButton
        size="sm"
        variant="bordered"
        color="primary"
        onClick={onOpen}
        className="border-[#a12121] text-[#a12121] bg-white"
      >
        {t("translation:clear")} {t("translation:logs")}
      </NextButton>
      <NextModal
        disableAnimation={false}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="top"
      >
        {ModalBuilder({
          warning: true,
          modalHeader: t("translation:confirm_delete_logs"),
          modalFooter: (
            <>
              <NextButton
                size="sm"
                color="default"
                variant="bordered"
                onClick={onOpenChange}
                className="hover:border-primary-default hover:text-primary-default"
              >
                {t("translation:cancel")}
              </NextButton>
              <NextButton size="sm" color="red" onClick={handleClearLogs}>
                {t("translation:delete")}
              </NextButton>
            </>
          ),
        })}
      </NextModal>
    </>
  );
}
