import { iOrder as iOrderType } from "/app/src/models";
import { useNumberId } from "/app/src/hooks/useNumberId";
import { iOrderService } from "/app/src/services";
import { useQuery } from "@tanstack/react-query";

import { useTranslation } from "react-i18next";
import Revisions from "./revisions";
import Lines from "./lines";
import OrderDetails from "./orderDetails";
import Spin from "/app/src/components/NextUi/Spin";
import { useState, useEffect } from "react";

/**
 * Displays the order details of an order created through the Order Builder App
 */
export default function OrderBuildOrder() {
  const { orderId } = useNumberId();
  const { t } = useTranslation();
  const [refetchInterval, setRefetchInterval] = useState<number | false>(false);
  const { data: iOrder } = useQuery({
    queryKey: ["order", orderId],
    queryFn: () => {
      return iOrderService.getSingle(orderId);
    },
    initialData: { order: { loadedData: {}, lines: [] } },
    select: (data: { order: iOrderType }) => {
      return data.order;
    },
    refetchInterval,
  });
  useEffect(() => {
    if (iOrder?.orderStatus === "Ready to be Sent to Power Pick") {
      setRefetchInterval(2000);
    } else {
      setRefetchInterval(false);
    }
  }, [iOrder]);
  return (
    <>
      <OrderDetails iOrder={iOrder} />
      <Lines order={iOrder} />
      <h2>{t("translation:history")}</h2>
      <Revisions order={iOrder} />

      {iOrder?.orderStatus === "Ready to be Sent to Power Pick" && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <Spin />
        </div>
      )}
    </>
  );
}
