import StaticText from "./staticText";
import ReportColumn from "./reportColumn";
import { Switch, Form } from "formik-antd";
import { useTranslation } from "react-i18next";
import { Integration } from "/app/src/models";

/**
 * Renders a dynamic field based on the provided status and integration.
 *
 * @param type - The type of the field.
 * @param integration - The integration object.
 * @returns The rendered dynamic field component.
 */
export default function DynamicField({
  type,
  integration,
}: {
  type: string;
  integration: Integration;
}) {
  const { t } = useTranslation();
  if (type === "notMapped") {
    return null;
  }
  return (
    <>
      {type === "text" ? (
        <StaticText />
      ) : type === "column" ? (
        <ReportColumn integration={integration} />
      ) : (
        type === "advanced" && (
          <ReportColumn integration={integration} dynamic allowCurrentTime />
        )
      )}
      <div className="flex">
        <Form.Item name="required">
          {t("translation:required")} <Switch name="required" />
        </Form.Item>
        <div className="ml-3">
          <Form.Item name="viewOnly">
            {t("translation:view_only")} <Switch name="viewOnly" />
          </Form.Item>
        </div>
      </div>
    </>
  );
}
