import { Row, Col } from "antd";
import { Form, SubmitButton } from "formik-antd";
import { useTranslation } from "react-i18next";
import { Mapping } from "/app/src/models";
import { Formik } from "formik";
import { buildParams, simpleSchemaBuilder } from "/app/src/helpers";
import { useCallback } from "react";
import { mappingService } from "/app/src/services";
import { useQuery } from "@tanstack/react-query";
import TextFieldType from "./textFieldType";
import MappingTypes from "./mappingsType";
import GroupingType from "./groupingType";
import StatusFieldType from "./statusFieldType";

interface FormValues {
  key: string;
  value: string;
}

/**
 * Component to display the appropriate form for the confirmation mapping type
 */
export default function ConfirmationMappingTypes({
  mapping,
  onSubmit,
}: {
  mapping: Mapping;
  onSubmit: (values: FormValues) => void;
}) {
  const { data: mappings } = useQuery({
    queryKey: ["mappings", mapping.integrationId],
    queryFn: () => {
      return mappingService.getAll(
        buildParams({
          integrationId: mapping.integrationId,
          mappingType: "Mapping",
          orderby: "key",
        }),
      );
    },
    enabled: Boolean(mapping.integrationId),
    initialData: { mappings: [] },
    select: (data: { mappings: Mapping[] }) => {
      return data.mappings;
    },
  });
  const { t } = useTranslation();
  const getSchema = useCallback(() => {
    switch (mapping.confirmationType) {
      case "Grouping":
      case "Status":
      case "Message":
        return simpleSchemaBuilder([
          { name: "key", required: true, type: "string" },
        ]);
      case "Mapping":
      case "Text":
        return simpleSchemaBuilder([
          { name: "key", required: true, type: "string" },
          { name: "value", required: true, type: "string" },
        ]);
      default:
        return simpleSchemaBuilder([
          { name: "key", required: true, type: "string" },
        ]);
    }
  }, [mapping.confirmationType]);
  return (
    <Formik
      enableReinitialize
      initialValues={mapping}
      onSubmit={onSubmit}
      validationSchema={getSchema()}
    >
      {({ dirty, isValid }) => (
        <Form>
          <Row justify="start" gutter={16}>
            {mapping.confirmationType === "Text" && (
              <TextFieldType
                title={t("translation:text")}
                valueDisabled={false}
              />
            )}
            {mapping.confirmationType === "Status" && (
              <StatusFieldType title={t("translation:status")} />
            )}
            {mapping.confirmationType === "Message" && (
              <TextFieldType
                title={t("translation:message")}
                valueDisabled
                placeholder={t("translation:message_field_expanded")}
              />
            )}
            {mapping.confirmationType === "Grouping" && <GroupingType />}
            {mapping.confirmationType === "Mapping" && (
              <MappingTypes mappings={mappings} />
            )}
            <Col flex="auto">
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || !isValid}
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
