import React, { useCallback } from "react";
import { Row } from "antd";
import { User } from "/app/src/models";
import Status from "../users/status";
import { useTranslation } from "react-i18next";
import NextButton from "/app/src/components/NextUi/Button";
import NextModal from "/app/src/components/NextUi/Modal";
import ModalBuilder from "/app/src/components/NextUi/helpers/ModalBuilder";
import { useDisclosure } from "@nextui-org/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { userService } from "/app/src/services";
import { useNavigate } from "react-router-dom";
import {
  useAuthDispatch,
  useAuthState,
  logout,
} from "/app/src/contexts/authentication";

export default function UserMenu({
  user,
  toggleUserStatus,
}: {
  user: User;
  toggleUserStatus: () => void;
}) {
  const { t } = useTranslation();
  const dispatch = useAuthDispatch();
  const {
    isOpen: isDisableModalOpen,
    onOpen: openDisableModal,
    onOpenChange: openChangeDisableModal,
  } = useDisclosure();
  const {
    isOpen: isDeleteModalOpen,
    onOpen: openDeleteModal,
    onOpenChange: openChangeDeleteModal,
  } = useDisclosure();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { user: authUser } = useAuthState();

  const toggleUser = useCallback(() => {
    toggleUserStatus();
    openChangeDisableModal();
  }, [openChangeDisableModal, toggleUserStatus]);

  const handleLogout = useCallback(() => {
    userService.logout().then(() => {
      logout(dispatch);
      navigate("/login");
    });
  }, [dispatch, navigate]);

  const { mutateAsync: deleteUser } = useMutation({
    mutationFn: (id: number) => userService.deleteSingle(id),
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);
    },
  });

  const deleteUserHandler = useCallback(() => {
    deleteUser(user.id).then(() => {
      if (authUser.id === user.id) {
        handleLogout();
      } else {
        navigate("/users");
      }
    });
  }, [deleteUser, user.id, authUser.id, navigate, handleLogout]);

  return (
    <div className="pl-[10px]">
      <Row>
        <h4>
          {t("translation:user")} {t("translation:status")}:
        </h4>
      </Row>
      <Row>
        <h2>
          <p>{Status(user.status)}</p>
        </h2>
      </Row>
      <Row>
        {user.status !== 2 ? (
          <>
            <NextButton
              fullWidth
              color="default"
              variant="bordered"
              size="sm"
              onClick={openDisableModal}
              className="border-[#a12121] text-[#a12121] bg-white"
            >
              {t("translation:deactivate")} {t("translation:user")}
            </NextButton>
            <NextModal
              disableAnimation={false}
              isOpen={isDisableModalOpen}
              onOpenChange={openChangeDisableModal}
              placement="top"
            >
              {ModalBuilder({
                warning: true,
                modalHeader: t("translation:warning"),
                modalBody: t("translation:confirm_deactivate_user"),
                modalFooter: (
                  <>
                    <NextButton
                      size="sm"
                      color="default"
                      variant="bordered"
                      onClick={openChangeDisableModal}
                      className="hover:border-primary-default hover:text-primary-default"
                    >
                      {t("translation:cancel")}
                    </NextButton>
                    <NextButton size="sm" color="red" onClick={toggleUser}>
                      {t("translation:deactivate")}
                    </NextButton>
                  </>
                ),
              })}
            </NextModal>
          </>
        ) : (
          <>
            <NextButton
              fullWidth
              color="primary"
              variant="bordered"
              className="bg-white"
              size="sm"
              onClick={openDisableModal}
            >
              {t("translation:activate")} {t("translation:user")}
            </NextButton>
            <NextModal
              disableAnimation={false}
              isOpen={isDisableModalOpen}
              onOpenChange={openChangeDisableModal}
              placement="top"
            >
              {ModalBuilder({
                warning: true,
                modalHeader: t("translation:warning"),
                modalBody: t("translation:confirm_activate_user"),
                modalFooter: (
                  <>
                    <NextButton
                      size="sm"
                      color="default"
                      variant="bordered"
                      onClick={openChangeDisableModal}
                      className="hover:border-primary-default hover:text-primary-default"
                    >
                      {t("translation:cancel")}
                    </NextButton>
                    <NextButton size="sm" color="primary" onClick={toggleUser}>
                      {t("translation:activate")}
                    </NextButton>
                  </>
                ),
              })}
            </NextModal>
          </>
        )}
        {user?.status === 2 && (
          <>
            <NextButton
              fullWidth
              color="default"
              variant="bordered"
              size="sm"
              onClick={openDeleteModal}
              className="border-[#a12121] text-[#a12121] bg-white"
            >
              {t("translation:delete_user")}
            </NextButton>
            <NextModal
              disableAnimation={false}
              isOpen={isDeleteModalOpen}
              onOpenChange={openChangeDeleteModal}
              placement="top"
            >
              {ModalBuilder({
                warning: true,
                modalHeader: t("translation:warning"),
                modalBody: t("translation:confirm_delete_user"),
                modalFooter: (
                  <>
                    <NextButton
                      size="sm"
                      color="default"
                      variant="bordered"
                      onClick={openChangeDeleteModal}
                      className="hover:border-primary-default hover:text-primary-default"
                    >
                      {t("translation:cancel")}
                    </NextButton>
                    <NextButton
                      size="sm"
                      color="red"
                      onClick={deleteUserHandler}
                    >
                      {t("translation:delete")}
                    </NextButton>
                  </>
                ),
              })}
            </NextModal>
          </>
        )}
      </Row>
    </div>
  );
}
