import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, Input, Checkbox } from "formik-antd";
import { settingService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { Integration, Setting } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { simpleSchemaBuilder } from "/app/src/helpers";
import { useCallback, useMemo } from "react";
import NextButton from "/app/src/components/NextUi/Button";
import Box from "/app/src/components/generic/components/box";
import { IconToolTip } from "/app/src/components/icons/IconBuilder";

/**
 * Format the form value keys to match the API Model
 */
function formatForm(values: FormValues, integrationId: number): Setting {
  return {
    name: values.name,
    integrationId,
    type: "header",
    value: values.value,
    number: values.number ? 2 : 1,
  };
}
/**
 * Format the form for updating a header
 */
function formatUpdateForm(values: UpdateFormValues): Setting {
  return {
    name: values.name,
    value: values.value,
    number: values.number ? 2 : 1,
  };
}

/**
 * Component to edit a header
 */
function EditHeader({
  header,
  removeHeader,
  updateHeader,
}: {
  header: Setting;
  removeHeader: (setting: Setting) => void;
  updateHeader: (setting: Setting) => void;
}) {
  const { t } = useTranslation();

  /**
   * Update the header
   */
  const updateHeaderHandler = useCallback(
    async (values: FormValues) => {
      if (header?.id) {
        const formattedForm = formatUpdateForm(values);
        await updateHeader({ id: header.id, ...formattedForm });
      }
    },
    [header.id, updateHeader],
  );
  /**
   * Handler for removing the headers
   */
  const handleRemoveHeader = useCallback(() => {
    removeHeader(header);
  }, [header, removeHeader]);

  /**
   * Form for editing a header
   */
  const editHeadingForm: (props: FormikProps<UpdateFormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isSubmitting }) => (
        <Form>
          <Row justify="start" gutter={16}>
            <Col span={2}>{"Header:"}</Col>
            <Col span={5}>
              <Form.Item name="key">
                <Input
                  size="large"
                  name="name"
                  placeholder={t("translation:enter_key")}
                />
              </Form.Item>
            </Col>
            <Col span={1}>
              <h1 style={{ textAlign: "center" }}>:</h1>
            </Col>
            <Col span={8}>
              <Form.Item name="value">
                <Input
                  size="large"
                  name="value"
                  placeholder={t("translation:enter_value")}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name="number" label={t("translation:private")}>
                <Checkbox name="number" />
              </Form.Item>
            </Col>
            <Col span={3}>
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || isSubmitting}
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
            <Col span={3}>
              <NextButton
                onClick={handleRemoveHeader}
                color="default"
                variant="bordered"
                fullWidth
                className="hover:border-primary-default hover:text-primary-default"
              >
                {t("translation:remove")}
              </NextButton>
            </Col>
          </Row>
        </Form>
      ),
      [handleRemoveHeader, t],
    );

  return (
    <Formik
      component={editHeadingForm}
      enableReinitialize
      initialValues={{
        name: header.name,
        value: header.value,
        number: header.number === 2,
      }}
      validationSchema={simpleSchemaBuilder([
        { name: "name", type: "string", required: true },
        { name: "value", type: "string", required: true },
      ])}
      onSubmit={updateHeaderHandler}
    />
  );
}

interface FormValues {
  name: string;
  value: string;
  number: boolean;
}
interface UpdateFormValues {
  name: string | undefined;
  value: string | undefined;
  number: boolean;
}

/**
 * Component to display all the headers for an integration
 */
export default function Headers({ integration }: { integration: Integration }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync: addHeader } = useMutation({
    mutationFn: (setting: Setting) => {
      return settingService.createSingle(setting).then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["settings", integration.id, "header"],
        (oldData: { settings: Setting[] }) => {
          return {
            settings: [...oldData.settings, response.setting],
          };
        },
      );
    },
  });

  const { mutateAsync: removeHeader } = useMutation({
    mutationFn: (setting: Setting) => {
      return settingService.deleteSingle(setting.id).then(() => {
        const settingId = setting.id;
        return { settingId };
      });
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["settings", integration.id, "header"],
        (oldData: { settings: Setting[] }) => {
          return {
            settings: oldData.settings.filter(
              (setting) => setting.id !== response.settingId,
            ),
          };
        },
      );
    },
  });

  const { mutateAsync: updateHeader } = useMutation({
    mutationFn: (setting: Setting) => {
      return settingService
        .updateSingle(setting.id, setting)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["settings", integration.id, "header"],
        (oldData: { settings: Setting[] }) => {
          return {
            settings: oldData.settings.map((setting) => {
              if (setting.id === response.setting.id) {
                return response.setting;
              }
              return setting;
            }),
          };
        },
      );
    },
  });

  const { data: settings } = useQuery({
    queryKey: ["settings", integration.id, "header"],
    queryFn: () => {
      return settingService.getAll(
        buildParams({ integrationId: integration.id, type: "header" }),
      );
    },
    enabled: Boolean(integration.id),
    initialData: { settings: [] },
    select: (data: { settings: Setting[] }) => {
      return data.settings;
    },
  });

  /**
   * Handler for adding a header
   */
  const addHeaderHandler = useCallback(
    async (values: FormValues) => {
      if (integration?.id) {
        const formattedForm = formatForm(values, integration.id);
        await addHeader(formattedForm);
      }
    },
    [addHeader, integration.id],
  );

  const labelContent = useMemo(
    () => (
      <>
        {t("translation:headers")}
        <IconToolTip
          icon="QuestionCircle"
          content={t("translation:private_description")}
          size={20}
          className="pl-[5px]"
        />
      </>
    ),
    [t],
  );

  /**
   * Form for creating a header
   */
  const createSettingForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isSubmitting }) => (
        <Form layout="vertical">
          <Row justify="start" gutter={16}>
            <Col span={6}>
              <Form.Item
                name="name"
                label={t("translation:key")}
                hasFeedback={false}
              >
                <Input
                  name="name"
                  placeholder={t("translation:enter_key")}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="value"
                label={t("translation:value")}
                hasFeedback={false}
              >
                <Input
                  name="value"
                  placeholder={t("translation:enter_value")}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name="number" label={labelContent}>
                <Checkbox name="number" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || isSubmitting}
                style={{ marginTop: "30px" }}
              >
                {t("translation:create_header")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [labelContent, t],
    );

  return (
    <Box>
      <h1>{t("translation:headers")}</h1>
      <div className="newIntegration">
        <div>
          {settings.map((setting) => (
            <EditHeader
              header={setting}
              removeHeader={removeHeader}
              updateHeader={updateHeader}
              key={setting.id}
            />
          ))}
        </div>
        <Formik
          initialValues={{
            value: "",
            name: "",
            number: false,
          }}
          validationSchema={simpleSchemaBuilder([
            { name: "name", type: "string", required: true },
            { name: "value", type: "string", required: true },
          ])}
          component={createSettingForm}
          onSubmit={addHeaderHandler}
        />
      </div>
    </Box>
  );
}
