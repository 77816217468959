import { useCallback, useEffect, useState } from "react";
import { Integration, Trigger } from "/app/src/models";
import { Col, Row, Select } from "antd";
import { useTranslation } from "react-i18next";
import NextButton from "/app/src/components/NextUi/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { integrationService, triggerService } from "/app/src/services";
import Triggers from "/app/src/components/generic/components/trigger";
import { handlePromiseError } from "/app/src/helpers/api";

/**
 *
 * @param param0 integration
 * @returns Data Cache Setting component for the integration
 */
export default function DataCache({
  integration,
}: {
  integration: Integration & { trigger?: Trigger };
}) {
  const { t } = useTranslation();
  const [cacheEnabled, setCacheEnabled] = useState<"true" | "false">("false");

  useEffect(() => {
    if (integration?.trigger) {
      setCacheEnabled("true");
    }
  }, [integration?.trigger]);

  const queryClient = useQueryClient();
  const { mutateAsync: deleteTrigger } = useMutation({
    mutationFn: (id: number) => {
      return triggerService.deleteSingle(id).then(handlePromiseError);
    },
    onSuccess: () => {
      queryClient.setQueryData(
        ["integration", integration.id],
        (oldData: { integration: Integration }) => {
          return {
            integration: {
              ...oldData.integration,
              trigger: undefined,
            },
          };
        },
      );
    },
  });
  const clearCache = useCallback(async () => {
    return await integrationService.clearIntegrationCache(integration.id);
  }, [integration.id]);

  const handleTriggerUpdate = useCallback(
    (trigger) => {
      const integrationCopy: Integration & { trigger?: Trigger } = {
        ...integration,
      };
      integrationCopy.trigger = trigger;
      queryClient.setQueryData(["integration", Number(integration.id)], {
        integration: integrationCopy,
      });
    },
    [queryClient, integration],
  );
  const handleCacheChange = useCallback(
    (value) => {
      if (value === "false" && integration?.trigger?.id) {
        //remove trigger
        deleteTrigger(integration?.trigger?.id);
      }
      setCacheEnabled(value);
    },
    [deleteTrigger, integration?.trigger?.id],
  );
  return (
    <>
      <Row justify="start" gutter={16}>
        <Col span={8}>
          <h3 className="font-semibold">{t("translation:data_cache")}</h3>
          <p>{t("translation:data_cache_description")}</p>
        </Col>
        <Col span={12}>
          <Select
            size="large"
            className="w-full !mt-[6px]"
            value={cacheEnabled}
            onChange={handleCacheChange}
          >
            <Select.Option value={"true"}>
              {t("translation:enabled")}
            </Select.Option>
            <Select.Option value={"false"}>
              {t("translation:disabled")}
            </Select.Option>
          </Select>
        </Col>
        <Col span={4}>
          <Row justify="end">
            <NextButton
              size="md"
              fullWidth
              className="mt-[6px]"
              variant="bordered"
              onClick={clearCache}
            >
              {t("translation:clear_cache")}
            </NextButton>
          </Row>
        </Col>
      </Row>
      <Row justify="start" gutter={16}>
        <Col span={16} offset={8}>
          {(integration?.trigger || cacheEnabled === "true") && (
            <Triggers
              object={integration}
              updateTrigger={handleTriggerUpdate}
              objectType="integration"
            />
          )}
        </Col>
      </Row>
    </>
  );
}
