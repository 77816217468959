import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { peripheralService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { Peripheral as PeripheralType } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
import Box from "/app/src/components/generic/components/box";
import { useHandleNavigationError } from "/app/src/helpers/api";

export default function Peripheral() {
  const { t } = useTranslation();
  const { peripheralId } = useParams();
  const handleNavigationError = useHandleNavigationError();

  //Get the Single Peripheral from URL
  const { data: peripheral } = useQuery({
    queryKey: ["peripheral", peripheralId],
    queryFn: () => {
      return peripheralService.getSingle(peripheralId).catch(() => {
        handleNavigationError();
      });
    },
    initialData: { peripheral: {} },
    retry: false,
    select: (data: { peripheral: PeripheralType }) => {
      return data.peripheral;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{peripheral?.name}</title>
      </Helmet>
      {peripheral?.name ? (
        <Box>
          <h1>{peripheral?.name}</h1>
          <div className="bigItems">
            <div className="bigItem">
              <h4>{t("translation:type")}</h4>
              <span>{peripheral?.typeDescription}</span>
            </div>
            <div className="bigItem">
              <h4>{t("translation:description")}</h4>
              <span>{peripheral?.description}</span>
            </div>
            <div className="bigItem">
              <h4>{t("translation:parameters")}</h4>
              <span>{peripheral?.parameters}</span>
            </div>
          </div>
        </Box>
      ) : (
        <div />
      )}
    </div>
  );
}
