import React, { memo, useCallback } from "react";
import { Formik, FormikProps } from "formik";
import { Col } from "antd";
import { Form } from "formik-antd";
import { formatForm } from "./newMapping";
import { mappingService, reportColumnTypeService } from "/app/src/services";
import {
  ColumnType,
  InternalIdType,
  TextType,
  AdvancedType,
  ReportColumnTypes,
  GroupingType,
  DraggableMappingType,
} from "./mappingTypes";
import {
  Integration,
  Mapping,
  MappingType,
  ReportColumnType,
} from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { simpleSchemaBuilder } from "/app/src/helpers";
import { themes } from "/app/src/constants/themes";
import getMappingTitle from "./utilities";
import {
  newMappingSchema,
  sqlColumnMappingSchema,
} from "/app/src/schemas/apps/dataPush/newMappingSchema";

interface FormValues {
  dataType: string | undefined;
  key: string | string[] | undefined;
  value: string | undefined;
  columnTypeId: number | [string | undefined, number | undefined] | undefined;
  type: MappingType | undefined;
  parentMappingId: number | undefined;
}

/**
 *
 * Component to edit the mapping
 */
const EditMapping = memo(function EditMapping({
  mapping,
  hideForm = false,
}: {
  mapping: Mapping & { connectionType?: string };
  hideForm: boolean;
}) {
  const queryClient = useQueryClient();
  const { integration } = queryClient.getQueryData<{
    integration: Integration;
  }>(["integration", mapping.integrationId]);
  const groupingLabel = getMappingTitle(mapping, integration);

  const isThemed = integration.baseTable !== null;

  const { data: columnTypes } = useQuery({
    queryKey: ["columnTypes", integration.baseTable],
    queryFn: () => {
      const params = { baseTable: integration.baseTable };
      if (integration.baseTable === "Material") {
        params["table"] = "Material";
      }
      if (params.baseTable === "Order" && integration.type === "delete") {
        params.baseTable = "Order Deletion";
      }
      return reportColumnTypeService.getAll(buildParams(params));
    },
    enabled: Boolean(
      themes.map((map) => map.value).includes(integration.baseTable) ||
        integration.baseTable === "Order",
    ),
    initialData: { report_column_types: [] },
    select: (data: { report_column_types: ReportColumnType[] }) => {
      return data.report_column_types;
    },
    staleTime: 60 * 60,
  });

  const { mutateAsync: removeMapping } = useMutation({
    mutationFn: (mapping: Mapping) => {
      return mappingService.deleteSingle(mapping.id).then(() => {
        const mappingId = mapping.id;
        return { mappingId };
      });
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["mappings", integration.id],
        (oldData: { mappings: Mapping[] }) => {
          const idsToRemove = new Set();

          // Recursive function to find all descendants by parentMappingId
          const findDescendants = (mappings, parentId) => {
            mappings.forEach((mapping) => {
              if (mapping.parentMappingId === parentId) {
                idsToRemove.add(mapping.id);
                findDescendants(mappings, mapping.id);
              }
            });
          };

          idsToRemove.add(response.mappingId);
          findDescendants(oldData.mappings, response.mappingId);

          return {
            mappings: oldData.mappings.filter(
              (mapping) => !idsToRemove.has(mapping.id),
            ),
          };
        },
      );
    },
  });

  const { mutateAsync: updateMapping } = useMutation({
    mutationFn: (mapping: Omit<Mapping, "parentMapping" | "children">) => {
      return mappingService
        .updateSingle(mapping.id, mapping)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["mappings", integration.id],
        (oldData: { mappings: Mapping[] }) => {
          return {
            mappings: oldData.mappings.map((mapping) =>
              mapping.id === response.mapping.id ? response.mapping : mapping,
            ),
          };
        },
      );
    },
  });

  const updateMappingHandler = useCallback(
    async (values: FormValues) => {
      const formattedValues = formatForm(values);
      return await updateMapping({ id: mapping.id, ...formattedValues });
    },
    [mapping.id, updateMapping],
  );

  /**
   * Form for a column type mapping
   */
  const columnTypeForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form>
          <ColumnType
            isThemed={isThemed}
            columnTypes={columnTypes}
            removeMapping={removeMapping}
            mapping={mapping}
            dirty={dirty}
            isValid={isValid}
          />
        </Form>
      ),
      [columnTypes, isThemed, mapping, removeMapping],
    );
  /**
   * Form for a text type mapping
   */
  const textTypeForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => {
        return (
          <Form>
            <TextType
              removeMapping={removeMapping}
              mapping={mapping}
              dirty={dirty}
              isValid={isValid}
            />
          </Form>
        );
      },
      [mapping, removeMapping],
    );

  const reportColumnForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form>
          <ReportColumnTypes
            removeMapping={removeMapping}
            mapping={mapping}
            dirty={dirty}
            isValid={isValid}
          />
        </Form>
      ),
      [mapping, removeMapping],
    );

  /**
   * For for an internal id mapping
   */
  const idTypeForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form>
          <InternalIdType
            removeMapping={removeMapping}
            mapping={mapping}
            dirty={dirty}
            isValid={isValid}
          />
        </Form>
      ),
      [mapping, removeMapping],
    );

  const advancedTypeForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form>
          <AdvancedType
            removeMapping={removeMapping}
            mapping={mapping}
            dirty={dirty}
            isValid={isValid}
            isThemed={isThemed}
            columnTypes={columnTypes}
          />
        </Form>
      ),
      [columnTypes, isThemed, mapping, removeMapping],
    );

  /**
   * Form for a grouping type mapping
   */
  const editGroupingForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty }) => (
        <Form>
          <GroupingType
            label={groupingLabel}
            mapping={mapping}
            dirty={dirty}
            removeMapping={removeMapping}
          />
        </Form>
      ),
      [groupingLabel, mapping, removeMapping],
    );

  /**
   * Get the initial values for the form
   */
  const getInitialValues = useCallback(() => {
    let columnType:
      | number
      | [string | undefined, number | undefined]
      | undefined = mapping.columnTypeId;
    if (isThemed) {
      columnType = [mapping.value, mapping.columnTypeId];
    }
    return {
      key: mapping.key,
      dataType: mapping.dataType,
      value: mapping.value,
      columnTypeId: columnType,
      parentMappingId: mapping.parentMappingId,
      type: mapping.type,
    } as {
      key: string | string[] | undefined;
      dataType: string | undefined;
      value: string | undefined;
      columnTypeId:
        | number
        | [string | undefined, number | undefined]
        | undefined;
      parentMappingId: number | undefined;
      type: MappingType | undefined;
    };
  }, [isThemed, mapping]);

  /**
   * Converts advanced type initial values to the correct format - list of strings
   */
  const getAdvancedInitialValues = () => {
    const initValues = getInitialValues();
    if (initValues.type === "advanced") {
      if (typeof initValues.key === "string") {
        initValues.key = initValues.key?.split(",");
      }
    }
    return initValues;
  };

  if (hideForm) {
    return <DraggableMappingType mapping={mapping} integration={integration} />;
  } else {
    return (
      <Col span={24}>
        {["lines", "grouping", "data"].includes(mapping.type) && (
          <Formik
            enableReinitialize
            component={editGroupingForm}
            initialValues={getInitialValues()}
            validationSchema={simpleSchemaBuilder([
              { name: "key", type: "string", required: true },
            ])}
            onSubmit={updateMappingHandler}
          />
        )}
        {mapping.type === "column" && (
          <Formik
            enableReinitialize
            component={columnTypeForm}
            initialValues={getInitialValues()}
            validationSchema={
              ["MSSQL", "IBM2"].includes(integration.connectionType)
                ? sqlColumnMappingSchema
                : newMappingSchema
            }
            onSubmit={updateMappingHandler}
          />
        )}
        {mapping.type === "text" && (
          <Formik
            enableReinitialize
            component={textTypeForm}
            initialValues={getInitialValues()}
            validationSchema={simpleSchemaBuilder([
              { name: "key", type: "string", required: true },
              { name: "value", type: "string", required: true },
            ])}
            onSubmit={updateMappingHandler}
          />
        )}
        {mapping.type === "id" && (
          <Formik
            enableReinitialize
            component={idTypeForm}
            initialValues={getInitialValues()}
            validationSchema={simpleSchemaBuilder([
              { name: "key", type: "string", required: true },
            ])}
            onSubmit={updateMappingHandler}
          />
        )}
        {mapping.type === "reportColumn" && (
          <Formik
            enableReinitialize
            component={reportColumnForm}
            initialValues={getInitialValues()}
            validationSchema={simpleSchemaBuilder([
              { name: "dataType", type: "string", required: true },
              { name: "key", type: "string", required: true },
            ])}
            onSubmit={updateMappingHandler}
          />
        )}
        {mapping.type === "advanced" && (
          <Formik
            enableReinitialize
            component={advancedTypeForm}
            initialValues={getAdvancedInitialValues()}
            validationSchema={simpleSchemaBuilder([
              { name: "key", type: "mixed", required: true },
            ])}
            onSubmit={updateMappingHandler}
          />
        )}
      </Col>
    );
  }
});

export default EditMapping;
