import { useState, useEffect, useCallback } from "react";
import { Row, Col } from "antd";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Form, Select, SubmitButton } from "formik-antd";
import { buildParams } from "/app/src/helpers/params";
import {
  deleteObjectInStateArray,
  addObjectInStateArray,
} from "/app/src/helpers/modifyObjectInStateArray";
import {
  reportService,
  fileService,
  appService,
  integrationService,
} from "/app/src/services";
import { useTranslation } from "react-i18next";
import File from "./file";
import { useAuthState } from "/app/src/contexts/authentication";
import { Report, App, File as FileType, Integration } from "/app/src/models";
import { reportExportSchema } from "/app/src/schemas/reports/reportSchema";
import NextButton from "/app/src/components/NextUi/Button";
import NextModal from "/app/src/components/NextUi/Modal";
import ModalBuilder from "/app/src/components/NextUi/helpers/ModalBuilder";
import { useDisclosure } from "@nextui-org/react";
import Box from "/app/src/components/generic/components/box";

interface FormValues {
  exportType: string | undefined;
  integrationId: number | undefined;
}

/**
 * Formats the form values along with user and report IDs and integrations to create an export file type object.
 *
 * @param {FormValues} values - The form values to be formatted.
 * @param {number | undefined} userId - The ID of the user, can be undefined.
 * @param {number | undefined} reportId - The ID of the report, can be undefined.
 * @param {Integration[]} integrations - The list of integrations.
 * @returns {FileType} - The formatted export file type object.
 */
function formatForm(
  values: FormValues,
  userId: number | undefined,
  reportId: number | undefined,
  integrations: Integration[],
): FileType {
  let exportType = "CSV";
  if (values.exportType) {
    exportType = JSON.parse(values.exportType).name;
  }
  let integrationId = values.integrationId;
  if (exportType === "XLSX") {
    integrationId = integrations[0].id;
  }

  const ret = {
    exportType,
    reportId,
    userId,
    integrationId,
  };

  return ret;
}

export default function Export({ report }: { report: Report }) {
  const { user } = useAuthState();
  const { t } = useTranslation();
  const [template, setTemplate] = useState();
  const [reportFiles, setReportFiles] = useState<FileType[]>([]);
  const [apps, setApps] = useState<App[]>([]);
  const [selectedApp, setSelectedApp] = useState<App>({});
  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  //get the integrations for the selected file type(app)
  useEffect(() => {
    integrationService
      .getAll(buildParams({ appId: selectedApp.id }))
      .then((response) => {
        setIntegrations(response.integrations);
      });
  }, [selectedApp]);

  //get the file apps - needed for getting integrations
  useEffect(() => {
    appService
      .getAll(buildParams({ name: "[or]CSV;XLSX" }))
      .then((response) => {
        setApps(response.apps);
      });
  }, []);

  const removeFile = useCallback(
    (reportFile: FileType) => {
      return deleteObjectInStateArray(
        reportFile,
        reportFiles,
        setReportFiles,
        fileService.deleteSingle,
      );
    },
    [reportFiles, setReportFiles],
  );

  const addFile = useCallback(
    (reportFile: FileType) => {
      return addObjectInStateArray(
        reportFile,
        reportFiles,
        setReportFiles,
        fileService.createSingle,
        true,
      );
    },
    [reportFiles, setReportFiles],
  );
  //this does not use the generic functions,
  //as there is no API update occurring - instead
  //it is an update of only the front end object.
  //this occurs when the newly generated file's location
  //gets fetched
  const updateFile = useCallback(
    (reportFile: FileType) => {
      setReportFiles(
        reportFiles.map((file) =>
          file.id === reportFile.id
            ? {
                ...file,
                location: reportFile.location,
                name: reportFile.name,
                fileType: reportFile.fileType,
              }
            : file,
        ),
      );
    },
    [reportFiles, setReportFiles],
  );

  useEffect(() => {
    if (report?.id) {
      reportService
        .exportSingle(report.id, buildParams({ export: "json" }))
        .then((response) => setTemplate(response));
    }
  }, [report.id]);

  useEffect(() => {
    if (report?.id) {
      fileService
        .getAll(
          buildParams({
            reportId: report.id,
            orderby: "creationDate:desc",
            limit: 20,
          }),
        )
        .then((response) => {
          setReportFiles(response.files);
        });
    }
  }, [report.id]);

  const HandleExportClick = useCallback(() => {
    //create the link to download the json file
    const data = `data:text/json;charset=utf-8,${encodeURIComponent(
      JSON.stringify(template),
    )}`;

    //easy way to trigger download popup:
    const link = document.createElement("a");
    link.href = data;
    link.download = `${report.name}-template.itempath.json`;
    link.click();
  }, [template, report.name]);

  // the integrationId placeholder text should change based
  // on the selected export type
  const getPlaceholder = useCallback(() => {
    switch (selectedApp?.name) {
      case "CSV":
        return t("translation:select_delimiter");
      case "XLSX":
        return t("translation:blank");
      default:
        return "";
    }
  }, [selectedApp?.name, t]);

  const handleSubmit = useCallback(
    (values: FormValues, actions: FormikHelpers<FormValues>) => {
      if (user?.id && report?.id) {
        addFile(formatForm(values, user.id, report.id, integrations)).then(
          (response) => {
            actions.setSubmitting(false);
            setSelectedApp({});
            if (!response?.errors) {
              actions.resetForm();
            }
          },
        );
      }
    },
    [user, report, integrations, addFile],
  );

  const onClick = useCallback(() => {
    HandleExportClick();
    onOpenChange();
  }, [HandleExportClick, onOpenChange]);

  const exportTypeChange = useCallback((value) => {
    setSelectedApp(JSON.parse(value));
  }, []);

  const exportForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      () => (
        <Form layout="vertical">
          <Row justify="start" gutter={16}>
            <Col span={6}>{t("translation:template_description")}</Col>
            <Col span={4}>
              <NextButton
                size="md"
                variant="bordered"
                color="primary"
                onClick={onOpen}
                fullWidth
                className="hover:border-primary-default hover:text-primary-default"
              >
                {t("translation:download_template")}
              </NextButton>
              <NextModal
                disableAnimation={false}
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                placement="top"
              >
                {ModalBuilder({
                  modalHeader: t("translation:confirm_file_download"),
                  modalFooter: (
                    <>
                      <NextButton
                        size="md"
                        color="default"
                        variant="bordered"
                        onClick={onOpenChange}
                      >
                        {t("translation:cancel")}
                      </NextButton>
                      <NextButton size="md" color="primary" onClick={onClick}>
                        {t("translation:download")}
                      </NextButton>
                    </>
                  ),
                })}
              </NextModal>
            </Col>
            <Col span={3} offset={4}>
              <Form.Item name="exportType">
                <Select
                  name="exportType"
                  size="large"
                  onChange={exportTypeChange}
                  placeholder={t("translation:select_file_type")}
                >
                  {apps.map((c) => (
                    <Select.Option key={c.id} value={JSON.stringify(c)}>
                      {c.name}
                    </Select.Option>
                  ))}{" "}
                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="integrationId">
                <Select
                  style={{
                    display: selectedApp?.name !== "CSV" ? "none" : undefined,
                  }}
                  name="integrationId"
                  size="large"
                  placeholder={getPlaceholder()}
                >
                  {integrations.map((c) => (
                    <Select.Option key={c.id} value={c.id}>
                      {c.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <SubmitButton type="primary" size="large" block>
                {t("translation:generate_new_file")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [
        t,
        onOpen,
        isOpen,
        onOpenChange,
        onClick,
        exportTypeChange,
        apps,
        selectedApp?.name,
        getPlaceholder,
        integrations,
      ],
    );
  const initialFormValues: FormValues = {
    exportType: "",
    integrationId: undefined,
  };
  return (
    <div className="reportExport">
      <div className="export">
        <Formik
          component={exportForm}
          initialValues={initialFormValues}
          validationSchema={reportExportSchema}
          onSubmit={handleSubmit}
        />
      </div>
      <div style={{ marginTop: "15px" }}>
        <Box>
          <h1>{t("translation:generated_files")}</h1>
          {reportFiles.map((reportFile) => (
            <File
              file={reportFile}
              removeFile={removeFile}
              updateFile={updateFile}
              key={reportFile.id}
            />
          ))}
        </Box>
      </div>
    </div>
  );
}
