import React, { useState, useEffect, useCallback } from "react";
import { Col, Row } from "antd";
import { Form, SubmitButton, Select, Input } from "formik-antd";
import { ColorResult, CompactPicker } from "react-color";
import { hueService, connectionService } from "/app/src/services";
import { IconToolTip } from "/app/src/components/icons/IconBuilder";
import { useTranslation } from "react-i18next";
import { WorkflowAction, Connection } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import NextButton from "/app/src/components/NextUi/Button";
import { useFormikContext } from "formik";
import { useQuery } from "@tanstack/react-query";

type Zone = {
  id: number;
  name: string;
};

interface FormValues {
  to: string | undefined;
  value: string | undefined;
  message: string | undefined;
}

/*
 * Form for the Hue action
 */
export default function HueForm({
  action,
  removeWorkflowAction,
  isNew,
}: {
  action: WorkflowAction;
  removeWorkflowAction: (
    action: WorkflowAction,
  ) => Promise<{ deleted: boolean }> | undefined;
  isNew: boolean;
}) {
  const { dirty, setFieldValue } = useFormikContext<FormValues>();
  const [color, setColor] = useState<string | number>("#fcdc00");
  const [showing, setShowing] = useState(false);
  const [zones, setZones] = useState([]);
  const [connectionId, setConnectionId] = useState<number | string>();
  const { t } = useTranslation();

  //Hide the color picker
  const hidePicker = useCallback(() => {
    setShowing((prevShowing) => !prevShowing);
  }, []);

  const handleRemoveWorkflowAction = useCallback(() => {
    removeWorkflowAction(action);
  }, [action, removeWorkflowAction]);

  const handleColorChange = useCallback(
    (colour: ColorResult) => {
      if (colour) {
        setColor(colour.hex);
        setFieldValue("message", colour.hex);
      }
      setShowing(false);
    },
    [setFieldValue],
  );
  /**
   * Gets Hue Zones for the selected bridge
   */
  const getZones = (id: string | number) => {
    hueService.getAllZones({ id }).then((response) => {
      if (!response?.errors) {
        setZones(response);
      }
    });
  };
  const handleBridgeChange = useCallback(
    (value) => {
      getZones(value);
      setFieldValue("value", "");
    },
    [setFieldValue],
  );
  const { data: connections } = useQuery({
    queryKey: ["connections", { type: "[or]Hue Remote;Hue Local" }],
    queryFn: () => {
      return connectionService.getAll(
        buildParams({
          type: "[or]Hue Remote;Hue Local",
        }),
      );
    },
    select: (data: { connections: Connection[] }) => {
      return data.connections;
    },
    initialData: { connections: [] },
  });

  useEffect(() => {
    if (isNew === false && action?.to) {
      getZones(action.to);
    }
  }, [action?.to, isNew]);

  useEffect(() => {
    if (isNew === false && action?.message && action?.to) {
      setColor(action.message);
      setConnectionId(action.to);
    }
  }, [isNew, action]);

  const learnMore = useCallback(() => {
    const isNewWindow = window.open(
      "https://itempath.com/docs/phillips-hue/setting-up-a-zone",
      "_blank",
      "noopener,noreferrer",
    );
    if (isNewWindow) isNewWindow.opener = null;
  }, []);
  return (
    <Row justify="start" gutter={16}>
      <Col span={3}>
        <span className="blockText">{t("translation:activate")}</span>
      </Col>
      <Col span={9}>
        <Form.Item name="to">
          <Select
            name="to"
            size="large"
            placeholder={t("translation:select_bridge")}
            onChange={handleBridgeChange}
          >
            {connections.map((c: Connection) => (
              <Select.Option key={c.id} value={String(c.id)}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {connectionId === null && zones.length < 1 ? (
          <IconToolTip
            content={t("translation:no_hue_zones_found")}
            icon="Warning"
            onClick={learnMore}
            color="#ffcc00"
            size={32}
          />
        ) : (
          ""
        )}
        <Form.Item name="value">
          <Select
            name="value"
            size="large"
            placeholder={t("translation:select_zone")}
          >
            {zones.map((c: Zone) => (
              <Select.Option key={c.id} value={c.id}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="message">
          <Input.TextArea name="message" placeholder="" hidden value={color} />
        </Form.Item>
      </Col>
      <Col span={6}>
        <div style={{ backgroundColor: `${color}` }}>
          <NextButton
            size="md"
            variant="solid"
            color="primary"
            fullWidth
            onClick={hidePicker}
            className={`bg-[${color}]`}
          >
            {t("translation:select_colour")}
          </NextButton>
        </div>

        {showing ? (
          <CompactPicker onChangeComplete={handleColorChange} />
        ) : null}
      </Col>

      <Col flex="auto">
        <SubmitButton type="primary" size="large" block disabled={!dirty}>
          {isNew ? t("translation:add_action") : t("translation:save")}
        </SubmitButton>
      </Col>
      {!isNew && (
        <Col span={3}>
          <NextButton
            size="md"
            variant="bordered"
            color="default"
            fullWidth
            onClick={handleRemoveWorkflowAction}
            className="hover:border-primary-default hover:text-primary-default bg-white"
          >
            {t("translation:remove")}
          </NextButton>
        </Col>
      )}
    </Row>
  );
}
