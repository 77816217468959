import React, { useCallback, useMemo, useState } from "react";
import getOrderByQuery from "/app/src/helpers/table";
import DateTime from "/app/src/components/generic/formatting/dateTime";
import { Task } from "/app/src/models";
import { useTranslation } from "react-i18next";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import { useTasks } from "./data/useTasks";
import StatusIcon from "/app/src/components/generic/components/statusIcon";

/**
 * Shows a table of tasks for a given integration
 * @param param Object containing integrationId, setTaskId and emptyText
 */
export default function TasksTable({
  integrationId,
  setTaskId,
  emptyText,
  params = {},
  refresh = false,
  columns = [],
}: {
  integrationId: number;
  setTaskId: (taskId: number) => void;
  emptyText: string;
  includeSearch?: boolean;
  params?: { [key: string]: string | number };
  refresh?: boolean;
  columns?: ColumnDef<Task>[];
}) {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<Task>();
  const defaultColumns = useMemo(
    () => [
      columnHelper.accessor("creationDate", {
        id: "creationDate",
        cell: (info) => <DateTime date={info.getValue()} />,
        header: t("translation:time"),
      }),
      columnHelper.accessor("data", {
        id: "data",
        header: t("translation:data"),
      }),
      columnHelper.accessor("status", {
        id: "status",
        cell: (info) => {
          const value = info.getValue();
          return <StatusIcon status={value} />;
        },
        header: t("translation:status"),
      }),
    ],
    [columnHelper, t],
  );
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useSortUpgrade([]);
  const { tasks, tasksCount, isLoading } = useTasks(
    {
      page,
      limit: pageSize,
      orderby: getOrderByQuery(sort),
      integrationId,
      ...params,
    },
    { integrationId, ...params },
    refresh,
  );

  const rowClicked = useCallback(
    (id: string | number) => {
      setTaskId(Number(id));
    },
    [setTaskId],
  );

  return (
    <div className="workflow">
      <Table
        loading={isLoading}
        rows={tasks}
        tableColumns={columns?.length ? columns : defaultColumns}
        length={tasksCount}
        sort={sort}
        setSort={setSort}
        rowClicked={rowClicked}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage,
          setPageSize,
        }}
        enableRowSelection
        emptyText={emptyText}
      />
    </div>
  );
}
