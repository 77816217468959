import React, { useCallback, useMemo } from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col } from "antd";
import {
  Form,
  SubmitButton,
  Cascader,
  SelectProps,
  Select,
  Input,
} from "formik-antd";
import { useTranslation } from "react-i18next";
import { Integration, ReportColumnType, Setting } from "/app/src/models";
import DataHook from "./dataHook";
import { useQuery } from "@tanstack/react-query";
import { reportColumnTypeService } from "/app/src/services";
import {
  buildParams,
  convertArrayToSemicolonSeparated,
  convertSemicolonSeparatedToArray,
} from "/app/src/helpers";
import { cascadeReportColumnTypes } from "/app/src/helpers/cascadeReportColumnTypes";
import NextButton from "/app/src/components/NextUi/Button";
import Box from "/app/src/components/generic/components/box";

const comparators = [
  { value: "[eq]", label: "is" },
  { value: "[gt]", label: "greater than" },
  { value: "[lt]", label: "less than" },
  { value: "[not]", label: "not" },
  { value: "[gte]", label: "greater than or equal to" },
  { value: "[lte]", label: "less than or equal to" },
  { value: "[like]", label: "like" },
  { value: "[notlike]", label: "not like" },
];
interface FormValues {
  name: (string | number)[] | undefined;
  comparator: string;
  value: string;
}

interface ConsolidateLinesFormValues {
  value: "0" | "1" | "2";
}

export default function Filters({ integration }: { integration: Integration }) {
  const { t } = useTranslation();
  const {
    consolidateLinesQuery,
    consolidateLinesMutator,
    filterSettingsQuery,
    deleteFilterSettingMutator,
    updateFilterSettingMutator,
    createFilterSettingMutator,
  } = DataHook(integration);

  const { data: columnTypes } = useQuery({
    queryKey: ["columnTypes", "History"],
    queryFn: () => {
      const params = { baseTable: integration.baseTable };
      return reportColumnTypeService.getAll(buildParams(params));
    },
    enabled: Boolean(integration.baseTable),
    initialData: { report_column_types: [] },
    select: (data: { report_column_types: ReportColumnType[] }) => {
      return data.report_column_types;
    },
  });

  const handleFilterCreation = useCallback(
    (values: FormValues, actions: FormikHelpers<FormValues>) => {
      const formValues = {
        value:
          values.comparator + convertArrayToSemicolonSeparated(values.value),
        name: values.name[1] as string,
      };
      createFilterSettingMutator(formValues).then(() => actions.resetForm());
    },
    [createFilterSettingMutator],
  );

  const consolidateLinesHandler = useCallback(
    async (
      values: ConsolidateLinesFormValues,
      actions: FormikHelpers<ConsolidateLinesFormValues>,
    ) => {
      await consolidateLinesMutator(values);
      actions.resetForm();
    },
    [consolidateLinesMutator],
  );

  const getInitialName = useCallback(
    (id: string) => {
      const col = columnTypes.find((c) => c.id === Number(id));
      if (col) {
        return [col.table, Number(id)];
      }
      return undefined;
    },
    [columnTypes],
  );
  const getInitialComparator = useCallback((value: string) => {
    const comparator = comparators.find((c) => {
      return value.includes(c.value);
    });
    if (comparator) {
      return comparator.value;
    }
    return "[eq]";
  }, []);
  const getInitialValue = useCallback((value: string) => {
    const comparator = comparators.find((c) => value.includes(c.value));
    if (comparator) {
      return convertSemicolonSeparatedToArray(
        value.replace(comparator.value, ""),
      );
    }
    return value;
  }, []);
  const options: SelectProps["options"] = useMemo(
    () => [{ label: t("translation:no_value"), value: "no_value" }],
    [t],
  );
  const filterSettingForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isSubmitting, values }) => (
        <Form layout="vertical">
          <Row justify="start" gutter={16}>
            <Col span={8}>
              <Form.Item name="name" label={t("translation:property")}>
                <Cascader
                  name="name"
                  size="large"
                  className="larger-cascade"
                  options={cascadeReportColumnTypes(columnTypes)}
                  placeholder={t("translation:select_column")}
                  showSearch
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="comparator" label={t("translation:comparator")}>
                <Select name="comparator" size="large">
                  {comparators.map((comparator) => (
                    <Select.Option
                      key={comparator.value}
                      value={comparator.value}
                    >
                      {comparator.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="value" label={t("translation:value")}>
                {["[eq]", "[not]"].includes(values.comparator) ? (
                  <Select
                    name="value"
                    placeholder={t("translation:enter_value")}
                    size="large"
                    mode="tags"
                    options={options}
                    tokenSeparators={[";"]}
                  />
                ) : (
                  <Input name="value" size="large" />
                )}
              </Form.Item>
            </Col>
            <Col span={4}>
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || isSubmitting}
                style={{ marginTop: "30px" }}
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [t, columnTypes, options],
    );

  const consolidateLinesForm: (
    props: FormikProps<ConsolidateLinesFormValues>,
  ) => JSX.Element = useCallback(
    ({ dirty, isSubmitting }) => (
      <Form layout="vertical">
        <Row justify="start" gutter={16}>
          <Col span={20}>
            <Form.Item
              name="value"
              label={t("translation:consolidate_transactions")}
            >
              <Select name="value" size="large">
                <Select.Option value={"0"}>
                  {t("translation:disabled")}
                </Select.Option>
                <Select.Option value={"1"}>
                  {t("translation:consolidate_lines")}
                </Select.Option>
                <Select.Option value={"2"}>
                  {t("translation:consolidate_orders")}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <SubmitButton
              type="primary"
              size="large"
              block
              disabled={!dirty || isSubmitting}
              style={{ marginTop: "30px" }}
            >
              {t("translation:save")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [t],
  );

  return (
    <Box>
      <h1>{"Filters"}</h1>
      {filterSettingsQuery.data?.map((setting: Setting) => {
        const updateFilterSetting = (values: FormValues) => {
          const formValues = {
            value:
              values.comparator +
              convertArrayToSemicolonSeparated(values.value),
            name: values.name[1] as string,
          };
          updateFilterSettingMutator({ ...formValues, id: setting.id });
        };
        const deleteFilterSetting = () => {
          deleteFilterSettingMutator(setting.id);
        };
        const initialValues = {
          name: getInitialName(setting.name),
          value: getInitialValue(setting.value),
          comparator: getInitialComparator(setting.value),
        };
        return (
          <Row justify="start" gutter={16} key={setting.id}>
            <Col span={21}>
              <Formik
                key={setting.id}
                component={filterSettingForm}
                initialValues={initialValues}
                enableReinitialize
                onSubmit={updateFilterSetting} // skipcq: JS-0417
              />
            </Col>
            <Col span={3}>
              <NextButton
                onClick={deleteFilterSetting} // skipcq: JS-0417
                color="default"
                fullWidth
                className="text-red-500 border-red-500 mt-[30px] w-[100%]"
                variant="bordered"
              >
                {t("translation:delete")}
              </NextButton>
            </Col>
          </Row>
        );
      })}
      <Row justify="start" gutter={16}>
        <Col span={21}>
          <Formik
            component={filterSettingForm}
            initialValues={{
              name: undefined,
              comparator: "[eq]",
              value: "",
            }}
            onSubmit={handleFilterCreation}
          />
        </Col>
      </Row>
      <Row justify="start" gutter={16}>
        <Col span={21}>
          <Formik
            component={consolidateLinesForm}
            initialValues={{
              value: consolidateLinesQuery.data?.settings[0]?.value || "0",
            }}
            enableReinitialize
            onSubmit={consolidateLinesHandler}
          />
        </Col>
      </Row>
    </Box>
  );
}
