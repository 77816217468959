import { useState, useEffect, useCallback } from "react";
import { InputNumber } from "antd";
import { materialService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { Material } from "/app/src/models";
import { OrderBuilderLine } from "./type";

import DeleteButton from "/app/src/components/generic/components/buttons/DeleteButton";

export default function Line({
  line,
  removeLine,
  updateLineQuantity,
}: {
  line: OrderBuilderLine;
  removeLine: (lineId: number) => void;
  updateLineQuantity: (lineId: number, newQuantity: number) => void;
}) {
  const { t } = useTranslation();
  const [material, setMaterial] = useState<Material>({});
  useEffect(() => {
    if (line.materialId) {
      materialService
        .getSingle(line.materialId)
        .then((response) => setMaterial(response.material));
    }
  }, [line]);

  const handleDeleteClick = useCallback((): void => {
    if (line.id) {
      removeLine(line.id);
    }
  }, [line, removeLine]);

  const handleQuantityChange = useCallback(
    (value) => {
      if (line.id && value) {
        updateLineQuantity(line.id, value);
      }
    },
    [line, updateLineQuantity],
  );

  return (
    <div className={`line ${material?.currentQuantity ? "" : "outOfStock"}`}>
      <div className="title">
        <h4>{material.name}</h4>
        <span className="stock ">
          {t("translation:in_stock")}: {material.currentQuantity}
        </span>
      </div>
      <div className="quantity">
        <InputNumber
          defaultValue={line.quantity}
          name="quantity"
          placeholder=""
          size="large"
          onChange={handleQuantityChange}
        />
      </div>
      <div className="actions">
        <DeleteButton onClick={handleDeleteClick} />
      </div>
    </div>
  );
}
