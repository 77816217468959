//with optional regex groups, we need to check the length to ensure the entire value entered is valid.
//without doing so, any value with an ip address inside would be considered valid(because IP is non-optional in the regex).
//e.g asdfasd:123.12.1.1:sdf would be considered valid if only using urlRegex.test()
function matchRegex(regex: RegExp, value: string) {
  //catch no value
  if (!value) {
    return false;
  }
  const match = value.match(regex);
  let valid = false;

  if (match) {
    if (match[0].length === value.length) {
      valid = true;
    }
  }
  return valid;
}

/**
 *
 * @param url string | undefined
 * @returns Check if the given string is a valid IP address or URL
 */
export default function isIPAddress(url: string | undefined) {
  const ipRegex =
    /(https?:\/\/)?([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})(:([0-9]{1,5}))?/;
  const urlRegex =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/; // Change this regex based on requirement
  if (url) {
    const validIP = matchRegex(ipRegex, url);
    const validURL = matchRegex(urlRegex, url);
    return validIP || validURL;
  } else {
    return false;
  }
}

// Validate if a string is a valid number
export function isValidNumber(value: string) {
  return !isNaN(Number(value));
}
