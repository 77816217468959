import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";
import { Row, Col } from "antd";
import { integrationService } from "/app/src/services";
import Settings from "/app/src/components/generic/components/apps/data/settings";
import { Mappings } from "/app/src/components/generic/components/apps/data/mappings";
import SideMenuBox from "/app/src/components/generic/components/sideMenuBox";
import DataPushLogs from "./logs";
import Test from "./test";
import TransactionsList from "./transactions";
import { App, Integration as IntegrationType } from "/app/src/models";
import Filters from "./settings/historyFilters";
import { useQuery } from "@tanstack/react-query";
import { useNumberId } from "/app/src/hooks/useNumberId";
import { useMemo } from "react";

/**
 * Component for Data Push integration
 * @param app: Data Push app
 */
export default function Integration({ app }: { app: App }) {
  const { integrationId } = useNumberId();

  const { data: integration } = useQuery({
    queryKey: ["integration", integrationId],
    queryFn: () => {
      return integrationService.getSingle(integrationId);
    },
    enabled: Boolean(integrationId),
    initialData: { integration: {} },
    select: (data: { integration: IntegrationType }) => {
      return data.integration;
    },
  });

  const links = useMemo(() => {
    const baseLinks = [
      {
        path: `/apps/${app.id}/integrations/${integration.id}`,
        label: "settings",
      },
      {
        path: `/apps/${app.id}/integrations/${integration.id}/mappings`,
        label: "mappings",
      },
      {
        path: `/apps/${app.id}/integrations/${integration.id}/logs`,
        label: "logs",
      },
      {
        path: `/apps/${app.id}/integrations/${integration.id}/manual`,
        label: "manually_send",
      },
      {
        path: `/apps/${app.id}/integrations/${integration.id}/failed_attempts`,
        label: "failed_attempts",
      },
    ];

    if (integration.type === "manual") {
      baseLinks.push({
        path: `/apps/${app.id}/integrations/${integration.id}/awaiting_approval`,
        label: "awaiting_approval",
      });
    }

    return baseLinks;
  }, [app.id, integration.id, integration.type]);

  return (
    <div className="integration">
      <Helmet>
        <title>{integration?.name}</title>
      </Helmet>

      <Row gutter={20}>
        <Col
          xs={{ span: 24, order: 2 }}
          sm={{ span: 24, order: 2 }}
          lg={{ span: 4, order: 1 }}
        >
          <SideMenuBox links={links} />
        </Col>
        <Col
          xs={{ span: 24, order: 1 }}
          sm={{ span: 24, order: 1 }}
          lg={{ span: 20, order: 2 }}
        >
          {integration.id && (
            <Routes>
              <Route
                path="/"
                element={
                  <Settings integration={integration}>
                    <Filters integration={integration} />
                  </Settings>
                }
              />
              <Route
                path="mappings"
                element={<Mappings integration={integration} />}
              />

              <Route
                path="logs"
                element={<DataPushLogs integration={integration} />}
              />
              <Route
                path="manual"
                element={<Test integration={integration} />}
              />
              <Route
                path="failed_attempts"
                element={
                  <TransactionsList
                    integration={integration}
                    exportStateType={5}
                  />
                }
              />
              {integration?.type === "manual" && (
                <Route
                  path="awaiting_approval"
                  element={
                    <TransactionsList
                      integration={integration}
                      exportStateType={12}
                    />
                  }
                />
              )}
            </Routes>
          )}
        </Col>
      </Row>
    </div>
  );
}
