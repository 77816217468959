import React, { useState, useMemo, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import getOrderByQuery from "/app/src/helpers/table";

import { zoneService } from "/app/src/services";
import Search from "../search";
import { useTranslation } from "react-i18next";
import { Zone as ZoneType } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";

export default function Zones() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");

  const columnHelper = createColumnHelper<ZoneType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: () => t("translation:name"),
      }),
      columnHelper.accessor("typeDescription", {
        id: "description",
        cell: (info) => info.getValue(),
        header: () => t("translation:description"),
      }),
      columnHelper.accessor("pickTypeDescription", {
        id: "pickTypeDescription",
        cell: (info) => info.getValue(),
        header: () => t("translation:pick_type"),
      }),
      columnHelper.accessor("putTypeDescription", {
        id: "putTypeDescription",
        cell: (info) => info.getValue(),
        header: () => t("translation:put_type"),
      }),
    ],
    [columnHelper, t],
  );

  const gotToZone = useCallback(
    (id: number) => {
      navigate(`/explorer/zones/${id}`);
    },
    [navigate],
  );

  const { data: zones, isFetching } = useQuery({
    queryKey: ["zones", page, pageSize, searchString, sort],
    queryFn: () => {
      return zoneService.getAll(
        buildParams({
          limit: pageSize,
          page,
          search: searchString,
          orderby: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { zones: [] },
    select: (data: { zones: ZoneType[] }) => {
      return data.zones;
    },
  });

  const { data: zonesCount } = useQuery({
    queryKey: ["zonesCount", searchString],
    queryFn: () => {
      return zoneService.getCount(
        buildParams({ countOnly: true, search: searchString }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:zones")} - ItemPath</title>
      </Helmet>
      <div className="search box">
        <Search setSearchString={setSearchString} />
      </div>
      <Table
        loading={isFetching}
        rows={zones}
        tableColumns={columns}
        length={zonesCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage,
          setPageSize,
        }}
        enableRowSelection
        rowClicked={gotToZone}
        emptyText={`${t("translation:no")} ${t("translation:zones")} ${t(
          "translation:found",
        )}`}
      />
    </div>
  );
}
