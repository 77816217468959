import { Col, Row } from "antd";
import { Integration, iField } from "/app/src/models";
import { useTranslation } from "react-i18next";
import NextDivider from "/app/src/components/NextUi/Divider";
import Box from "/app/src/components/generic/components/box";
import Field from "./field";
import { orderBuilderLineFields as allFields } from "/app/src/constants/orderBuilderFields";
import NewField from "./newField";
import { useMemo } from "react";
/**
 * Renders the line fields component.
 *
 * @param integration - The integration object.
 * @param lineFields - The array of line fields.
 * @param createField - The function to create a new field.
 * @param updateField - The function to update an existing field.
 */
export default function LineFields({
  integration,
  lineFields,
  createField,
  updateField,
  deleteField,
}: {
  integration: Integration;
  lineFields: iField[];
  createField: (data: iField) => Promise<void>;
  updateField: (data: iField) => Promise<void>;
  deleteField: (data: iField) => Promise<iField>;
}) {
  const { t } = useTranslation();
  const requiredFields = useMemo(() => {
    const fields = [{ label: "Material Name", value: "materialName" }];
    if (integration.token !== "Count") {
      fields.push({ label: "Quantity", value: "quantity" });
    }
    return fields;
  }, [integration.token]);
  const existingFields = lineFields.map((field) => field.name);
  const missingRequiredFields = requiredFields.filter(
    (requiredField) => !existingFields.includes(requiredField.value),
  );

  const fieldsToRender = useMemo(() => {
    return [
      ...lineFields.map((field) => {
        // Check if the field is in requiredFields
        const isRequired = requiredFields.some(
          (requiredField) => requiredField.value === field.name,
        );
        if (isRequired) {
          return {
            ...field,
            deletable: false,
          };
        }
        return { ...field, deletable: true };
      }),
      ...missingRequiredFields.map((field) => ({
        name: field.value,
        label: field.label,
        required: true,
      })),
    ];
  }, [lineFields, missingRequiredFields, requiredFields]);

  const fieldsNotInRender = useMemo(() => {
    return allFields.filter(
      (field) =>
        !fieldsToRender.some(
          (renderedField) => renderedField.name === field.value,
        ),
    );
  }, [fieldsToRender]);
  return (
    <Box>
      <Row className="pb-3">
        <Col className="text-lg" span={8}>
          {t("translation:order_lines")}
        </Col>
      </Row>
      <NextDivider />
      {fieldsToRender.map((field) => {
        return (
          <Field
            key={field.name}
            iField={field}
            integration={integration}
            createField={createField}
            updateField={updateField}
            level={"line"}
            deleteField={deleteField}
          />
        );
      })}
      <NewField
        fields={fieldsNotInRender}
        createField={createField}
        level={"line"}
      />
    </Box>
  );
}
