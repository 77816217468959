import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "/app/src/components/generic/components/box";
interface link {
  path: string;
  label: string;
}

/**
 * @param {array} links - The links to render in the menu
 * @returns {JSX.Element} - The menu component
 * @description - This component renders a menu
 */
export default function SideMenuBox({ links }: { links: link[] }) {
  const { t } = useTranslation();

  return (
    <div className="sideMenu">
      <Box>
        <ul>
          {links.map((link, linkIndex) => (
            <li key={linkIndex}>
              <NavLink to={link.path} end={linkIndex === 0}>
                {t(`translation:${link.label}`, link.label)}
              </NavLink>
            </li>
          ))}
        </ul>
      </Box>
    </div>
  );
}
