import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, Checkbox } from "formik-antd";
import { Integration } from "/app/src/models";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useSetting from "/app/src/components/settings/setting";
import { convertBooleanToString } from "/app/src/helpers";

interface FormValues {
  value: boolean | undefined;
}

/**
 * Component to display the manual sending setting for a single Data Pull Integration
 */
export default function EnableManual({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();
  const { settingValue, createUpdateSetting } = useSetting({
    integrationId: integration.id,
    type: "enableManual",
  });

  const onSubmit = useCallback(
    async (values: FormValues) => {
      let value: string | boolean = values.value;
      value = convertBooleanToString(values.value);
      return await createUpdateSetting({
        value,
        type: "enableManual",
        integrationId: integration.id,
      });
    },
    [createUpdateSetting, integration.id],
  );
  const labelContent = useMemo(
    () => <>{t("translation:enable_manual_pull")}</>,
    [t],
  );
  const form: (props: FormikProps<FormValues>) => JSX.Element = useCallback(
    ({ isSubmitting, dirty }) => (
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={20}>
            <Form.Item name="value" label={labelContent}>
              <Checkbox name="value" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <SubmitButton
              type="primary"
              size="large"
              block
              disabled={!dirty || isSubmitting}
              style={{ marginTop: "30px" }}
            >
              {t("translation:save")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [labelContent, t],
  );
  return (
    <Formik
      initialValues={
        settingValue && settingValue === "True"
          ? { value: true }
          : { value: false }
      }
      onSubmit={onSubmit}
      component={form}
      enableReinitialize
    />
  );
}
