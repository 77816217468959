import { useCallback } from "react";
import { ReportColumn, Report as ReportType } from "/app/src/models";
import { useQueryClient } from "@tanstack/react-query";
import { reportService } from "/app/src/services";
import { Formik, FormikProps } from "formik";
import { Col, Row } from "antd";
import { Form, Select, SubmitButton } from "formik-antd";
import { useTranslation } from "react-i18next";

interface FormValues {
  groupColumnId: number | undefined;
  subGroupColumnId: number | undefined;
  finalGroupColumnId: number | undefined;
}

/**
 * Component to set the grouping columns for the report
 */
export default function GroupingColumns({
  report,
  columns,
}: {
  report: ReportType;
  columns: ReportColumn[];
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const onSubmitHandler = useCallback(
    (values: FormValues) => {
      return reportService
        .updateSingle(report.id, {
          groupColumnId: values.groupColumnId ? values.groupColumnId : null,
          subGroupColumnId: values.subGroupColumnId
            ? values.subGroupColumnId
            : null,
          finalGroupColumnId: values.finalGroupColumnId
            ? values.finalGroupColumnId
            : null,
        })
        .then(() => {
          queryClient.setQueryData(["report", report.id], {
            report: {
              ...report,
              groupColumnId: values.groupColumnId,
              subGroupColumnId: values.subGroupColumnId,
              finalGroupColumnId: values.finalGroupColumnId,
            },
          });
        });
    },
    [report, queryClient],
  );

  const columnForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ values, isSubmitting, dirty }) => (
        <Form>
          <Row gutter={16}>
            <Col span={7}>
              <Form.Item name="groupColumnId" hasFeedback={false}>
                <Select
                  size="large"
                  name="groupColumnId"
                  placeholder={t("translation:select_group_column")}
                  allowClear
                >
                  {columns
                    .filter(
                      (column) =>
                        column.id !== values.subGroupColumnId &&
                        column.id !== values.finalGroupColumnId &&
                        column.type !== "count",
                    )
                    .map((column) => (
                      <Select.Option key={column.id} value={column.id}>
                        {column.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name="subGroupColumnId" hasFeedback={false}>
                <Select
                  size="large"
                  name="subGroupColumnId"
                  placeholder={t("translation:select_sub_group_column")}
                  allowClear
                >
                  {columns
                    .filter(
                      (column) =>
                        column.id !== values.groupColumnId &&
                        column.id !== values.finalGroupColumnId &&
                        column.type !== "count",
                    )
                    .map((column) => (
                      <Select.Option key={column.id} value={column.id}>
                        {column.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name="finalGroupColumnId" hasFeedback={false}>
                <Select
                  size="large"
                  name="finalGroupColumnId"
                  placeholder={t("translation:select_final_group_column")}
                  allowClear
                >
                  {columns
                    .filter(
                      (column) =>
                        column.id !== values.groupColumnId &&
                        column.id !== values.subGroupColumnId &&
                        column.type !== "count",
                    )
                    .map((column) => (
                      <Select.Option key={column.id} value={column.id}>
                        {column.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || isSubmitting}
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [columns, t],
    );

  return (
    <div className="groupingColumns">
      <h2>{t("translation:column_grouping")}</h2>
      <Formik
        component={columnForm}
        enableReinitialize
        initialValues={{
          groupColumnId: report.groupColumnId,
          subGroupColumnId: report.subGroupColumnId,
          finalGroupColumnId: report.finalGroupColumnId,
        }}
        onSubmit={onSubmitHandler}
      />
    </div>
  );
}
