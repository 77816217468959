import { useCallback } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, Input, Select } from "formik-antd";
import { useNavigate } from "react-router-dom";
import Box from "/app/src/components/generic/components/box";
import { integrationService, reportService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { App, Integration } from "/app/src/models";
import { buildParams, simpleSchemaBuilder } from "/app/src/helpers";
import { useMutation, useQuery } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";

interface FormValues {
  name: string;
  number: string;
  token: string;
  reportId: number | undefined;
}

export default function NewIntegration({
  app,
  setToggled,
}: {
  app: App;
  setToggled: () => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  /**
   * Format form values to match the integration model
   * @param values
   * @param app
   */
  function formatForm(values: FormValues, app: App): Integration {
    return {
      name: values.name,
      appId: app.id,
      number: values.number,
      token: values.token,
      reportId: values.reportId,
    };
  }

  const { mutateAsync: addIntegration } = useMutation({
    mutationFn: (integration: Integration) => {
      return integrationService
        .createSingle(integration)
        .then(handlePromiseError);
    },
  });

  const addIntegrationHandler = useCallback(
    async (values: FormValues) => {
      const formattedForm = formatForm(values, app);
      await addIntegration(formattedForm).then((response) => {
        navigate(`/apps/${app.id}/integrations/${response.integration.id}`);
        setToggled();
      });
    },
    [addIntegration, app, navigate, setToggled],
  );

  const { data: reports } = useQuery({
    queryKey: [
      "reports",
      "[or]LocationContentBreakdown;LocationContent;History;Material",
    ],
    queryFn: () =>
      reportService.getAll(
        buildParams({
          baseTable:
            "[or]LocationContentBreakdown;LocationContent;History;Material",
        }),
      ),
    initialData: { reports: [] },
    select: (data) => data.reports,
  });

  const newIntegrationForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isSubmitting }) => (
        <Form layout="vertical">
          <Row justify="start" gutter={16}>
            <Col span={6}>
              <Form.Item
                name="name"
                hasFeedback={false}
                label={t("translation:name")}
              >
                <Input
                  name="name"
                  placeholder={t("translation:enter_name")}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="number"
                label={t("translation:order_builder_creation_type")}
                hasFeedback={false}
              >
                <Select name="number" size="large">
                  <Select.Option value="manual">
                    {t("translation:manual")}
                  </Select.Option>
                  <Select.Option value="automatic">
                    {t("translation:automatic")}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="token"
                label={t("translation:order_creation_type")}
                hasFeedback={false}
              >
                <Select
                  name="token"
                  size="large"
                  placeholder={t("translation:select_direction_type")}
                >
                  <Select.Option value="Pick">
                    {t("translation:pick")}
                  </Select.Option>
                  <Select.Option value="Put">
                    {t("translation:put")}
                  </Select.Option>
                  <Select.Option value="Count">
                    {t("translation:count")}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="reportId"
                label={t("translation:report_build_form")}
                hasFeedback={false}
              >
                <Select
                  name="reportId"
                  placeholder={t("translation:select_report")}
                  size="large"
                  showSearch
                  optionFilterProp="label"
                  options={reports.map((c) => ({
                    value: c.id,
                    label: c.name,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <SubmitButton
                style={{ marginTop: "30px" }}
                type="primary"
                size="large"
                block
                disabled={!dirty || isSubmitting}
              >
                {t("translation:create")} {t("translation:integration")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [reports, t],
    );
  return (
    <Box>
      <div className="newIntegration">
        <Formik
          component={newIntegrationForm}
          initialValues={{
            name: "",
            number: "automatic",
            token: undefined,
            reportId: undefined,
          }}
          validationSchema={simpleSchemaBuilder([
            { name: "name", type: "string", required: true },
            { name: "number", type: "string", required: true },
            { name: "token", type: "string", required: true },
            { name: "reportId", type: "number", required: true },
          ])}
          onSubmit={addIntegrationHandler}
          enableReinitialize
        />
      </div>
    </Box>
  );
}
