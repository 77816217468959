import { Col, Row } from "antd";
import { Mapping, Setting } from "/app/src/models";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Form, Select, SubmitButton } from "formik-antd";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchFilter } from "/app/src/components/generic/components/searchFilter";
import { simpleSchemaBuilder } from "/app/src/helpers";
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import { ValueField } from "./valueField";
import NextButton from "/app/src/components/NextUi/Button";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";

/**
 * Finds the operand at the start of a given string.
 * @param {string} str - The string to process and find the operand from.
 * @returns {string} The operand found at the start of the string; otherwise, returns "[eq]".
 */
function findOperand(str: string) {
  const regex = /^(\[eq]|\[not]|\[gt]|\[lt]|\[gte]|\[lte]|\[or]|\[nor])/;
  const match = str.match(regex);
  if (match) {
    return match[0];
  } else {
    return "[eq]"; // Default return
  }
}

/**
 * Removes a specific set of operands (eq, not, gt, lt, gte, lte, or, nor) from the start of a given string if present.
 * @param {string} str - The string to process and remove the operand from.
 * @returns {string} The processed string with the initial operand removed, if it was present; otherwise, returns the original string.
 */
function removeOperand(str) {
  const regex = /^(?:\[eq]|\[not]|\[gt]|\[lt]|\[gte]|\[lte]|\[or]|\[nor])(.+)/;
  const match = str.match(regex);
  if (match) {
    return match[1];
  } else {
    return str;
  }
}

interface FormValues {
  name?: string;
  value?: string;
  operand?: string;
}
/**
 *
 * @param param0 filter, updateSetting, deleteSetting, mappings
 * @returns List of filters
 */
export default function Filter({
  filter,
  updateSetting,
  deleteSetting,
  mappings,
}: {
  filter: Setting;
  updateSetting: (setting: Setting) => Promise<void>;
  deleteSetting: UseMutateAsyncFunction<
    { setting: { id: number } },
    unknown,
    number,
    unknown
  >;
  mappings: Mapping[];
}) {
  const { t } = useTranslation();
  const getMappingType = useCallback(
    (id: number) => {
      const mapping = mappings.find((mapping) => mapping.id === id);
      return mapping?.dataType;
    },
    [mappings],
  );
  const [mappingType, setMappingType] = useState<string>(
    getMappingType(Number(filter.name)),
  );

  const mapMappings = useCallback((mappings: Mapping) => {
    return (
      <Select.Option key={mappings.id} value={String(mappings.id)}>
        {mappings.key}
      </Select.Option>
    );
  }, []);

  const onSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      await updateSetting({
        name: values.name,
        value: `${values.operand}${values.value}`,
        id: filter.id,
        integrationId: filter.integrationId,
        type: "sqlFilter",
      }).then(() => {
        actions.resetForm();
      });
    },
    [filter.id, filter.integrationId, updateSetting],
  );
  const handleSelectChange = useCallback(
    (value: string) => {
      //find the mapping with the id
      setMappingType(getMappingType(Number(value)));
    },
    [getMappingType],
  );

  const deleteSettingFn = useCallback(() => {
    deleteSetting(filter.id);
  }, [deleteSetting, filter.id]);

  const filterForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid, isSubmitting }) => (
        <Form layout="vertical">
          <Row justify="start" gutter={16} className="flex items-center">
            <Col span={8}>
              <Form.Item name="name" label="Key" hasFeedback={false}>
                <SearchFilter
                  name="name"
                  placeholder={t("translation:select_mapping")}
                  mapOptionsFn={mapMappings}
                  list={mappings}
                  sort
                  onChange={handleSelectChange}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="operand" label=" " hasFeedback={false}>
                <Select name="operand" style={{ width: "100%" }} size="large">
                  {[
                    { value: "[eq]", label: "equals" },
                    { value: "[not]", label: "does not equal" },
                    { value: "[gt]", label: "greater than" },
                    { value: "[lt]", label: "less than" },
                    { value: "[gte]", label: "greater than or equal to" },
                    { value: "[lte]", label: "less than or equal to" },
                    { value: "[or]", label: "or" },
                    { value: "[nor]", label: "nor" },
                  ].map((operand) => (
                    <Select.Option key={operand.value} value={operand.value}>
                      {operand.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="value" label="Value" hasFeedback={false}>
                <ValueField mappingType={mappingType} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <SubmitButton
                className="mt-[5px]"
                disabled={!dirty || isSubmitting || !isValid}
                loading={isSubmitting}
                type="primary"
                size="large"
                block
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
            <Col span={1}>
              <NextButton
                onClick={deleteSettingFn}
                size="md"
                variant="bordered"
                color="default"
                isIconOnly
                className="hover:border-red-500  bg-white mt-[5px]"
              >
                <IconBuilder size={18} color="#e00000" icon="Delete" />
              </NextButton>
            </Col>
          </Row>
        </Form>
      ),
      [
        deleteSettingFn,
        handleSelectChange,
        mapMappings,
        mappingType,
        mappings,
        t,
      ],
    );
  return (
    <div className="filter inList">
      <Formik
        component={filterForm}
        enableReinitialize
        initialValues={{
          operand: findOperand(filter.value),
          name: filter.name,
          value: removeOperand(filter.value),
        }}
        validationSchema={simpleSchemaBuilder([
          { name: "name", type: "string", required: true },
          { name: "value", type: "string", required: true },
        ])}
        onSubmit={onSubmit}
      />
    </div>
  );
}
