import { Modal, Button } from "antd";
import { useCallback } from "react";
import { settingService } from "/app/src/services";
import { useTranslation } from "react-i18next";
const { confirm } = Modal;

export default function DeleteForm(props) {
  const { t } = useTranslation();

  /**
   * Show a confirm modal to delete a channel
   */
  function ShowConfirm(okText, cancelText, titleText, settingId, refresh) {
    confirm({
      okText,
      okButtonProps: { type: "danger" },
      cancelText,
      title: titleText,
      onOk() {
        //refresh the list of channels if channel successfully removed
        settingService.deleteSingle(settingId).then((response) => {
          if (response.deleted === "true") {
            refresh();
          }
        });
      },
    });
  }

  const deleteForm = useCallback(() => {
    ShowConfirm(
      t("delete"),
      t("do_not_delete"),
      t("confirm_delete_channel"),
      props.settingId,
      props.refresh,
    );
  }, [props.settingId, props.refresh, t]);
  return (
    <Button type="danger" className="btn-delete" onClick={deleteForm}>
      {t("delete")} {t("channel")}
    </Button>
  );
}
