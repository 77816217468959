import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import DynamicFields from "/app/src/components/explorer/generic/dynamicFields";
import Descriptions from "/app/src/components/generic/components/descriptions";
import { orderLineService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { OrderLine as OrderLineType } from "/app/src/models";
import Box from "/app/src/components/generic/components/box";
import { useHandleNavigationError } from "/app/src/helpers/api";

export default function OrderLine() {
  const { t } = useTranslation();
  const { orderLineId } = useParams();
  const handleNavigationError = useHandleNavigationError();

  // Get the Single OrderLine from URL
  const { data: orderLine } = useQuery({
    queryKey: ["orderLine", orderLineId],
    queryFn: () => {
      return orderLineService.getSingle(orderLineId).catch(() => {
        handleNavigationError();
      });
    },
    initialData: { order_line: {} },
    retry: false,
    select: (data: { order_line: OrderLineType }) => {
      return data.order_line;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{`Order Line ${orderLine?.number} - ItemPath`}</title>
      </Helmet>
      <Box>
        <h1>{orderLine?.number}</h1>

        <h4>{t("translation:information")}</h4>
        <Descriptions
          items={[
            {
              label: t("translation:creation_date"),
              value: orderLine?.modifiedDate,
            },
            {
              label: t("translation:status"),
              value: orderLine?.status,
            },
            {
              label: t("translation:direction_type"),
              value: orderLine?.directionType,
            },
            {
              label: t("translation:quantity"),
              value: orderLine?.quantity,
            },
            {
              label: t("translation:quantity_deviated"),
              value: orderLine?.quantityDeviated,
            },
            { label: t("translation:order_id"), value: orderLine?.orderId },
            {
              label: t("translation:material_name"),
              value: orderLine?.materialName,
            },
          ]}
          bordered
        />

        <h4>{t("translation:information_fields")}</h4>
        <Descriptions
          items={[
            { label: t("translation:info1"), value: orderLine?.Info1 },
            { label: t("translation:info2"), value: orderLine?.Info2 },
            { label: t("translation:info3"), value: orderLine?.Info3 },
            { label: t("translation:info4"), value: orderLine?.Info4 },
            { label: t("translation:info5"), value: orderLine?.Info5 },
          ]}
          bordered
        />

        <DynamicFields object={orderLine} baseTable="Masterorderline" />
      </Box>
    </div>
  );
}
