import { Col, Input as AntdInput } from "antd";
import { Form, Input } from "formik-antd";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

import { useCallback, useEffect, useState } from "react";

interface FormValues {
  key: string;
  value: string;
}
/**
 * Fields for the status confirmation type
 */
export default function StatusFieldType({ title }: { title: string }) {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext<FormValues>();
  const [successValue, setSuccessValue] = useState<string>("");
  const [failureValue, setFailureValue] = useState<string>("");

  useEffect(() => {
    if (values.value) {
      const [success, failure] = values.value.split(",");
      setSuccessValue(success);
      setFailureValue(failure);
    }
  }, [values.value]);

  const handleSuccessChange = useCallback(
    (e) => {
      setFieldValue("value", `${e.target.value},${failureValue}`);
    },
    [setFieldValue, failureValue],
  );

  const handleFailureChange = useCallback(
    (e) => {
      setFieldValue("value", `${successValue},${e.target.value}`);
    },
    [setFieldValue, successValue],
  );

  return (
    <>
      <Col span={2}>{title}:</Col>
      <Col span={5}>
        <Form.Item name="key" hasFeedback={false}>
          <Input
            size="large"
            name="key"
            placeholder={t("translation:enter_key")}
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        <div className="arrowRight" />
      </Col>
      <Col span={5}>
        <AntdInput
          addonBefore="Success:"
          size="large"
          placeholder={t("translation:success")}
          onChange={handleSuccessChange}
          value={successValue}
        />
      </Col>
      <Col span={5}>
        <AntdInput
          size="large"
          addonBefore="Error:"
          placeholder={t("translation:error")}
          onChange={handleFailureChange}
          value={failureValue}
        />
      </Col>
    </>
  );
}
