import {
  connectionSchema,
  connectionSchemaNoPwd,
  connectionSchemaNoAuth,
} from "/app/src/schemas";
import { Connection } from "../models";
import { object, string, number, boolean, date, array, mixed } from "yup";
import i18next from "/app/src/locales/config";
import isIpAddress from "/app/src/helpers/validators";

export function getConnectionSchema(connection: Connection) {
  if (
    ["No Auth", "OAuth1", "Basic", "OAuth2"].includes(
      connection?.authentication,
    )
  ) {
    return connectionSchemaNoAuth;
  }
  if (connection?.passwordExists) {
    return connectionSchemaNoPwd;
  } else {
    return connectionSchema;
  }
}

interface Schema {
  name: string;
  required: boolean;
  type: "string" | "number" | "boolean" | "date" | "array" | "url" | "mixed";
}

/**
Dynamic builder for simple yup schemas. Takes a list of simple field properties and returns a yup schema
@param schemaProperties List of field properties to be added to the schema
*/
export function simpleSchemaBuilder(schemaProperties: Schema[]) {
  const fieldMap = {
    string: string(),
    number: number().typeError(i18next.t("translation:number_is_required")),
    mixed: mixed(),
    boolean: boolean(),
    date: date(),
    array: array(),
    url: string().test(
      "translation:test-name",
      i18next.t("translation:valid_address"),
      (value) => {
        return isIpAddress(value);
      },
    ),
  };
  let yupSchema = object().shape({});
  schemaProperties.forEach((field) => {
    yupSchema = yupSchema.concat(
      object().shape({
        [field.name]: field.required
          ? fieldMap[field.type].required(i18next.t("translation:required"))
          : fieldMap[field.type],
      }),
    );
  });
  return yupSchema;
}
