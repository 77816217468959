import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { batchService } from "/app/src/services";
import getOrderByQuery from "/app/src/helpers/table";
import { PowerPickTimeZoneToLocal } from "/app/src/components/generic/formatting/dateTime";
import Search from "../search";
import { useTranslation } from "react-i18next";
import { Batch as BatchType } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";

export default function Batches() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<BatchType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: t("translation:name"),
      }),
      columnHelper.accessor("priority", {
        id: "priority",
        cell: (info) => info.getValue(),
        header: t("translation:priority"),
      }),
      columnHelper.accessor("creationDate", {
        id: "creationDate",
        cell: (info) => {
          return (
            <PowerPickTimeZoneToLocal
              date={info.getValue()}
              format={"YYYY-MM-DD HH:mm:ss"}
            />
          );
        },
        header: t("translation:creation_date"),
      }),
    ],
    [columnHelper, t],
  );
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");

  const goToBatch = useCallback(
    (batchId: number) => {
      navigate(`/explorer/batches/${batchId}`);
    },
    [navigate],
  );

  //Get the batches
  const { data: batches, isFetching } = useQuery({
    queryKey: ["batches", page, pageSize, sort, searchString],
    queryFn: () => {
      return batchService.getAll(
        buildParams({
          limit: pageSize,
          page,
          search: searchString,
          orderby: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { batches: [] },
    select: (data: { batches: BatchType[] }) => {
      return data.batches;
    },
  });

  //Get the number of batches
  const { data: batchesCount } = useQuery({
    queryKey: ["batchesCount", searchString],
    queryFn: () => {
      return batchService.getCount(buildParams({ search: searchString }));
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:batches")} - ItemPath</title>
      </Helmet>
      <div className="search box">
        <Search setSearchString={setSearchString} />
      </div>
      <Table
        loading={isFetching}
        rows={batches}
        tableColumns={columns}
        length={batchesCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage,
          setPageSize,
        }}
        enableRowSelection
        rowClicked={goToBatch}
        emptyText={t("translation:no_batches")}
      />
    </div>
  );
}
