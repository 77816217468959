import { useCallback } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, Input } from "formik-antd";
import { useNavigate } from "react-router-dom";
import { Workflow } from "/app/src/models/workflow";
import { workflowService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { simpleSchemaBuilder } from "/app/src/helpers";
import { handlePromiseError } from "/app/src/helpers/api";
import Box from "/app/src/components/generic/components/box";

interface FormValues {
  name: string;
}

export default function NewWorkflow() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  /**
   * Format form values to match the workflow model
   * @param values FormValues
   */
  function formatForm(values: FormValues) {
    return {
      name: values.name,
    };
  }

  const newWorkflowForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isSubmitting }) => (
        <Form layout="vertical">
          <Row justify="start" gutter={16}>
            <Col span={20}>
              <Form.Item
                name="name"
                label={t("translation:workflow_name")}
                hasFeedback={false}
              >
                <Input
                  name="name"
                  placeholder={t("translation:enter_name")}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || isSubmitting}
              >
                {t("translation:create")} {t("translation:workflow")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [t],
    );

  const { mutateAsync: createWorkflow } = useMutation({
    mutationFn: (values: Omit<Workflow, "trigger">) => {
      return workflowService.createSingle(values).then(handlePromiseError);
    },
  });

  const onSubmitWorkflow = useCallback(
    async (value: { name: string }) => {
      await createWorkflow(formatForm(value)).then((response) => {
        navigate(`/workflows/${response.workflow.id}`);
      });
    },
    [createWorkflow, navigate],
  );

  return (
    <Box>
      <div className="newWorkflow">
        <Formik
          component={newWorkflowForm}
          initialValues={{
            name: "",
          }}
          validationSchema={simpleSchemaBuilder([
            { name: "name", type: "string", required: true },
          ])}
          onSubmit={onSubmitWorkflow}
        />
      </div>
    </Box>
  );
}
