import React, { useState, useEffect, useCallback } from "react";
import { reportColumnService } from "/app/src/services";
import { ReportColumn } from "./reportColumn";
import { ReportColumn as ReportColumnType } from "/app/src/models";
import { useAccessContext } from "/app/src/contexts/hooks/useAccessContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";

export default function ColumnArea({
  columns,
  reportId,
}: {
  columns: ReportColumnType[];
  reportId: number;
}) {
  const { canEdit } = useAccessContext();

  const queryClient = useQueryClient();
  const [colPositions, setColPositions] = useState<number[]>(
    columns
      .sort((a, b) => parseInt(a.position) - parseInt(b.position))
      .map((col) => {
        return col.id;
      }),
  );

  useEffect(() => {
    setColPositions(
      columns
        .sort((a, b) => parseInt(a.position) - parseInt(b.position))
        .map((col) => {
          return col.id;
        }),
    );
  }, [columns]);

  const { mutateAsync: updateColumnsPositions } = useMutation({
    mutationFn: async (updatedCols: ReportColumnType[]) => {
      await Promise.all(
        updatedCols.map(async (col) => {
          await reportColumnService.updateSingle(col.id, {
            position: col.position,
          });
        }),
      );

      return updatedCols;
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["reportColumns", reportId],
        (oldData: { report_columns: ReportColumnType[] }) => {
          const updatedPositions = response.reduce((acc, col) => {
            acc[col.id] = col.position;
            return acc;
          }, {});

          return {
            report_columns: oldData.report_columns.map((col) => {
              if (updatedPositions[col.id] !== undefined) {
                return {
                  ...col,
                  position: updatedPositions[col.id],
                };
              }
              return col;
            }),
          };
        },
      );
    },
  });

  const { mutateAsync: updateColumn } = useMutation({
    mutationFn: (updatedCol: ReportColumnType) => {
      return reportColumnService
        .updateSingle(updatedCol.id, updatedCol)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["reportColumns", reportId],
        (oldData: { report_columns: ReportColumnType[] }) => {
          return {
            report_columns: oldData.report_columns.map((col) => {
              if (col.id === response.report_column.id) {
                return response.report_column;
              }
              return col;
            }),
          };
        },
      );
    },
  });

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (active.id === over.id) {
        return;
      }
      const activeIndex = colPositions.findIndex(
        (value) => value === active.id,
      );
      const overIndex = colPositions.findIndex((value) => value === over.id);
      const updatedArray = arrayMove(colPositions, activeIndex, overIndex);
      setColPositions(updatedArray);

      const updatedColumns = updatedArray.reduce((acc, id, index) => {
        if (id !== colPositions[index]) {
          const col = columns.find((col) => col.id === id);
          if (col) {
            acc.push({
              ...col,
              position: index.toString(),
            });
          }
        }
        return acc;
      }, []);
      updateColumnsPositions(updatedColumns);
    },
    [columns, updateColumnsPositions, colPositions],
  );

  return (
    <div className="layout">
      <DndContext
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={colPositions}>
          {colPositions.map((id) => {
            return columns.find((col) => col.id === id) ? (
              <ReportColumn
                key={id}
                column={columns.find((col) => col.id === id)}
                updateColumn={updateColumn}
                canEdit={canEdit}
              />
            ) : null;
          })}
        </SortableContext>
      </DndContext>
    </div>
  );
}
