import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import {
  Announcements,
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragOverlay,
  DragMoveEvent,
  DragEndEvent,
  DragOverEvent,
  MeasuringStrategy,
  DropAnimation,
  Modifier,
  defaultDropAnimation,
  UniqueIdentifier,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import {
  buildTree,
  flattenTree,
  getProjection,
  getChildCount,
  removeItem,
  removeChildrenOf,
  setProperty,
} from "./utilities";
import type { FlattenedItem, SensorContext, TreeItems } from "./types";
import { sortableTreeKeyboardCoordinates } from "./keyboardCoordinates";
import { SortableTreeItem } from "./components";
import { CSS } from "@dnd-kit/utilities";
import { debounce } from "lodash";

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const dropAnimationConfig: DropAnimation = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5,
        }),
      },
    ];
  },
  easing: "ease-out",
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing,
    });
  },
};

/**
 * Modifier function to adjust the translation of an element.
 * Specifically, it decreases the y-coordinate of the transform by 25 units.
 *
 * @param {Object} transform - The transform object containing x and y coordinates.
 * @returns {Object} The modified transform object with the adjusted y-coordinate.
 */
const adjustTranslate: Modifier = ({ transform }) => {
  return {
    ...transform,
    y: transform.y - 25,
  };
};

interface Props {
  collapsible?: boolean;
  defaultItems?: TreeItems;
  indentationWidth?: number;
  indicator?: boolean;
  removable?: boolean;
  onSuccessfulDrop: (updateItems: FlattenedItem[]) => void;
}

/**
 * SortableTree component provides a sortable tree structure with drag-and-drop functionality.
 *
 * @param {Object} props - The properties object.
 * @param {boolean} props.collapsible - Indicates if tree items are collapsible.
 * @param {Array} [props.defaultItems=[]] - The default items to be displayed in the tree.
 * @param {boolean} [props.indicator=false] - Indicates if an indicator should be shown during drag-and-drop.
 * @param {number} [props.indentationWidth=50] - The width of indentation for nested items.
 * @param {boolean} props.removable - Indicates if tree items are removable.
 * @param {Function} props.onSuccessfulDrop - Callback function to be called after a successful drop.
 *
 * @returns {JSX.Element} The rendered SortableTree component.
 *
 * @example
 * <SortableTree
 *   collapsible={true}
 *   defaultItems={[{ id: 1, name: 'Item 1' }, { id: 2, name: 'Item 2' }]}
 *   indicator={true}
 *   indentationWidth={30}
 *   removable={true}
 *   onSuccessfulDrop={(items) => console.log('Dropped items:', items)}
 * />
 */
export function SortableTree({
  collapsible,
  defaultItems = [],
  indicator = false,
  indentationWidth = 50,
  removable,
  onSuccessfulDrop,
}: Props) {
  const [items, setItems] = useState(() => defaultItems);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [currentPosition, setCurrentPosition] = useState<{
    parentId: UniqueIdentifier | null;
    overId: UniqueIdentifier;
  } | null>(null);
  useEffect(() => {
    setItems(defaultItems);
  }, [defaultItems]);
  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items);

    if (activeId) {
      return removeChildrenOf(flattenedTree, [activeId]);
    } else {
      return flattenedTree;
    }
  }, [activeId, items]);
  const projected =
    activeId && overId
      ? getProjection(
          flattenedItems,
          activeId,
          overId,
          offsetLeft,
          indentationWidth,
        )
      : null;
  const sensorContext: SensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  });
  const [coordinateGetter] = useState(() =>
    sortableTreeKeyboardCoordinates(sensorContext, indicator, indentationWidth),
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    }),
  );

  const sortedIds = useMemo(
    () => flattenedItems.map(({ id }) => id),
    [flattenedItems],
  );
  const activeItem = activeId
    ? flattenedItems.find(({ id }) => id === activeId)
    : null;

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    };
  }, [flattenedItems, offsetLeft]);

  const handleDragStart = useCallback(
    ({ active: { id: activeId } }: DragStartEvent) => {
      setActiveId(activeId);
      setOverId(activeId);

      const activeItem = flattenedItems.find(({ id }) => id === activeId);

      if (activeItem) {
        setCurrentPosition({
          parentId: activeItem.parentId,
          overId: activeId,
        });
      }

      document.body.style.setProperty("cursor", "grabbing");
    },
    [flattenedItems],
  );

  const handleDragMove = useCallback(({ delta }: DragMoveEvent) => {
    setOffsetLeft(delta.x);
  }, []);

  const handleDragOver = useCallback(({ over }: DragOverEvent) => {
    setOverId(over?.id ?? null);
  }, []);
  const resetState = useCallback(() => {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    setCurrentPosition(null);

    document.body.style.setProperty("cursor", "");
  }, []);
  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      resetState();

      if (projected && over) {
        const { depth, parentId } = projected;
        const clonedItems: FlattenedItem[] = JSON.parse(
          JSON.stringify(flattenTree(items)),
        );
        const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
        const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
        const activeTreeItem = clonedItems[activeIndex];

        clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

        const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
        const newItems = buildTree(sortedItems);

        setItems(newItems);

        onSuccessfulDrop(sortedItems);
      }
    },
    [items, onSuccessfulDrop, projected, resetState],
  );

  const handleDragCancel = useCallback(() => {
    resetState();
  }, [resetState]);

  const handleRemove = useCallback(
    (id: UniqueIdentifier) => () => {
      setItems((items) => removeItem(items, id));
    },
    [],
  );

  const handleCollapse = useCallback(
    (id: UniqueIdentifier) => () => {
      setItems((items) =>
        setProperty(items, id, "collapsed", (value) => {
          return !value;
        }),
      );
    },
    [],
  );

  const getMovementAnnouncement = useCallback(
    (
      eventName: string,
      activeId: UniqueIdentifier,
      overId?: UniqueIdentifier,
    ) => {
      if (overId && projected) {
        if (eventName !== "onDragEnd") {
          if (
            currentPosition &&
            projected.parentId === currentPosition.parentId &&
            overId === currentPosition.overId
          ) {
            return null;
          } else {
            setCurrentPosition({
              parentId: projected.parentId,
              overId,
            });
          }
        }

        const clonedItems: FlattenedItem[] = JSON.parse(
          JSON.stringify(flattenTree(items)),
        );
        const overIndex = clonedItems.findIndex(({ id }) => id === overId);
        const activeIndex = clonedItems.findIndex(({ id }) => id === activeId);
        const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

        const previousItem = sortedItems[overIndex - 1];

        let announcement;
        const movedVerb = eventName === "onDragEnd" ? "dropped" : "moved";
        const nestedVerb = eventName === "onDragEnd" ? "dropped" : "nested";

        if (!previousItem) {
          const nextItem = sortedItems[overIndex + 1];
          if (!nextItem) {
            announcement = `${activeId} was ${movedVerb} at the end.`;
          } else {
            announcement = `${activeId} was ${movedVerb} before ${nextItem.id}.`;
          }
        } else {
          if (projected.depth > previousItem.depth) {
            announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`;
          } else {
            let previousSibling: FlattenedItem | undefined = previousItem;
            while (previousSibling && projected.depth < previousSibling.depth) {
              const parentId: UniqueIdentifier | null =
                previousSibling.parentId;
              previousSibling = sortedItems.find(({ id }) => id === parentId);
            }

            if (previousSibling) {
              announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`;
            }
          }
        }

        return announcement;
      }

      return null;
    },
    [currentPosition, items, projected],
  );
  const announcements: Announcements = {
    onDragStart({ active }) {
      return `Picked up ${active.id}.`;
    },
    onDragMove({ active, over }) {
      return getMovementAnnouncement("onDragMove", active.id, over?.id);
    },
    onDragOver({ active, over }) {
      return getMovementAnnouncement("onDragOver", active.id, over?.id);
    },
    onDragEnd({ active, over }) {
      return getMovementAnnouncement("onDragEnd", active.id, over?.id);
    },
    onDragCancel({ active }) {
      return `Moving was cancelled. ${active.id} was dropped in its original position.`;
    },
  };

  return (
    <DndContext
      accessibility={{ announcements }}
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={measuring}
      onDragStart={handleDragStart}
      onDragMove={debounce(handleDragMove, 10)}
      onDragOver={debounce(handleDragOver, 10)}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
        {flattenedItems.map(({ id, children, depth, mapping }) => (
          <SortableTreeItem
            key={id}
            id={id}
            value={mapping}
            depth={id === activeId && projected ? projected.depth : depth}
            indentationWidth={indentationWidth}
            indicator={indicator}
            collapsed={id === activeId ? true : false}
            onCollapse={
              collapsible && children.length ? handleCollapse(id) : undefined
            }
            onRemove={removable ? handleRemove(id) : undefined}
          />
        ))}
        {createPortal(
          <DragOverlay
            dropAnimation={dropAnimationConfig}
            modifiers={indicator ? [adjustTranslate] : undefined}
          >
            {activeId && activeItem ? (
              <SortableTreeItem
                id={activeId}
                depth={activeItem.depth}
                clone
                childCount={getChildCount(items, activeId) + 1}
                value={activeItem.mapping}
                indentationWidth={indentationWidth}
              />
            ) : null}
          </DragOverlay>,
          document.body,
        )}
      </SortableContext>
    </DndContext>
  );
}
