import { iLine, iOrder } from "/app/src/models";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { iLineService } from "/app/src/services";
import { useQuery } from "@tanstack/react-query";
import { buildParams } from "/app/src/helpers";
import Line from "./line";

/**
 * Displays the details of a line in an order created through the Order Builder App
 */
export default function Lines({ order }: { order: iOrder }) {
  const { t } = useTranslation();
  const { data: lines } = useQuery({
    queryKey: ["lines", { orderId: order.id }],
    queryFn: () => {
      return iLineService.getAll(buildParams({ orderId: order.id }));
    },
    initialData: { lines: [] },
    select: (data: { lines: iLine[] }) => {
      return data.lines;
    },
    enabled: order?.id !== undefined,
  });

  if (lines.length > 0) {
    return (
      <>
        <h2>{t("translation:lines")}</h2>
        <Row gutter={16}>
          {lines.map((line) => (
            <Col span={24} key={line.id}>
              <Line
                line={line}
                key={line.id}
                disabled={order.orderSent}
                integrationId={order.integrationId}
                onlyLine={lines.length === 1}
              />
            </Col>
          ))}
        </Row>
      </>
    );
  }
}
