import { Col } from "antd";
import { Form, Input, Select } from "formik-antd";
import { useTranslation } from "react-i18next";
import { Mapping } from "/app/src/models";

/**
 * Fields for the mapping confirmation type
 */
export default function MappingTypes({ mappings }: { mappings: Mapping[] }) {
  const { t } = useTranslation();
  return (
    <>
      <Col span={2}>{t("translation:mapping")}:</Col>
      <Col span={5}>
        <Form.Item name="key" hasFeedback={false}>
          <Input
            size="large"
            name="key"
            placeholder={t("translation:enter_key")}
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        <div className="arrowRight" />
      </Col>
      <Col span={10}>
        <Form.Item name="value" hasFeedback={false}>
          <Select name="value" size="large">
            {mappings.map((mapping) => (
              <Select.Option
                key={mapping.id}
                value={String(mapping.id)}
                label={mapping.key}
              >
                {mapping.key}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
}
