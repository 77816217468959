import {
  genericGetSingle,
  genericCreateSingle,
  genericGetAll,
  genericUpdateSingle,
  genericBulkDelete,
} from "../helpers/apiCall";
import { Task } from "../models";
import { Error } from "../types";

/**
 * Gets all tasks
 * @param params URLSearchParams
 * @returns Promise<{ tasks: Task[] }>
 */
function getAll(params: URLSearchParams = null): Promise<{ tasks: Task[] }> {
  return genericGetAll("tasks", params);
}

/**
 * Gets a single task
 * @param taskId number | string
 * @returns Promise<{ task: Task }>
 */
function getSingle(taskId: number | string): Promise<{ task: Task }> {
  return genericGetSingle("tasks", taskId);
}

/**
 * Create a new task
 * @param task { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ task: Task } | Error>
 */
function createSingle(task: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<{ task: Task } | Error> {
  return genericCreateSingle("tasks", task);
}

/**
 * Update a single task
 * @param id number | string
 * @param task Task
 * @returns Promise<{ task: Task } | Error | { retried: boolean }>
 */
function updateSingle(
  id: number | string,
  task: { [key: string]: string | number | boolean | Date | undefined },
): Promise<{ task: Task } | Error | { retried: boolean }> {
  return genericUpdateSingle("tasks", id, task);
}

/**
 * Gets the count of tasks
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("tasks", params);
}

/**
 * Deletes multiple tasks in bulk.
 *
 * @param params - The URLSearchParams object containing the parameters for the bulk delete operation.
 * @returns A Promise that resolves to an object with a success property indicating the success of the operation.
 */
function bulkDelete(params: URLSearchParams): Promise<{ deleted: number }> {
  return genericBulkDelete("tasks", params);
}

export const taskService = {
  getAll,
  getSingle,
  createSingle,
  updateSingle,
  getCount,
  bulkDelete,
};
