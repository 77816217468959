import { Col } from "antd";
import { Form, Input } from "formik-antd";
import { useTranslation } from "react-i18next";

/**
 * Fields for the text confirmation type
 */
export default function TextFieldType({
  title,
  valueDisabled = false,
  placeholder,
}: {
  title: string;
  valueDisabled?: boolean;
  placeholder?: string;
}) {
  const { t } = useTranslation();
  return (
    <>
      <Col span={2}>{title}:</Col>
      <Col span={5}>
        <Form.Item name="key" hasFeedback={false}>
          <Input
            size="large"
            name="key"
            placeholder={t("translation:enter_key")}
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        <div className="arrowRight" />
      </Col>
      <Col span={10}>
        <Form.Item name="value" hasFeedback={false}>
          <Input
            size="large"
            name="value"
            placeholder={placeholder}
            disabled={valueDisabled}
          />
        </Form.Item>
      </Col>
    </>
  );
}
