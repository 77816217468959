import { Formik, FormikHelpers, FormikProps } from "formik";
import { Connection } from "/app/src/models";
import { useCallback } from "react";
import { Form, Input, InputNumber, Select, SubmitButton } from "formik-antd";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { simpleSchemaBuilder } from "/app/src/helpers";
import useSetting from "../setting";
import { IconToolTip } from "/app/src/components/icons/IconBuilder";

interface FormValues {
  cookieDuration?: string;
  cookieDurationUnit?: string;
  login?: string;
  userKey?: string;
  passwordKey?: string;
  authLocation?: string;
  cookieKeys?: string[];
}

/**
 * Component to display the Cookie connection types.
 * Authentication settings are controlled in this component.
 */
export default function Cookie({ connection }: { connection: Connection }) {
  // tracks the duration of the cookie
  const {
    settingValue: cookieDuration,
    createUpdateSetting: createUpdateCookieDuration,
  } = useSetting({
    connectionId: connection.id,
    type: "cookieDuration",
  });
  // what key to use when passing user value
  const { settingValue: userKey, createUpdateSetting: createUpdateUserKey } =
    useSetting({
      connectionId: connection.id,
      type: "userKey",
    });
  // what key to use when passing password value
  const {
    settingValue: passwordKey,
    createUpdateSetting: createUpdatePasswordKey,
  } = useSetting({
    connectionId: connection.id,
    type: "passwordKey",
  });
  // URL to login to the service
  const { settingValue: login, createUpdateSetting: createUpdateLogin } =
    useSetting({
      connectionId: connection.id,
      type: "login",
    });
  // where the auth information should be placed
  const {
    settingValue: authLocation,
    createUpdateSetting: createUpdateAuthLocation,
  } = useSetting({
    connectionId: connection.id,
    type: "authLocation",
  });
  // keys to specify which cookies to use
  const {
    settingValue: cookieKeys,
    createUpdateSetting: createUpdateCookieKeys,
  } = useSetting({
    connectionId: connection.id,
    type: "cookieKeys",
  });

  const updateSettings = useCallback(
    async (values: FormValues) => {
      /**
       * Updates a specified field if the new value is different from the current value.
       * Constructs an update payload and calls the provided update function with it.
       *
       * @param type - The type of the field
       * @param currentValue - The current value of the field.
       * @param newValue - The new value to update the field to.
       * @param updateFunction - The function to call with the update payload.
       * @returns A promise that resolves when the update function completes.
       */
      const updateField = async (
        type: string,
        currentValue: string | undefined,
        newValue: string | undefined,
        updateFunction: (values) => Promise<void>,
      ) => {
        if (currentValue !== newValue) {
          const updatePayload = {
            type,
            connectionId: connection.id,
            value: newValue,
          };
          await updateFunction(updatePayload);
        }
      };

      // Handle all updates in one place
      await Promise.all([
        updateField(
          "cookieDuration",
          cookieDuration,
          `${values.cookieDuration}${values.cookieDurationUnit}`,
          createUpdateCookieDuration,
        ),
        updateField("userKey", userKey, values.userKey, createUpdateUserKey),
        updateField(
          "passwordKey",
          passwordKey,
          values.passwordKey,
          createUpdatePasswordKey,
        ),
        updateField("login", login, values.login, createUpdateLogin),
        updateField(
          "authLocation",
          authLocation,
          values.authLocation,
          createUpdateAuthLocation,
        ),
        updateField(
          "cookieKeys",
          cookieKeys,
          values.cookieKeys.join(","),
          createUpdateCookieKeys,
        ),
      ]);

      return Promise.resolve();
    },
    [
      cookieDuration,
      createUpdateCookieDuration,
      userKey,
      createUpdateUserKey,
      passwordKey,
      createUpdatePasswordKey,
      login,
      createUpdateLogin,
      authLocation,
      createUpdateAuthLocation,
      cookieKeys,
      createUpdateCookieKeys,
      connection.id,
    ],
  );
  const handleSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      await updateSettings(values).then(() => {
        actions.resetForm();
      });
    },
    [updateSettings],
  );
  const { t } = useTranslation();
  const oauthForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form layout="vertical">
          <h3>{t("translation:cookie_settings")}</h3>
          <Row justify="start" gutter={16}>
            <Col span={18}>
              <Form.Item name="login" label={t("translation:login")}>
                <Input name="login" size="large" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="authLocation"
                label={
                  <>
                    {t("translation:auth_location")}
                    <IconToolTip
                      content={t("translation:auth_location_description")}
                      className="ml-1"
                    />
                  </>
                }
              >
                <Select name="authLocation" size="large">
                  {[
                    { value: "queryParams", label: "Query Parameters" },
                    { value: "headers", label: "Headers" },
                    { value: "body", label: "Body" },
                  ].map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="start" gutter={16}>
            <Col span={4}>
              <Form.Item
                name="passwordKey"
                label={t("translation:password_key")}
              >
                <Input name="passwordKey" size="large" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="userKey"
                label={<>{t("translation:user_key")}</>}
              >
                <Input name="userKey" size="large" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="cookieKeys"
                label={<>{t("translation:cookie_keys")}</>}
              >
                <Select
                  mode="tags"
                  options={[]}
                  size="large"
                  name="cookieKeys"
                  placeholder={`${t("translation:enter")} ${t("translation:cookie_keys")}`}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                name="cookieDuration"
                label={t("translation:cookie_duration")}
              >
                <InputNumber
                  name="cookieDuration"
                  min={0}
                  max={1000000}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                name="cookieDurationUnit"
                label={t("translation:unit_of_time")}
              >
                <Select name="cookieDurationUnit" size="large">
                  {[
                    { value: "m", label: "minutes" },
                    { value: "h", label: "hours" },
                    { value: "d", label: "days" },
                  ].map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={4}>
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || !isValid}
                style={{ marginTop: "30px" }}
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [t],
    );
  return (
    <Formik
      component={oauthForm}
      initialValues={{
        login,
        cookieDuration: cookieDuration?.slice(0, -1),
        cookieDurationUnit: cookieDuration?.slice(-1),
        userKey: userKey ? userKey : "user",
        passwordKey: passwordKey ? passwordKey : "password",
        authLocation: authLocation ? authLocation : "queryParams",
        cookieKeys: cookieKeys ? cookieKeys.split(",") : [],
      }}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={simpleSchemaBuilder([
        { name: "login", type: "url", required: true },
      ])}
    />
  );
}
