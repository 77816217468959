import React, { HTMLProps, useEffect, useRef } from "react";

/**
 * This is the correct syntax to add the IndeterminateCheckbox to a table.
 *
 *     {
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        ),
        meta: {
          clickable: false,
        },
      },
 */

/**
 *
 * @param param0 indeterminate: boolean
 * @param rest
 * @returns Checkbox
 */
function IndeterminateCheckbox({
  indeterminate,
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement | null>(null);

  // useEffect updates the indeterminate state of the checkbox
  // ref.current.indeterminate will be true if the checkbox is not checked and the indeterminate prop is true
  // This ensures that the checkbox shows the indeterminate state when the indeterminate prop is true
  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={!rest?.disabled && "cursor-pointer"}
      {...rest}
    />
  );
}

export default IndeterminateCheckbox;
