import { Input, Form } from "formik-antd";

/**
 *
 * @returns StaticText component
 */
export default function StaticText() {
  return (
    <Form.Item className="w-full !mb-0 " name="values" hasFeedback={false}>
      <Input size="large" name="values" placeholder="" />
    </Form.Item>
  );
}
