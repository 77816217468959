import { useNavigate } from "react-router-dom";

import { Transaction as TransactionType } from "/app/src/models";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import { createColumnHelper } from "@tanstack/react-table";
import { useQuery } from "@tanstack/react-query";
import { transactionService } from "/app/src/services";
import getOrderByQuery from "/app/src/helpers/table";
import { buildParams } from "/app/src/helpers";
import { Helmet } from "react-helmet";
import Search from "../search";
import Table from "/app/src/components/generic/tables/table";
import { Col, Row, Select } from "antd";
import { HistoryTypes } from "/app/src/constants/types";
import Box from "/app/src/components/generic/components/box";

const { Option } = Select;

/**
 *
 * @returns List of Transactions
 */
export default function Transactions() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");
  const [filter, setFilter] = useState("all");

  const columnHelper = createColumnHelper<TransactionType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("creationDate", {
        id: "creationDate",
        cell: (info) => info.getValue(),
        header: () => t("translation:creation_date"),
      }),
      columnHelper.accessor("type", {
        id: "type",
        cell: (info) => info.getValue(),
        header: () => t("translation:type"),
      }),
      columnHelper.accessor("exportStateType", {
        id: "exportStateType",
        cell: (info) => info.getValue(),
        header: () => t("translation:export_state"),
      }),
      columnHelper.accessor("id", {
        id: "id",
        cell: (info) => info.getValue(),
        header: () => t("translation:id"),
        meta: { clickable: false, sortable: false },
      }),
    ],
    [columnHelper, t],
  );

  /**
   *
   * @param id Transaction ID
   * @returns Navigate to the Transaction
   */
  const goToTransaction = useCallback(
    (id: number) => {
      navigate(`/explorer/transactions/${id}`);
    },
    [navigate],
  );

  const { data: transactions, isFetching } = useQuery({
    queryKey: ["transactions", page, pageSize, searchString, sort, filter],
    queryFn: () => {
      return transactionService.getAll(
        buildParams({
          limit: pageSize,
          page,
          search: searchString,
          type: `[or]${filter}`,
          orderby: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { transactions: [] },
    select: (data: { transactions: TransactionType[] }) => {
      return data.transactions;
    },
  });

  const { data: transactionsCount } = useQuery({
    queryKey: ["transactionsCount", searchString, filter],
    queryFn: () => {
      return transactionService.getCount(
        buildParams({ search: searchString, type: `[or]${filter}` }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  //onChange for select setFilter Callback
  const onChangeFilter = useCallback(
    (value: number[]) => {
      const deliminatedValue = value.join(";");
      setFilter(deliminatedValue);
    },
    [setFilter],
  );

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:transactions")} - ItemPath</title>
      </Helmet>
      <Box>
        <Row>
          <Col span={12}>
            <Search setSearchString={setSearchString} />
          </Col>
          <Col span={12}>
            <Select
              onChange={onChangeFilter}
              size="large"
              style={{ width: "100%" }}
              placeholder={t("translation:filter_by_type")}
              allowClear
              mode="multiple"
            >
              {HistoryTypes.map((key) => (
                <Option key={key.value} value={key.value}>
                  {key.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Box>
      <Table
        loading={isFetching}
        rows={transactions}
        tableColumns={columns}
        length={transactionsCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage,
          setPageSize,
        }}
        enableRowSelection
        rowClicked={goToTransaction}
        emptyText={`${t("translation:no")} ${t("translation:transactions")} ${t(
          "translation:found",
        )}`}
      />
    </div>
  );
}
