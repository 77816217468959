import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { shelfService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Shelf as ShelfType } from "/app/src/models";
import Box from "/app/src/components/generic/components/box";
import { useHandleNavigationError } from "/app/src/helpers/api";

export default function Shelf() {
  const { t } = useTranslation();
  const { shelfId } = useParams();
  const handleNavigationError = useHandleNavigationError();

  //Get the Single Shelf from URL
  const { data: shelf } = useQuery({
    queryKey: ["shelf", shelfId],
    queryFn: () => {
      return shelfService.getSingle(shelfId).catch(() => {
        handleNavigationError();
      });
    },
    initialData: { shelf: {} },
    retry: false,
    select: (data: { shelf: ShelfType }) => {
      return data.shelf;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{shelf?.name}</title>
      </Helmet>
      {shelf && (
        <Box>
          <h1>{shelf?.name}</h1>
          <div className="bigItems">
            <div className="bigItem">
              <h4>{t("translation:number")}</h4>
              <span>{shelf?.number}</span>
            </div>
          </div>
        </Box>
      )}
    </div>
  );
}
