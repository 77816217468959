import React, { useCallback, useMemo } from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Form, SubmitButton, Input } from "formik-antd";
import { Setting, Widget } from "/app/src/models";
import { settingService } from "/app/src/services";
import { simpleSchemaBuilder } from "/app/src/helpers";
import { useTranslation } from "react-i18next";
import TrendLine from "./trendLine";
import { Col, Row } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";

interface FormValues {
  value: string;
  name: string;
  number: string;
}

/**
 * Function to take form values and widgetId and return a setting object
 * @param values values passed from the form
 * @param widgetId The widget id to which the trendline belongs
 * @returns the setting object to be saved
 */
function formatForm(values: FormValues, widgetId: number) {
  const value = { color: values.value, value: values.number };
  //convert to json string
  return {
    widgetId,
    name: values.name,
    value: JSON.stringify(value),
    number: 1,
    type: "trendline",
  };
}

/**
 * Trendlines are used to display a trendline on a bar chart
 * @param widget the widget to get the trendlines for
 * @returns component to display the trendlines
 */
export default function TrendLines({ widget }: { widget: Widget }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data: trendLines } = useQuery({
    queryKey: ["trendLines", widget.id],
  });
  const formattedTrendlines = useMemo(() => {
    const markers = trendLines as { settings: Setting[] };
    return markers.settings.map((trendLine: Setting) => {
      return {
        ...trendLine,
        value: JSON.parse(trendLine.value).value,
        color: JSON.parse(trendLine.value).color,
      };
    });
  }, [trendLines]);

  const { mutateAsync: createTrendline } = useMutation({
    mutationFn: (trendLine: Setting) => settingService.createSingle(trendLine),
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["trendLines", widget.id],
        (oldData: { settings: Setting[] }) => {
          return { settings: [...oldData.settings, response.setting] };
        },
      );
    },
  });

  /**
   * Function to handle the submit of the form
   */
  const handleSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      await createTrendline(formatForm(values, widget.id)).then(() => {
        actions.resetForm();
      });
    },
    [createTrendline, widget.id],
  );

  /**
   * Form to create a new trendline
   * @param props the formik props
   * @returns the form
   */
  const newTrendLineForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid, isSubmitting }) => (
        <Form>
          <Row justify="start" gutter={4}>
            <Col span={12}>
              <Form.Item name="name" hasFeedback={false}>
                <Input name="name" placeholder={t("translation:name")} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="number" hasFeedback={false}>
                <Input name="number" placeholder={t("translation:number")} />
              </Form.Item>
            </Col>
            <Col span={4} offset={4}>
              <div style={{ float: "right" }}>
                <SubmitButton
                  type="primary"
                  icon={
                    <IconBuilder
                      className="flex items-center justify-center"
                      icon="Plus"
                      color={
                        !dirty || !isValid || isSubmitting
                          ? "#d9d9d9"
                          : "#ffffff"
                      }
                    />
                  }
                  disabled={!dirty || !isValid || isSubmitting}
                />
              </div>
            </Col>
          </Row>
        </Form>
      ),
      [t],
    );

  return (
    <div>
      <div className="trendlines">
        {formattedTrendlines.map((trendLine) => {
          return <TrendLine key={trendLine.id} trendLine={trendLine} />;
        })}
      </div>
      <Formik
        initialValues={{ value: "#0080FF", name: "", number: "" }}
        component={newTrendLineForm}
        validationSchema={simpleSchemaBuilder([
          { name: "name", type: "string", required: true },
          { name: "number", type: "number", required: true },
        ])}
        enableReinitialize
        onSubmit={handleSubmit}
      />
    </div>
  );
}
