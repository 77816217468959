import React, { useState, useMemo, useCallback } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { taskService } from "/app/src/services";
import { Task as TaskType, Integration, Setting } from "/app/src/models";
import DateTime from "/app/src/components/generic/formatting/dateTime";
import Task from "./task";
import getOrderByQuery from "/app/src/helpers/table";
import { buildParams } from "/app/src/helpers/params";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import Box from "/app/src/components/generic/components/box";

export default function Logs({ integration }: { integration: Integration }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);

  const columnHelper = createColumnHelper<TaskType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("data", {
        id: "data",
        cell: (info) => info.getValue(),
        header: t("translation:description"),
      }),
      columnHelper.accessor("status", {
        id: "status",
        cell: (info) => info.getValue(),
        header: t("translation:status"),
      }),
      columnHelper.accessor("creationDate", {
        id: "creationDate",
        cell: (info) => <DateTime date={info.getValue()} />,
        header: t("translation:date"),
      }),
    ],
    [columnHelper, t],
  );

  const goToTask = useCallback(
    (taskId: number) => {
      navigate(
        `/apps/${integration.appId}/integrations/${integration.id}/tasks/${taskId}`,
      );
    },
    [navigate, integration],
  );

  const { data: tasksCount } = useQuery({
    queryKey: ["tasksCount", integration.id],
    queryFn: () => {
      return taskService.getCount(
        buildParams({ integrationId: integration.id }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  const { data: tasks, isFetching } = useQuery({
    queryKey: ["tasks", page, pageSize, sort, integration.id],
    queryFn: () => {
      return taskService.getAll(
        buildParams({
          limit: pageSize,
          page,
          integrationId: integration.id,
          orderby: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { tasks: [] },
    select: (data: { tasks: TaskType[] }) => {
      return data.tasks;
    },
  });

  return (
    <Routes>
      <Route path=":taskId/*" element={<Task integration={integration} />} />
      <Route
        path="/"
        element={
          <div className="tasks">
            <Box>
              <h1>{t("translation:logs")}</h1>
            </Box>
            <Table
              loading={isFetching}
              rows={tasks}
              tableColumns={columns}
              length={tasksCount}
              sort={sort}
              setSort={setSort}
              paginationEnabled={{
                currentPage: page,
                pageSize,
                setPage,
                setPageSize,
              }}
              enableRowSelection
              rowClicked={goToTask}
              emptyText={t("translation:no_logs_found")}
            />
          </div>
        }
      />
    </Routes>
  );
}

export function Log({ setting }: { setting: Setting }) {
  return <div>{setting.name}</div>;
}
