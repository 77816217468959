import { Select } from "formik-antd";
import { useFormikContext } from "formik";
import { useCallback } from "react";
import { App } from "/app/src/models";
import { useTranslation } from "react-i18next";

interface NewFormValues {
  app?: string;
  reportId?: number;
  value?: string | number;
  to?: string | number;
  integrationId?: number;
}

/**
 *
 * @param param0 selectedAppSetter, apps
 * @returns AppFormItem
 */
export default function AppFormItem({
  selectedAppSetter,
  apps,
}: {
  selectedAppSetter: (app: App) => void;
  apps: App[];
}) {
  const { setFieldValue } = useFormikContext<NewFormValues>();
  const { t } = useTranslation();

  const selectAppHandler = useCallback(
    (value) => {
      selectedAppSetter(JSON.parse(value));
      setFieldValue("integrationId", undefined);
    },
    [setFieldValue, selectedAppSetter],
  );

  return (
    <Select
      name="app"
      size="large"
      placeholder={t("translation:select_app")}
      onChange={selectAppHandler}
    >
      {apps.map((app) => (
        <Select.Option key={app.id} value={JSON.stringify(app)}>
          {app.name}
        </Select.Option>
      ))}
    </Select>
  );
}
