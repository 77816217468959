import React from "react";
import "moment-timezone";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";

/**
 * Function to display the status icon for a status. Green check mark for 1, red x for 0
 * @param param0 the value of the status
 * @returns the status icon
 */
export default function Status({ value }: { value: string | number }) {
  if (String(value) === "1") {
    return <IconBuilder icon="Checkmark" color="#23ce6b" />;
  } else {
    return <IconBuilder icon="Close" color="#E00000" />;
  }
}
