import { useCallback } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, Select, SubmitButton, Input } from "formik-antd";
import { useNavigate } from "react-router-dom";
import { connectionService, integrationService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { App, Integration } from "/app/src/models";
import { useMutation, useQuery } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { buildParams, simpleSchemaBuilder } from "/app/src/helpers";
import Box from "/app/src/components/generic/components/box";
import { IconToolTip } from "/app/src/components/icons/IconBuilder";

interface FormValues {
  name?: string;
  theme?: string;
  connectionId?: number;
}

function formatForm(values: FormValues, app: App): Integration {
  return {
    name: values.name,
    appId: app.id,
    baseTable: values.theme,
    connectionId: values.connectionId,
  };
}

export default function NewIntegration({
  app,
  setToggled,
}: {
  app: App;
  setToggled: () => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutateAsync: addIntegration } = useMutation({
    mutationFn: (integration: Integration) => {
      return integrationService
        .createSingle(integration)
        .then(handlePromiseError);
    },
  });
  const { data: connections } = useQuery({
    queryKey: [
      "connections",
      buildParams({ type: "[or]REST;GraphQL;MSSQL;IBM2" }),
    ],
    queryFn: () =>
      connectionService.getAll(
        buildParams({ type: "[or]REST;GraphQL;MSSQL;IBM2" }),
      ),
    initialData: { connections: [] },
    select: (data) => data.connections,
  });
  const addIntegrationHandler = useCallback(
    async (values: FormValues) => {
      const formattedForm = formatForm(values, app);
      await addIntegration(formattedForm).then((response) => {
        navigate(`/apps/${app.id}/integrations/${response.integration.id}`);
        setToggled();
      });
    },
    [addIntegration, app, navigate, setToggled],
  );

  const newIntegrationForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isSubmitting }) => (
        <Form layout="vertical">
          <Row justify="start" gutter={16}>
            <Col span={8}>
              <Form.Item
                name="name"
                label={t("translation:integration_name")}
                hasFeedback={false}
              >
                <Input
                  name="name"
                  placeholder={t("translation:enter_name")}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="theme" label={t("translation:theme")}>
                <Select name="theme" size="large">
                  <Select.Option value="Location" key="Location">
                    <div className="flex items-center">
                      {t("translation:location")}{" "}
                      <IconToolTip
                        content={t("translation:location_variance_description")}
                        className="ml-1"
                        size={16}
                      />
                    </div>
                  </Select.Option>
                  <Select.Option
                    value="LocationContentBreakdown"
                    key="LocationContentBreakdown"
                  >
                    <div className="flex items-center">
                      {t("translation:location_content_breakdown")}{" "}
                      <IconToolTip
                        content={t(
                          "translation:location_content_breakdown_variance_description",
                        )}
                        className="ml-1"
                        size={16}
                      />
                    </div>
                  </Select.Option>
                  <Select.Option value="Material" key="Material">
                    <div className="flex items-center">
                      {t("translation:material")}{" "}
                      <IconToolTip
                        content={t("translation:material_variance_description")}
                        className="ml-1"
                        size={16}
                      />
                    </div>
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="connectionId"
                label={t("translation:connection")}
              >
                <Select name="connectionId" size="large">
                  {connections.map((connection) => (
                    <Select.Option value={connection.id} key={connection.id}>
                      {connection.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || isSubmitting}
                style={{ marginTop: "30px" }}
              >
                {t("translation:create")} {t("translation:integration")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [connections, t],
    );
  const initialValues: FormValues = {
    name: "",
    theme: "Material",
  };
  return (
    <Box>
      <div className="newIntegration">
        <Formik
          component={newIntegrationForm}
          initialValues={initialValues}
          validationSchema={simpleSchemaBuilder([
            { name: "name", type: "string", required: true },
            { name: "theme", type: "string", required: true },
            { name: "connectionId", type: "number", required: true },
          ])}
          onSubmit={addIntegrationHandler}
        />
      </div>
    </Box>
  );
}
