import { IconBuilder } from "/app/src/components/icons/IconBuilder";
import Spin from "/app/src/components/NextUi/Spin";

export default function StatusIcon({ status }: { status: number }) {
  if (status === 1) {
    return (
      <IconBuilder
        icon="Checkmark"
        size={24}
        color="#23ce6b"
        className="flex items-center pl-[15px]"
      />
    );
  } else if (status === 2) {
    return <Spin size="sm" />;
  } else {
    return (
      <IconBuilder
        icon="Close"
        size={24}
        color="#E00000"
        className="flex items-center pl-[15px]"
      />
    );
  }
}
