import { Formik, FormikProps } from "formik";
import { Row, Col, message } from "antd";
import { Form, SubmitButton, InputNumber } from "formik-antd";
import { Integration } from "/app/src/models";
import { simpleSchemaBuilder } from "/app/src/helpers";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { logService } from "/app/src/services";

interface FormValues {
  limit: number;
}

/**
 * Component for clearing logs.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Integration} props.integration - The integration object.
 * @returns {JSX.Element} The ClearLogs component.
 */
export default function ClearLogs({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (values: FormValues) => {
      return await logService
        .bulkDelete(
          new URLSearchParams({
            integrationId: integration.id.toString(),
            limit: values.limit.toString(),
            orderby: "creationDate:asc",
          }),
        )
        .then((response) => {
          message.success(
            `${response.deleted} ${t("translation:logs_deleted")}`,
          );
        });
    },
    [integration.id, t],
  );
  const labelContent = useMemo(
    () => <>{t("translation:number_logs_to_clear")}</>,
    [t],
  );

  const form: (props: FormikProps<FormValues>) => JSX.Element = useCallback(
    ({ isSubmitting }) => (
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="limit" label={labelContent}>
              <InputNumber
                name="limit"
                size="large"
                min={1}
                max={10000}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <SubmitButton
              type="primary"
              size="large"
              block
              disabled={isSubmitting}
              style={{ marginTop: "30px" }}
            >
              {t("translation:clear_logs")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [labelContent, t],
  );
  return (
    <Formik
      initialValues={{ limit: 10 }}
      validationSchema={simpleSchemaBuilder([
        { name: "limit", type: "number", required: true },
      ])}
      onSubmit={onSubmit}
      component={form}
      enableReinitialize
    />
  );
}
