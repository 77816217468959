import { useCallback } from "react";
import { Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import { Theme as ThemeType } from "/app/src/types";

/**
 * Renders a type selection dropdown component.
 *
 * @param {Object} props - The properties object.
 * @param {Function} props.setSelectedType - Callback function to set the selected type.
 * @param {Array} props.types - Array of theme types to be displayed in the dropdown.
 */
export function Type({
  setSelectedType,
  types,
}: {
  setSelectedType: (selectedType: string) => void;
  types: ThemeType[];
}) {
  const { t } = useTranslation();
  const selectedType = useCallback(
    (value) => {
      setSelectedType(value);
    },
    [setSelectedType],
  );
  return (
    <div className="themeFilter box">
      <Select
        defaultValue="all"
        size="large"
        style={{ width: "100%" }}
        onChange={selectedType}
        placeholder={t("translation:select_type")}
      >
        <Select.Option value="all">{t("translation:all_types")}</Select.Option>
        {types.map((theme) => (
          <Select.Option key={theme.name} value={theme.value}>
            {theme.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

export function Theme({
  themes,
  setSelectedTheme,
}: {
  themes: ThemeType[];
  setSelectedTheme: (selectedTheme: string) => void;
}) {
  const { t } = useTranslation();
  const selectedTheme = useCallback(
    (value) => {
      setSelectedTheme(value);
    },
    [setSelectedTheme],
  );
  return (
    <div className="themeFilter box">
      <Select
        defaultValue="all"
        size="large"
        style={{ width: "100%" }}
        onChange={selectedTheme}
        placeholder={t("translation:select_theme")}
      >
        <Select.Option value="all">{t("translation:all_themes")}</Select.Option>
        {themes.map((theme, index) => (
          <Select.Option key={index} value={theme.value}>
            {theme.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

/**
 * Search component for handling search input.
 *
 * @param {Object} props - The properties object.
 * @param {function} props.setSearchString - Function to update the search string.
 * @returns {JSX.Element} The search input element.
 */
export function Search({
  setSearchString,
}: {
  setSearchString: (searchString: string) => void;
}) {
  const { t } = useTranslation();
  const searchString = useCallback(
    (value) => {
      setSearchString(value);
    },
    [setSearchString],
  );
  return (
    <div className="search box">
      <Input.Search
        onSearch={searchString}
        size="large"
        placeholder={t("translation:search")}
      />
    </div>
  );
}

/**
 * Filters component for managing search, theme, and type filters.
 *
 * @param {Object} props - The properties object.
 * @param {ThemeType[]} [props.themes] - An array of theme objects.
 * @param {function(string): void} props.setSearchString - Function to set the search string.
 * @param {function(string): void} props.setSelectedTheme - Function to set the selected theme.
 * @param {function(string): void} props.setSelectedType - Function to set the selected type.
 * @param {ThemeType[]} props.types - An array of type objects.
 * @returns {JSX.Element} The rendered Filters component.
 */
export default function Filters({
  themes,
  setSearchString,
  setSelectedTheme,
  setSelectedType,
  types,
}: {
  themes?: ThemeType[];
  setSearchString: (searchString: string) => void;
  setSelectedTheme: (selectedTheme: string) => void;
  setSelectedType: (selectedType: string) => void;
  types: ThemeType[];
}) {
  return (
    <div className="integrationListFilters">
      <Search setSearchString={setSearchString} />
      {themes && <Theme themes={themes} setSelectedTheme={setSelectedTheme} />}
      {types.length > 0 && (
        <Type setSelectedType={setSelectedType} types={types} />
      )}
    </div>
  );
}
