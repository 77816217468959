import { ReportColumnType } from "/app/src/models";
import _ from "lodash";

/**
 * Convert the list of column types into a cascading list of options
 */
export function cascadeReportColumnTypes(
  reportColumnTypes: ReportColumnType[],
  theme?: string,
) {
  const selects = _.chain(reportColumnTypes)
    .sortBy("finalTable") // Sort by finalTable property
    .groupBy("finalTable") // Group the elements by finalTable property
    // `key` is group's name (color), `value` is the array of objects
    .map((value, key) => {
      return {
        value: key,
        label: key,
        children: value.map((child_value) => {
          // Variance report column types should also display their integration name
          const childLabel = child_value.integrationName
            ? `${child_value.name} (${child_value.integrationName})`
            : child_value.name;
          return {
            value: child_value.id,
            label: childLabel,
          };
        }),
      };
    })
    .sortBy((item) => (theme ? item.value !== theme : 0))
    .value();

  return selects;
}
