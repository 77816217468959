import React, { useCallback } from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

export default function Theme({
  themes,
  setSelectedTheme,
}: {
  themes: { name: string; value: string }[];
  setSelectedTheme: (string: string) => void;
}) {
  const { t } = useTranslation();

  const selectTheme = useCallback(
    (value) => {
      setSelectedTheme(value);
    },
    [setSelectedTheme],
  );

  return (
    <div className="themeFilter box">
      <Select
        defaultValue="all"
        size="large"
        style={{ width: "100%" }}
        onChange={selectTheme}
        placeholder={t("translation:select_theme")}
      >
        <Select.Option value="all">{t("translation:all_themes")}</Select.Option>
        {themes.map((theme, index) => (
          <Select.Option key={index} value={theme.value}>
            {theme.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}
