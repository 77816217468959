import { Button } from "antd";
import i18next from "/app/src/locales/config";

export default function refreshSetting(props) {
  return (
    <Button
      type="primary"
      onClick={() =>
        refreshList(props.refresh, props.newSetting, props.setNewSetting)
      }
    >
      {i18next.t("translation:refresh_list")}
    </Button>
  );
}

function refreshList(refresh, newSetting, setNewSetting) {
  refresh();
  setNewSetting(!newSetting);
}
