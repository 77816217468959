import { string, object } from "yup";
import i18next from "/app/src/locales/config";

export const sqlIntegrationSchema = object().shape({
  name: string().required(i18next.t("translation:required")),
  token: string()
    .required(i18next.t("translation:required"))
    .matches(
      /^[a-zA-Z0-9_]+$/,
      i18next.t("translation:key_must_be_alphanumeric_underscore"),
    ),
  baseTable: string().required(i18next.t("translation:required")),
});
