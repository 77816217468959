import React, { useCallback } from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

/**
 * App component that renders a themed filter box with a Select dropdown.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.setSelectedApp - Function to set the selected app.
 *
 * @returns {JSX.Element} The rendered App component.
 */
export default function App({
  setSelectedApp,
}: {
  setSelectedApp: (string: string) => void;
}) {
  const { t } = useTranslation();

  const selectApp = useCallback(
    (value) => {
      setSelectedApp(value);
    },
    [setSelectedApp],
  );

  return (
    <div className="themeFilter box">
      <Select
        size="large"
        style={{ width: "100%" }}
        onChange={selectApp}
        placeholder={t("translation:select_app")}
        allowClear
      >
        <Select.Option value={"Order Builder"}>{"Order Builder"}</Select.Option>
        <Select.Option value={"Variance"}>{"Variance"}</Select.Option>
      </Select>
    </div>
  );
}
