import ColumnArea from "./columnArea";
import NewReportColumnForm from "./newReportColumnForm";
import { ReportColumn, Report as ReportType } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
import { reportColumnTypeService } from "/app/src/services";
import { buildParams } from "/app/src/helpers/params";
import GroupingColumns from "./groupingColumns";

type ReportColumnWithId = ReportColumn & { id: number };

export default function ReportColumns({
  report,
  columns,
}: {
  report: ReportType;
  columns: ReportColumn[];
}) {
  const reportColumnTypesQuery = useQuery({
    queryKey: ["reportColumnTypes", report.baseTable],
    queryFn: () => {
      return reportColumnTypeService.getAll(
        buildParams({ baseTable: report.baseTable, orderby: "name" }),
      );
    },
    placeholderData: { report_column_types: [] },
    enabled: Boolean(report.baseTable),
    select: (data) => data.report_column_types,
    staleTime: 60 * 1000,
  });

  const reportColumns = columns as ReportColumnWithId[];

  return (
    <>
      <div className="reportColumns">
        <ColumnArea columns={reportColumns} reportId={report.id} />
        <div className="newReportColumn">
          <NewReportColumnForm
            report={report}
            reportColumnTypes={reportColumnTypesQuery.data}
            columns={columns}
          />
        </div>
      </div>
      <GroupingColumns
        report={report}
        columns={columns.filter(
          (column) =>
            !["comparison", "count"].includes(column.type) &&
            column.custom !== "variance",
        )}
      />
    </>
  );
}
