import React, { useCallback, useState } from "react";
import "/app/src/stylesheets/utilities/forms.scss";
import { Formik } from "formik";
import EditButton from "/app/src/components/generic/components/buttons/EditButton";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Workflow } from "/app/src/models";
import { workflowService } from "/app/src/services";
import { simpleSchemaBuilder } from "/app/src/helpers";
import { handlePromiseError } from "/app/src/helpers/api";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";

export default function Title({ workflow }: { workflow: Workflow }) {
  const [editingTitle, setEditingTitle] = useState(false);
  const queryClient = useQueryClient();

  const handleEditClick = useCallback((): void => {
    setEditingTitle(true);
  }, []);

  const { mutateAsync: updateWorkflow } = useMutation({
    mutationFn: (values: Omit<Workflow, "trigger">) => {
      return workflowService
        .updateSingle(workflow.id, values)
        .then(handlePromiseError);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["workflow", workflow.id], data);
    },
  });

  const onSubmitWorkflow = useCallback(
    async (value: { name: string }) => {
      await updateWorkflow(value).then(() => {
        setEditingTitle(false);
      });
    },
    [updateWorkflow],
  );

  return (
    <div className="flex-grow-1 title">
      {editingTitle ? (
        <Formik
          initialValues={{
            name: workflow.name,
          }}
          validationSchema={simpleSchemaBuilder([
            { name: "name", type: "string", required: true },
          ])}
          onSubmit={onSubmitWorkflow}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <div className="formGroup">
                <input
                  type="text"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.name}
                  name="name"
                  className={`titleInput ${
                    props.errors.name ? "errorInput" : ""
                  }`}
                />
                {props.errors.name && (
                  <div id="feedback">{props.errors.name}</div>
                )}
                <button className="saveButton" type="submit">
                  <IconBuilder icon="Checkmark" color="#3caf96" />
                </button>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <div className="noEdit">
          <h1>{workflow.name}</h1>
          <EditButton onClick={handleEditClick} />
        </div>
      )}
    </div>
  );
}
