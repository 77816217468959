import React, { useCallback } from "react";
import { Input } from "antd";
import { useTranslation } from "react-i18next";

/**
 * Search component which allows users to input a search string.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.setSearchString - Function to set the search string.
 */
export function Search({
  setSearchString,
}: {
  setSearchString: (searchString: string) => void;
}) {
  const { t } = useTranslation();
  const searchString = useCallback(
    (value) => {
      setSearchString(value);
    },
    [setSearchString],
  );
  return (
    <div className="search box">
      <Input.Search
        onSearch={searchString}
        size="large"
        placeholder={t("translation:search")}
      />
    </div>
  );
}

/**
 * Filters component to render search filters.
 *
 * @param {Object} props - The properties object.
 * @param {Function} props.setSearchString - A function to set the search string.
 */
export default function Filters({
  setSearchString,
}: {
  setSearchString: (searchString: string) => void;
}) {
  return (
    <div className="integrationListFilters">
      <Search setSearchString={setSearchString} />
    </div>
  );
}
