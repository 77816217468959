import { Integration, Mapping } from "/app/src/models";
import i18next from "/app/src/locales/config";

/**
 * Retrieves the title for a given mapping based on its type and the integration's base table.
 *
 * @param {Mapping} mapping - The mapping object containing the type information.
 * @param {Integration} integration - The integration object containing the base table information.
 * @returns {string} The localized title corresponding to the mapping type and integration base table.
 */
export default function getMappingTitle(
  mapping: Mapping,
  integration: Integration,
) {
  let title: string;
  switch (mapping?.type) {
    case "column":
      title = i18next.t("translation:column");
      break;
    case "text":
      title = i18next.t("translation:text");
      break;
    case "reportColumn":
      title = i18next.t("translation:report_column");
      break;
    case "advanced":
      title = i18next.t("translation:advanced");
      break;
    case "lines":
      title = i18next.t("translation:lines");
      break;
    case "grouping":
      title = i18next.t("translation:grouping");
      break;
    case "data":
      switch (integration?.baseTable) {
        case "Order":
          title = i18next.t("translation:orders");
          break;
        case "Material":
          title = i18next.t("translation:materials");
          break;
        case "Order Deletion":
          title = i18next.t("translation:orders");
          break;
        default:
          title = i18next.t("translation:grouping");
          break;
      }
      break;
    case "id":
      title = i18next.t("translation:id");
      break;
    default:
      title = i18next.t("translation:column");
      break;
  }
  return title;
}
