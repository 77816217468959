import React, { useCallback } from "react";
import { Input } from "antd";
import { useTranslation } from "react-i18next";

export default function Search({
  setSearchString,
}: {
  setSearchString: (searchString: string) => void;
}) {
  const { t } = useTranslation();
  const searchStringHandler = useCallback(
    (value) => {
      setSearchString(value);
    },
    [setSearchString],
  );
  return (
    <div className="search box">
      <Input.Search
        onSearch={searchStringHandler}
        size="large"
        placeholder={t("translation:search")}
      />
    </div>
  );
}
