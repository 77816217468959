import { FullScreenHandle } from "react-full-screen";
import NextButton from "/app/src/components/NextUi/Button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { App, Integration } from "/app/src/models";
import { useCallback } from "react";
export default function Controls({
  integration,
  app,
  buttonVisible,
  handler,
}: {
  integration: Integration;
  app: App;
  buttonVisible: boolean;
  handler: FullScreenHandle;
}) {
  const handleExitFullscreen = useCallback((): void => {
    handler.exit();
  }, [handler]);

  const handleEnterFullscreen = useCallback((): void => {
    handler.enter();
  }, [handler]);

  const { t } = useTranslation();
  return (
    <div className="controls">
      <div className="icon"> </div>

      {integration && (
        <div className="title">
          {app && <Link to={`/apps/${app.id}`}>{app.name}</Link>}
          <span className="separator">{">"}</span>
          <h1>{integration.name}</h1>
        </div>
      )}
      {buttonVisible && (
        <div className="controlButtons">
          {!handler.active ? (
            <NextButton
              color="default"
              variant="bordered"
              size="sm"
              onClick={handleEnterFullscreen}
              className="hover:text-primary-default hover:border-primary-default"
            >
              {t("translation:enter_fullscreen")}
            </NextButton>
          ) : (
            <NextButton
              color="default"
              variant="bordered"
              size="sm"
              onClick={handleExitFullscreen}
              className="hover:text-primary-default hover:border-primary-default"
            >
              {t("translation:exit_fullscreen")}
            </NextButton>
          )}
        </div>
      )}
    </div>
  );
}
