import React, { useCallback } from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import { Role as RoleType } from "/app/src/models";

export default function Filter({
  roles,
  setRole,
  setStatus,
}: {
  roles: RoleType[];
  setRole: (role: number | string) => void;
  setStatus: (status: string) => void;
}) {
  const { t } = useTranslation();

  const setStatusHandler = useCallback(
    (value) => {
      setStatus(value);
    },
    [setStatus],
  );

  const setRoleHandler = useCallback(
    (value: number | string) => {
      setRole(value);
    },
    [setRole],
  );

  return (
    <>
      <div className="themeFilter box">
        <h3>{t("translation:filter_by_role")}</h3>
        <Select
          defaultValue="all"
          size="large"
          style={{ width: "100%" }}
          onChange={setRoleHandler}
          placeholder={t("translation:select_role")}
        >
          <Select.Option key={0} value="all">
            {t("translation:all")}
          </Select.Option>
          {roles.map((role) => (
            <Select.Option key={role.id} value={role.id}>
              {role.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className="themeFilter box">
        <h3>{t("translation:filter_by_status")}</h3>
        <Select
          defaultValue="all"
          size="large"
          style={{ width: "100%" }}
          onChange={setStatusHandler}
          placeholder={t("translation:select_theme")}
        >
          <Select.Option key={0} value="all">
            {t("translation:all")}
          </Select.Option>
          <Select.Option key={1} value={0}>
            {t("translation:pending")}
          </Select.Option>
          <Select.Option key={2} value={1}>
            {t("translation:active")}
          </Select.Option>
          <Select.Option key={3} value={3}>
            {t("translation:application")}
          </Select.Option>
          <Select.Option key={4} value={2}>
            {t("translation:deactivated")}
          </Select.Option>
        </Select>
      </div>
    </>
  );
}
