import { useTranslation } from "react-i18next";
import { Integration } from "/app/src/models";
import ConfirmationHook from "./confirmationHook";
import ConfirmationMappings from "./confirmationMappings";
import MethodSetting from "/app/src/components/generic/components/apps/data/settings/connectionSettings/methodSetting";
import ConfirmationConnection from "./confirmationConnection";
import Box from "/app/src/components/generic/components/box";

/**
 * Highest level component for the Confirmation Mappings page
 */
export default function Confirmation({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();

  const { mappings, connections } = ConfirmationHook({ integration });

  return (
    <>
      <Box>
        <h1>{t("translation:confirmation_mapping")}</h1>
        <ConfirmationMappings integration={integration} />
      </Box>
      <Box>
        <h1>{t("translation:settings")}</h1>
        <ConfirmationConnection
          integration={integration}
          connections={connections}
          mappings={mappings}
        />
        <MethodSetting
          params={{ integrationId: integration.id, type: "confirmationMethod" }}
        />
      </Box>
    </>
  );
}
