import { Formik } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, Input } from "formik-antd";
import { settingService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { IconToolTip } from "/app/src/components/icons/IconBuilder";
import { buildParams } from "/app/src/helpers/params";
import {
  Connection as ConnectionType,
  Setting as SettingType,
} from "/app/src/models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { useCallback } from "react";

function formatForm(
  value: string | undefined,
  connectionId: number | undefined,
) {
  return {
    name: "Test Endpoint",
    connectionId,
    type: "testEndpoint",
    value,
  };
}
export default function TestEndpoint({
  connection,
}: {
  connection: ConnectionType;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync: createSetting } = useMutation({
    mutationFn: (setting: SettingType) =>
      settingService.createSingle(setting).then(handlePromiseError),
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["setting", "testEndpoint", connection.id],
        response,
      );
    },
  });

  const { data: setting } = useQuery({
    queryKey: ["setting", "testEndpoint", connection.id],
    queryFn: () =>
      settingService
        .getAll(
          buildParams({ connectionId: connection.id, type: "testEndpoint" }),
        )
        .then((response) => {
          if (response.settings.length > 0) {
            return { setting: response.settings[0] };
          }
          return { setting: {} };
        }),
    initialData: { setting: {} },
    select: (data) => data.setting,
  });
  const { mutateAsync: updateSetting } = useMutation({
    mutationFn: (updatedSetting: SettingType) =>
      settingService
        .updateSingle(setting.id, updatedSetting)
        .then(handlePromiseError),
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["setting", "testEndpoint", connection.id],
        response,
      );
    },
  });
  const handleSubmit = useCallback(
    async (values: SettingType) => {
      if (setting?.id) {
        return await updateSetting(values);
      }
      return await createSetting(formatForm(values.value, connection.id));
    },
    [setting, updateSetting, createSetting, connection.id],
  );
  return (
    <div>
      <Row justify="start">
        <Col>
          <h2>{t("translation:test_endpoint")}</h2>
        </Col>
        <Col>
          <IconToolTip content={t("translation:testing_url_tip")} />
        </Col>
      </Row>
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            value: setting?.value,
          }}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, dirty }) => (
            <Form layout="vertical">
              <Row justify="start" gutter={16}>
                <Col span={11}>
                  <Form.Item name="url">
                    <Input
                      disabled
                      name="url"
                      placeholder={connection?.url}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <span className="blockTextLight">/</span>
                </Col>
                <Col span={8}>
                  <Form.Item name="value" hasFeedback={false}>
                    <Input
                      suffix
                      name="value"
                      placeholder={t("translation:test_endpoint_example")}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <SubmitButton
                    type="primary"
                    size="large"
                    block
                    disabled={isSubmitting || !dirty}
                  >
                    {t("translation:save")}
                  </SubmitButton>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
