/**
 * Helper function to check if a string is valid JSON
 * @param string the string to be checked
 * @returns Boolean value indicating if the string is valid JSON
 */
export function isJSON(string: string): boolean {
  let valid;
  try {
    JSON.parse(string);
    valid = true;
  } catch (error) {
    valid = false;
  }
  return valid;
}

/**
 * Helper function to parse JSON data
 * @param data the data to be parsed
 * @returns JSON object if data is valid JSON, otherwise returns the data
 */
export function getJSON(data: string): string {
  if (isJSON(data)) {
    return JSON.parse(data);
  } else {
    return data;
  }
}

/**
 * Converts the data to json and then returns the value
 * of the passed in key
 * @param data json string
 * @param key key to return the value of
 */
export function parseOutData(data: string, key: string) {
  try {
    const parsedData = JSON.parse(data);
    //convert to string to avoid react error
    return JSON.stringify(parsedData[key]);
  } catch (e) {
    return data;
  }
}

/**
 * Helper function to check if a key exists in a JSON string
 * @param data the JSON string
 * @param key the key to check for
 * @returns Boolean value indicating if the key exists
 */
export function keyExists(data: string, key: string) {
  try {
    const parsedData = JSON.parse(data);
    return parsedData[key] !== undefined;
  } catch (e) {
    return false;
  }
}

export function getKeyValue(data: string, key: string) {
  try {
    const parsedData = JSON.parse(data);
    return parsedData[key];
  } catch (e) {
    return undefined;
  }
}

/**
 * Parses a JSON string and returns the corresponding object.
 * If the input is undefined or an invalid JSON string, an empty object is returned.
 *
 * @param data - The JSON string to be parsed. Can be undefined.
 * @returns The parsed object. If the input is invalid or undefined, an empty object is returned.
 */
export function parseJSONData(data: string | undefined): object {
  if (!data) return {};

  try {
    const parsedData = JSON.parse(data);
    return typeof parsedData === "string" ? JSON.parse(parsedData) : parsedData;
  } catch (e) {
    return {};
  }
}
