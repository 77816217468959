import React from "react";
import Theme from "./theme";
import { Search } from "../../generic/components/search";
import App from "./app";

export default function Filters({
  themes,
  setSearchString,
  setSelectedTheme,
  setSelectedApp,
}: {
  themes: { name: string; value: string }[];
  setSearchString: (string: string) => void;
  setSelectedTheme: (string: string) => void;
  setSelectedApp: (string: string) => void;
}) {
  return (
    <div className="reportListFilters">
      <Search setSearchString={setSearchString} />
      <Theme themes={themes} setSelectedTheme={setSelectedTheme} />
      <App setSelectedApp={setSelectedApp} />
    </div>
  );
}
