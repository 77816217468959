import React, { useCallback, useEffect, useState, useMemo } from "react";
import { callService, userService } from "/app/src/services";
import { Helmet } from "react-helmet";
import DateTime from "/app/src/components/generic/formatting/dateTime";
import { buildParams } from "/app/src/helpers/params";
import { getDateFormat } from "/app/src/helpers/time";
import Controls from "./controls";
import { useTranslation } from "react-i18next";
import { User, apiCall } from "/app/src/models";
import getOrderByQuery from "/app/src/helpers/table";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { IconToolTip } from "/app/src/components/icons/IconBuilder";
import { roundDecimal } from "../../helpers/data";
import { useTimezoneContext } from "/app/src/contexts/hooks/useTimezoneContext";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";

/**
 * Build the params for the api call. Calculates the creation date range, and
 * builds the params for the search, status, and user.
 */
function buildCallParams(
  searchString: string,
  afterTime: string,
  beforeTime: string,
  selectedStatus: string,
  selectedUser: string,
  endpoint: string,
) {
  const creationDate = [];
  if (afterTime !== "") {
    creationDate.push(`[gt]${getDateFormat(afterTime, "YYYY-MM-DDTHH:mm:ss")}`);
  }
  if (beforeTime !== "") {
    creationDate.push(
      `[lt]${getDateFormat(beforeTime, "YYYY-MM-DDTHH:mm:ss")}`,
    );
  }

  return buildParams({
    search: searchString,
    creationDate,
    code: selectedStatus,
    userId: selectedUser,
    endpoint,
  });
}

/**
 * Component for displaying the api calls
 */
export default function Calls() {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");
  const [beforeTime, setBeforeTime] = useState("");
  const [afterTime, setAfterTime] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [selectedUser, setSelectedUser] = useState("all");
  const [isTextLimitEnabled, setIsTextLimitEnabled] = useState(false);
  const queryClient = useQueryClient();
  const { timeZone } = useTimezoneContext();
  const endpoint = "[notlike]refresh";

  //limit the text to 100 characters
  const limitText = (value: string) => {
    if (value) {
      return value.length > 100 ? `${value.substring(0, 100)} ...` : value;
    }
    return null;
  };

  //handler for toggling on and off the text limit
  const toggleTextLimit = useCallback(() => {
    setIsTextLimitEnabled((prev) => !prev);
  }, []);

  const columnHelper = createColumnHelper<apiCall>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("creationDate", {
        id: "creationDate",
        cell: (info) => {
          if (info.getValue() !== null) {
            return (
              <DateTime
                date={info.getValue()}
                format={"MMMM Do YYYY, h:mm:ss.SS a"}
              />
            );
          } else {
            return <> </>;
          }
        },
        header: () => (
          <div className="flex items-center">
            {t("translation:date/time")}
            <IconToolTip
              content={`${t("translation:times_displayed")} ${timeZone} ${t("translation:time_zone")}`}
              className="ml-1"
            />
          </div>
        ),
      }),
      columnHelper.accessor("code", {
        id: "code",
        cell: (info) => info.getValue(),
        header: () => t("translation:code"),
      }),
      columnHelper.accessor("duration", {
        id: "duration",
        cell: (info) => {
          if (info.getValue() !== null) {
            return <>{roundDecimal(info.getValue() as string, 1)} ms</>;
          } else {
            return <> </>;
          }
        },
        header: () => t("translation:duration"),
      }),
      columnHelper.accessor("endpoint", {
        id: "endpoint",
        cell: (info) => info.getValue(),
        header: () => t("translation:endpoint"),
      }),
      columnHelper.accessor("username", {
        id: "username",
        cell: (info) => info.getValue(),
        header: () => t("translation:user"),
      }),
      columnHelper.accessor("body", {
        id: "body",
        cell: (info) => {
          if (isTextLimitEnabled) {
            return <>{limitText(info.getValue())}</>;
          } else {
            return <>{info.getValue()}</>;
          }
        },
        header: () => t("translation:body"),
      }),
      columnHelper.accessor("response", {
        id: "response",
        cell: (info) => {
          if (isTextLimitEnabled) {
            return <>{limitText(info.getValue())}</>;
          } else {
            return <>{info.getValue()}</>;
          }
        },
        header: () => t("translation:response"),
      }),
      columnHelper.accessor("error", {
        id: "error",
        cell: (info) => {
          if (isTextLimitEnabled) {
            return <>{limitText(info.getValue())}</>;
          } else {
            return <>{info.getValue()}</>;
          }
        },
        header: () => t("translation:error"),
      }),
    ],
    [columnHelper, isTextLimitEnabled, t, timeZone],
  );

  //Get the list of all users
  const { data: users } = useQuery({
    queryKey: ["users"],
    queryFn: () => {
      return userService.getAll();
    },
    initialData: { users: [] },
    select: (data: { users: User[] }) => {
      return data.users;
    },
  });

  //handler for clearing logs button
  const clearLogs = useCallback(() => {
    callService.deleteAll().then(() => {
      queryClient.setQueryData(
        [
          "apiCalls",
          page,
          searchString,
          afterTime,
          beforeTime,
          selectedStatus,
          selectedUser,
        ],
        { calls: [] },
      );
      queryClient.setQueryData(
        [
          "callCount",
          page,
          searchString,
          afterTime,
          beforeTime,
          selectedStatus,
          selectedUser,
        ],
        { count: 0 },
      );
    });
  }, [
    afterTime,
    beforeTime,
    page,
    queryClient,
    searchString,
    selectedStatus,
    selectedUser,
  ]);

  //get the count
  const { data: callCount } = useQuery({
    queryKey: [
      "callCount",
      page,
      searchString,
      afterTime,
      beforeTime,
      selectedStatus,
      selectedUser,
    ],
    queryFn: () => {
      const countParams = buildCallParams(
        searchString,
        afterTime,
        beforeTime,
        selectedStatus,
        selectedUser,
        endpoint,
      );
      return callService.getCount(countParams);
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  //get the list of api calls
  const { data: apiCalls, isFetching } = useQuery({
    queryKey: [
      "apiCalls",
      page,
      pageSize,
      searchString,
      afterTime,
      beforeTime,
      selectedStatus,
      selectedUser,
      sort,
    ],
    queryFn: () => {
      const params = buildCallParams(
        searchString,
        afterTime,
        beforeTime,
        selectedStatus,
        selectedUser,
        endpoint,
      );
      params.append("page", String(page));
      params.append("limit", String(pageSize));
      params.append("orderby", getOrderByQuery(sort));
      return callService.getAll(params);
    },
    initialData: { calls: [] },
    select: (data: { calls: apiCall[] }) => {
      return data.calls;
    },
  });

  useEffect(() => {
    setPage(0);
  }, [searchString, afterTime, beforeTime, selectedStatus, selectedUser]);

  return (
    <div className="calls">
      <Helmet>
        <title>{t("translation:api_calls")} - ItemPath</title>
      </Helmet>
      <Controls
        clearLogs={clearLogs}
        setSelectedStatus={setSelectedStatus}
        setSelectedUser={setSelectedUser}
        setAfterTime={setAfterTime}
        setBeforeTime={setBeforeTime}
        users={users}
        setSearchString={setSearchString}
        isTextLimitEnabled={isTextLimitEnabled}
        toggleTextLimit={toggleTextLimit}
      />

      <Table
        loading={isFetching}
        rows={apiCalls}
        tableColumns={columns}
        length={callCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage,
          setPageSize,
        }}
        enableRowSelection
        emptyText={"No Calls Found"}
      />
    </div>
  );
}
