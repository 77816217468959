import { iLine as iLineType } from "/app/src/models";
import { iLineService } from "/app/src/services";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { Row, Col } from "antd";
import { Form, Select } from "formik-antd";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import DisabledSubmitButton from "/app/src/components/generic/components/buttons/DisabledSubmitButton";
import { handlePromiseError } from "/app/src/helpers/api";

interface FormValues {
  field?: string;
}

/**
 * NewLineDetail component allows users to add a new line detail to an order.
 *
 * @param {Object} props - The component props.
 * @param {iLineType} props.iLine - The current line item being edited.
 * @param {Array<{ label: string; value: string }>} props.availableFields - The list of available fields for selection.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * ```tsx
 * <NewLineDetail iLine={line} availableFields={fields} />
 * ```
 *
 * @remarks
 * This component uses Formik for form management and Ant Design components for UI.
 * It also utilizes the `useTranslation` hook for internationalization and `useQueryClient` from React Query for data synchronization.
 */
export default function NewLineDetail({
  iLine,
  availableFields,
}: {
  iLine: iLineType;
  availableFields: { label: string; value: string }[];
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const saveLine = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      iLine.loadedData[values.field] = "";
      const orderData = JSON.stringify(iLine.loadedData);
      return await iLineService
        .updateSingle(iLine.id, { data: orderData })
        .then(handlePromiseError)
        .then((response) => {
          queryClient.setQueryData(
            ["lines", { orderId: iLine.orderId }],
            (oldData: { lines: iLineType[] }) => {
              return {
                lines: oldData.lines.map((line) => {
                  if (line.id === iLine.id) {
                    return response?.line;
                  }
                  return line;
                }),
              };
            },
          );
          actions.resetForm();
        });
    },
    [iLine.id, iLine.loadedData, iLine.orderId, queryClient],
  );

  const newLineDetailForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form layout="vertical">
          <Row justify="start" gutter={16}>
            <Col span={20}>
              <Form.Item name="field" label={t("translation:field")}>
                <Select
                  name="field"
                  size="large"
                  placeholder={t("translation:add_new_line_detail")}
                >
                  {availableFields.map((field) => (
                    <Select.Option key={field.value} value={field.value}>
                      {field.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <DisabledSubmitButton
                style={{ marginTop: "30px" }}
                type="primary"
                size="large"
                block
                disabled={!(dirty && isValid)}
              >
                {t("translation:add")}
              </DisabledSubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [availableFields, t],
    );

  return (
    <Formik
      enableReinitialize
      component={newLineDetailForm}
      initialValues={{ field: undefined }}
      onSubmit={saveLine}
    />
  );
}
