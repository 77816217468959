import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Formik, FormikProps } from "formik";
import { Form, Input, SubmitButton } from "formik-antd";
import { Row, Col, message } from "antd";
import { resetPasswordSchema } from "/app/src/schemas";
import { userService } from "/app/src/services";
import { handleSubmissionErrors } from "/app/src/helpers/forms";
import { useAuthState } from "/app/src/contexts/authentication";
import { useTranslation } from "react-i18next";
import NextButton from "/app/src/components/NextUi/Button";

interface FormValues {
  password: string;
}

interface CodeParams {
  code?: string;
}

/**
 * Formats the form values.
 *
 * @param {FormValues} values - The form values to be formatted.
 * @returns {Object} An object containing the formatted password.
 */
function formatForm(values: FormValues) {
  return {
    password: values.password,
  };
}

export default function ResetForm() {
  const { t } = useTranslation();
  const [changed, setChanged] = useState(false);
  const { user } = useAuthState();
  const { code } = useParams() as CodeParams;

  const success = useCallback(() => {
    message.success(t("translation:password_saved_sucessfully"));
    setChanged(true);
  }, [t]);

  const navigate = useNavigate();

  // redirect to home if already logged in
  useEffect(() => {
    if (user?.id) {
      navigate("/");
    }
  });

  const navigateToLogin = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  const updatePassword = useCallback(
    (values, actions) => {
      if (code) {
        userService.updateSingle(code, formatForm(values)).then((response) => {
          if ("errors" in response) {
            handleSubmissionErrors(response.errors, actions.setFieldError);
          } else {
            success();
          }
        });
      } else {
        actions.setSubmitting(false);
      }
    },
    [code, success],
  );

  const ResetPasswordForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ errors, touched, isValid, dirty }) => (
        <Form layout="vertical">
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item name="password" label={t("translation:new_password")}>
                <Input.Password
                  name="password"
                  size="large"
                  className={
                    errors.password && touched.password
                      ? "input-error"
                      : undefined
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <SubmitButton
                type="primary"
                size="large"
                disabled={!(dirty && isValid)}
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [t],
    );

  return (
    <div className="login">
      <Helmet>
        <title>{t("translation:reset_password")} - ItemPath</title>
      </Helmet>
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 20, offset: 2 }}
          lg={{ span: 8, offset: 8 }}
        >
          <div className="box mt-4">
            {!changed ? (
              <div>
                <h3 style={{ textAlign: "center", margin: "20px 0 30px" }}>
                  {t("translation:enter_new_password")}
                </h3>
                <Formik
                  component={ResetPasswordForm}
                  validationSchema={resetPasswordSchema}
                  initialValues={{
                    password: "",
                  }}
                  onSubmit={updatePassword}
                />
              </div>
            ) : (
              <NextButton
                onClick={navigateToLogin}
                color="default"
                variant="bordered"
                size="sm"
                className="hover:border-primary-default hover:text-primary-default"
              >
                {t("translation:login")}
              </NextButton>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
