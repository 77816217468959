import { Row, Col } from "antd";
import NextDivider from "/app/src/components/NextUi/Divider";
import { IconToolTip } from "/app/src/components/icons/IconBuilder";
import { useStatusContext } from "/app/src/contexts/hooks/useStatusContext";
import "./_status.scss";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { warehouseService } from "/app/src/services";
import { useTimezoneContext } from "/app/src/contexts/hooks/useTimezoneContext";
import { useConnectionStatusContext } from "/app/src/contexts/hooks/useConnectionStatusContext";
import { useLicenseContext } from "/app/src/contexts/hooks/useLicenseContext";
import Box from "/app/src/components/generic/components/box";

/**
 * Component for displaying general info
 * @returns JSX.Element
 *
 */
export default function GeneralInfo() {
  const { version, warehouseLimit, releaseDate } = useStatusContext();
  const { powerPickVersion } = useLicenseContext();
  const { userTimeZone, globalTimeZone } = useTimezoneContext();
  const { powerPick } = useConnectionStatusContext();
  const { t } = useTranslation();

  const { data: warehouseCount } = useQuery({
    queryKey: ["warehouseCount"],
    queryFn: () => {
      return warehouseService.getCount();
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return (
    <Box>
      <Row>
        <Col span={12}>
          <div className="title">{t("translation:powerpick_version")}</div>
        </Col>
        <Col span={12}>
          <div className="title">
            {!powerPick ? (
              <IconToolTip
                content={t("translation:no_powerpick_connection")}
                icon="Warning"
                size={20}
                color="#d0021b"
              />
            ) : (
              <div>{powerPickVersion}</div>
            )}
          </div>
        </Col>
      </Row>
      <Col span={24}>
        <NextDivider className="my-6" />
      </Col>
      <Row>
        <Col span={12}>
          <div className="title">{t("translation:powerpick_warehouses")}</div>
        </Col>
        <Col span={12}>
          <div className="title">
            {!powerPick ? (
              <IconToolTip
                content={t("translation:no_powerpick_connection")}
                icon="Warning"
                size={20}
                color="#d0021b"
              />
            ) : (
              <div>
                {warehouseCount} ({warehouseLimit} {t("translation:licensed")})
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Col span={24}>
        <NextDivider className="my-6" />
      </Col>
      <Row>
        <Col span={12}>
          <div className="title">{t("translation:itempath_version")}</div>
        </Col>
        <Col span={12}>
          <div className="title">{version}</div>
        </Col>
      </Row>
      <Col span={24}>
        <NextDivider className="my-6" />
      </Col>
      <Row>
        <Col span={12}>
          <div className="title">{t("translation:version_release_date")}</div>
        </Col>
        <Col span={12}>
          <div className="title">{releaseDate}</div>
        </Col>
      </Row>
      <Col span={24}>
        <NextDivider className="my-6" />
      </Col>
      <Row>
        <Col span={12}>
          <div className="title">{t("translation:User_Time_Zone")}</div>
        </Col>
        <Col span={12}>
          {userTimeZone === "" ? (
            <div className="title">{t("translation:time_zone_not_set")}</div>
          ) : (
            <div className="title">{userTimeZone}</div>
          )}
        </Col>
      </Row>
      <Col span={24}>
        <NextDivider className="my-6" />
      </Col>
      <Row>
        <Col span={12}>
          <div className="title">{t("translation:Global_Time_Zone")}</div>
        </Col>
        <Col span={12}>
          <div className="title">{globalTimeZone}</div>
        </Col>
      </Row>
    </Box>
  );
}
