import Spin from "/app/src/components/NextUi/Spin";
import BarChart from "./barChart";
import PieChart from "./pieChart";
import LineChart from "./lineChart";
import NumberChart from "./numberChart";
import { Setting, Widget } from "/app/src/models";
import { settingService, widgetDatasetService } from "/app/src/services";
import { useQuery } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { buildParams } from "/app/src/helpers";
import CalendarChart from "./calendarChart";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";
import { useMemo } from "react";
import settingsToMarkers from "../dashboard/widget/helpers";
import { useTranslation } from "react-i18next";

/**
 * Display a chart based on the widget type.
 * Trendlines are displayed on the chart if they exist.
 */
export default function DataChart({ widget }: { widget: Widget }) {
  const { data: trendLines } = useQuery({
    queryKey: ["trendLines", widget.id],
    queryFn: () => {
      return settingService
        .getAll(buildParams({ type: "trendline", widgetId: widget.id }))
        .then(handlePromiseError);
    },
    select: (response: { settings: Setting[] }) => {
      return response.settings;
    },
    enabled: ["bar", "line", "stackedBar"].includes(widget.chartType),
    initialData: { settings: [] },
  });
  const { t } = useTranslation();

  const markers = useMemo(() => settingsToMarkers(trendLines), [trendLines]);

  const { data, isFetching, error } = useQuery({
    queryKey: ["widgetData", widget.id],
    queryFn: () => {
      return widgetDatasetService
        .getWidgetDatasetData(widget.id)
        .then(handlePromiseError);
    },
    select: (response) => {
      return response.data;
    },
    retry: false,
    placeholderData: { data: [] },
    enabled: widget.datasets.length > 0,
    keepPreviousData: true,
    refetchInterval: 1000 * 60, // 1 minute
    staleTime: 1000 * 60,
  });
  if (isFetching) {
    // return centered loading spinner
    return <Spin />;
  }
  if (error) {
    //return centered warning message
    return (
      <div className="content">
        <p style={{ textAlign: "center" }}>
          <IconBuilder icon="Warning" size={32} color="#f5222d" />
        </p>
        <p style={{ textAlign: "center" }}>{error[0]}</p>
      </div>
    );
  }
  if (!data || data.length === 0) {
    return (
      <div className="content">
        <p style={{ textAlign: "center" }}>{t("translation:no_dataset")}</p>
      </div>
    );
  }
  switch (widget.chartType) {
    case "line":
      return <LineChart trendLines={markers} data={data} />;
    case "stackedBar":
    case "bar":
      return (
        <BarChart
          trendLines={markers}
          data={data}
          isStacked={widget.chartType === "stackedBar"}
        />
      );
    case "pie":
      return <PieChart data={data} />;
    case "number":
      return <NumberChart data={data} />;
    case "calendar":
      return <CalendarChart data={data} />;
    default:
      return null;
  }
}
