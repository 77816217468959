import { Formik, FormikHelpers, FormikProps } from "formik";
import { Form, Select, SubmitButton } from "formik-antd";
import { Integration, Setting } from "/app/src/models";
import { useCallback, useState } from "react";
import FormikDisabler from "/app/src/components/generic/components/formikDisabler";
import { Col, Row } from "antd";
import { SearchFilter } from "/app/src/components/generic/components/searchFilter";
import { useTranslation } from "react-i18next";
import { simpleSchemaBuilder } from "/app/src/helpers";
import { Mapping } from "/app/src/models/mapping";
import { ValueField } from "./valueField";

interface FormValues {
  name?: string;
  operand?: string;
  value?: string;
}

/**
 *
 * @param param integration, createSetting, mappings
 * @returns  NewFilterForm
 */
export default function NewFilterForm({
  integration,
  createSetting,
  mappings,
}: {
  integration: Integration;
  createSetting: (setting: Setting) => Promise<void>;
  mappings: Mapping[];
}) {
  const { t } = useTranslation();
  const [mappingType, setMappingType] = useState<string>("");
  const mapMappings = useCallback((mapping: Mapping) => {
    return (
      <Select.Option key={mapping.id} value={mapping.id}>
        {mapping.key}
      </Select.Option>
    );
  }, []);

  const onSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      await createSetting({
        name: values.name,
        value: `${values.operand}${values.value}`,
        integrationId: integration.id,
        type: "sqlFilter",
      }).then(() => {
        actions.resetForm();
      });
    },
    [createSetting, integration.id],
  );
  const handleSelectChange = useCallback(
    (value: string) => {
      //find the mapping with the id
      const mapping = mappings.find((mapping) => mapping.id === Number(value));
      if (mapping) {
        setMappingType(mapping.dataType);
      }
    },
    [mappings],
  );
  const newFilterForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid, isSubmitting }) => (
        <Form layout="vertical">
          <FormikDisabler />
          <Row justify="start" gutter={16} className="flex items-center ">
            <Col span={8}>
              <Form.Item name="name" hasFeedback={false} label="Key">
                <SearchFilter
                  name="name"
                  placeholder={t("translation:select_mapping")}
                  mapOptionsFn={mapMappings}
                  list={mappings}
                  sort
                  onChange={handleSelectChange}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="operand" label=" " hasFeedback={false}>
                <Select name="operand" size="large">
                  {[
                    { value: "[eq]", label: "equals" },
                    { value: "[not]", label: "does not equal" },
                    { value: "[gt]", label: "greater than" },
                    { value: "[lt]", label: "less than" },
                    { value: "[gte]", label: "greater than or equal to" },
                    { value: "[lte]", label: "less than or equal to" },
                    { value: "[or]", label: "or" },
                    { value: "[nor]", label: "nor" },
                  ].map((operand) => (
                    <Select.Option key={operand.value} value={operand.value}>
                      {operand.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="value" label="Value" hasFeedback={false}>
                <ValueField mappingType={mappingType} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <SubmitButton
                className="mt-[5px]"
                disabled={!dirty || isSubmitting || !isValid}
                loading={isSubmitting}
                type="primary"
                size="large"
                block
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [t, mapMappings, mappings, handleSelectChange, mappingType],
    );
  return (
    <Formik
      component={newFilterForm}
      initialValues={{
        name: "",
        operand: "[eq]",
        value: "",
      }}
      validationSchema={simpleSchemaBuilder([
        { name: "name", type: "string", required: true },
        { name: "operand", type: "string", required: true },
        { name: "value", type: "string", required: true },
      ])}
      onSubmit={onSubmit}
    />
  );
}
