import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Row, Col } from "antd";
import {
  locationService,
  zoneService,
  locationContentService,
} from "/app/src/services";
import LocationContent from "./locationContent";
import { useTranslation } from "react-i18next";
import {
  LocationContent as LocationContentType,
  Location as LocationType,
  Zone as ZoneType,
} from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useQuery } from "@tanstack/react-query";
import Box from "/app/src/components/generic/components/box";
import { useHandleNavigationError } from "/app/src/helpers/api";

export default function SingleLocation() {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const handleNavigationError = useHandleNavigationError();

  //Get the Single Location from URL
  const { data: location } = useQuery({
    queryKey: ["location", locationId],
    queryFn: () => {
      return locationService.getSingle(locationId).catch(() => {
        handleNavigationError();
      });
    },
    enabled: Boolean(locationId),
    retry: false,
    initialData: { location: {} },
    select: (data: { location: LocationType }) => {
      return data.location;
    },
  });

  const { data: zone } = useQuery({
    queryKey: ["zone", location.zoneId],
    queryFn: () => {
      return zoneService.getSingle(location.zoneId);
    },
    enabled: Boolean(location.zoneId),
    initialData: { zone: {} },
    select: (data: { zone: ZoneType }) => {
      return data.zone;
    },
  });

  const { data: locationContents = [] } = useQuery({
    queryKey: ["locationContentsByLocation", locationId],
    queryFn: () => {
      return locationContentService.getAll(buildParams({ locationId }));
    },
    enabled: Boolean(locationId),
    initialData: { contents: [] },
    select: (data: { contents: LocationContentType[] }) => {
      return data.contents;
    },
  });
  return (
    <div className="explorer">
      <Helmet>
        <title>{location.name}</title>
      </Helmet>
      <Box>
        <Row>
          <Col span={6}>
            <h2>{t("translation:location_details")}</h2>
          </Col>
        </Row>
        <Row>
          <Col span={6} className="bigItem">
            <h4>{t("translation:location_name")}</h4>
            <p>{location.name}</p>
          </Col>
        </Row>
        <Row>
          <Col span={6} className="bigItem">
            <h4>{t("translation:type")}</h4>
            <p>{location.typeDescription}</p>
          </Col>
          <Col span={6} className="bigItem">
            <h4>{t("translation:fill_amount")}</h4>
            <p>{location.fillLevel}</p>
          </Col>
          <Col span={6} className="bigItem">
            <h4>{t("translation:marked_full")}</h4>
            <p>
              {location.isMarkedFull === 0
                ? t("translation:no")
                : t("translation:yes")}
            </p>
          </Col>

          <Col span={6} className="bigItem">
            <h4>{t("translation:zone")}</h4>
            <p>{zone.name === null ? t("translation:none") : zone.name}</p>
          </Col>
        </Row>
      </Box>
      <Box>
        <Row gutter={[24, 24]}>
          <Col span={6}>
            <h4>{t("translation:location_content")}:</h4>
          </Col>
        </Row>
        {locationContents.map((locationContent) => (
          <LocationContent
            key={location.id}
            locationContent={locationContent}
          />
        ))}
      </Box>
    </div>
  );
}
