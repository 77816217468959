import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { peripheralService } from "/app/src/services";
import { buildParams } from "/app/src/helpers/params";

import Search from "../search";
import { useTranslation } from "react-i18next";
import { Peripheral as PeripheralType } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
import getOrderByQuery from "/app/src/helpers/table";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";

/**
 * Component for displaying all peripherals
 */
export default function Peripherals() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");

  const columnHelper = createColumnHelper<PeripheralType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: () => t("translation:name"),
      }),
      columnHelper.accessor("typeDescription", {
        id: "typeDescription",
        cell: (info) => info.getValue(),
        header: () => t("translation:type"),
      }),
      columnHelper.accessor("description", {
        id: "description",
        cell: (info) => info.getValue(),
        header: () => t("translation:description"),
      }),
    ],
    [columnHelper, t],
  );

  const goToPeripheral = useCallback(
    (peripheralId: number) => {
      navigate(`/explorer/peripherals/${peripheralId}`);
    },
    [navigate],
  );

  //Gets all peripherals
  const { data: peripherals, isFetching } = useQuery({
    queryKey: ["peripherals", page, pageSize, sort, searchString],
    queryFn: () => {
      return peripheralService.getAll(
        buildParams({
          limit: pageSize,
          page,
          search: searchString,
          orderBy: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { peripherals: [] },
    select: (data: { peripherals: PeripheralType[] }) => {
      return data.peripherals;
    },
  });

  //Gets count of all peripherals
  const { data: peripheralsCount } = useQuery({
    queryKey: ["peripheralsCount", searchString],
    queryFn: () => {
      return peripheralService.getCount(buildParams({ search: searchString }));
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:peripherals")} - ItemPath</title>
      </Helmet>
      <div className="search box">
        <Search setSearchString={setSearchString} />
      </div>
      <Table
        loading={isFetching}
        rows={peripherals}
        tableColumns={columns}
        length={peripheralsCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage,
          setPageSize,
        }}
        enableRowSelection
        rowClicked={goToPeripheral}
        emptyText={t("translation:no_peripherals")}
      />
    </div>
  );
}
