import React, { useCallback } from "react";
import NextButton from "/app/src/components/NextUi/Button";
import { useTranslation } from "react-i18next";

export default function RefreshSetting({
  refresh,
  newSetting,
  setNewSetting,
}: {
  refresh: () => void;
  newSetting: boolean;
  setNewSetting: (newSetting: boolean) => void;
}) {
  const { t } = useTranslation();

  const newSettingSetter = useCallback(() => {
    refresh();
    setNewSetting(!newSetting);
  }, [refresh, newSetting, setNewSetting]);

  return (
    <NextButton color="primary" onClick={newSettingSetter} size="sm">
      {t("translation:refresh_list")}
    </NextButton>
  );
}
