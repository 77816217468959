import React, { useCallback } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  logout,
  useAuthState,
  useAuthDispatch,
} from "/app/src/contexts/authentication";

import logo from "./logo.svg";
import { useStatusContext } from "/app/src/contexts/hooks/useStatusContext";
import { Menu, Row, Col } from "antd";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";
import { useToggle } from "/app/src/hooks";
import { userService } from "/app/src/services";
import NextButton from "/app/src/components/NextUi/Button";
import NextPopover from "/app/src/components/NextUi/Popover";

export default function Header() {
  const navigate = useNavigate();

  const dispatch = useAuthDispatch(); // read dispatch method from context
  const { message, loading } = useStatusContext();
  const [toggled, setToggled] = useToggle();
  const { t, i18n } = useTranslation();
  const { user, isAuthenticated } = useAuthState(); //read the values of loading and errorMessage from context

  const changeLanguage = useCallback(
    (lng: string) => {
      i18n.changeLanguage(lng);
      if (user?.id) {
        userService.updateSingle(user.id, { language: lng });
      }
    },
    [i18n, user?.id],
  );
  const setLanguageFrench = useCallback(() => {
    changeLanguage("fr");
  }, [changeLanguage]);
  const setLanguageEnglish = useCallback(() => {
    changeLanguage("en");
  }, [changeLanguage]);

  const popoverContent = (
    <>
      <NextButton
        size="sm"
        variant="bordered"
        color={i18n.language === "en" ? "primary" : "default"}
        onClick={setLanguageEnglish}
        className="hover:border-primary-default hover:text-primary-default block mb-[5px]"
        fullWidth
      >
        English
      </NextButton>
      <NextButton
        size="sm"
        variant="bordered"
        color={i18n.language === "fr" ? "primary" : "default"}
        onClick={setLanguageFrench}
        className="hover:border-primary-default hover:text-primary-default block"
        fullWidth
      >
        Français
      </NextButton>
    </>
  );

  const triggerContent = (
    <div className="cursor-pointer">
      <IconBuilder
        icon="Globe"
        size={30}
        className="hide-mobile mt-[10px]"
        color="#82878e"
      />
    </div>
  );

  /**
   * Logout the user. This will call the logout action and navigate to the login page
   */
  const handleLogout = useCallback(() => {
    userService.logout().then(() => {
      logout(dispatch); //call the logout action
      navigate("/login"); //navigate to logout page on logout
    });
  }, [dispatch, navigate]);

  const menuItems = [
    {
      key: 1,
      label: <NavLink to="/dashboard">{t("translation:dashboard")}</NavLink>,
    },
    {
      key: 2,
      label: <NavLink to="/apps">{t("translation:apps")}</NavLink>,
    },
    {
      key: 3,
      label: <NavLink to="/explorer">{t("translation:explorer")}</NavLink>,
    },
    {
      key: 4,
      label: <NavLink to="/reports">{t("translation:reports")}</NavLink>,
    },
    {
      key: 5,
      label: <NavLink to="/workflows">{t("translation:workflows")}</NavLink>,
    },
    {
      key: 6,
      label: <NavLink to="/users">{t("translation:users")}</NavLink>,
    },
    {
      key: 7,
      label: <>{t("translation:log_out")}</>,
      onClick: () => {
        handleLogout();
      },
    },
  ];

  /**
   * Navigate to the user profile page
   */
  const handleProfileClick = useCallback(() => {
    navigate(`/users/${user.id}`);
  }, [navigate, user]);

  if (loading) {
    return null;
  } else {
    return (
      <div className="header">
        {isAuthenticated &&
          !["invalid", "locked", "restarting"].includes(message) && (
            <>
              <Row>
                <Col className="logo">
                  <img src={logo} alt="ItemPath" height="38" />
                </Col>
                <Col className="menu">
                  <nav className="links">
                    <NavLink to="/dashboard">
                      {t("translation:dashboard")}
                    </NavLink>
                    <NavLink to="/apps">{t("translation:apps")}</NavLink>
                    <NavLink to="/explorer">
                      {t("translation:explorer")}
                    </NavLink>
                    <NavLink to="/reports">{t("translation:reports")}</NavLink>
                    <NavLink to="/workflows">
                      {t("translation:workflows")}
                    </NavLink>
                    <NavLink to="/users">{t("translation:users")}</NavLink>
                  </nav>
                </Col>
                <Col>
                  <Row className="userMenu">
                    <Col>
                      <IconBuilder
                        icon="User"
                        onClick={handleProfileClick}
                        className="hide-mobile mt-[10px]"
                        size={30}
                        color="#82878e"
                      />
                    </Col>
                    <Col>
                      <NextPopover
                        radius="none"
                        triggerContent={triggerContent}
                        popoverContent={popoverContent}
                        showArrow
                      />
                    </Col>
                    <Col style={{ paddingLeft: "10px" }}>
                      <IconBuilder
                        icon="Menu"
                        onClick={setToggled}
                        color="#82878e"
                        className="menu-button mt-[5px]"
                      />
                      <NextButton
                        size="md"
                        variant="bordered"
                        color="default"
                        onClick={handleLogout}
                        className="hide-mobile hover:border-primary-default hover:text-primary-default"
                      >
                        {t("translation:log_out")}
                      </NextButton>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className={toggled ? "vertical-menu" : "hidden"}>
                <Menu
                  defaultSelectedKeys={["1"]}
                  defaultOpenKeys={["sub1"]}
                  mode="vertical"
                  onClick={setToggled}
                  items={menuItems}
                />
              </div>
            </>
          )}
      </div>
    );
  }
}
