import { useTranslation } from "react-i18next";
import { Integration } from "/app/src/models";
import { useCallback } from "react";
import { Form, Select, SubmitButton } from "formik-antd";
import { Formik, FormikProps } from "formik";
import { Col, Row } from "antd";
import SQLTable from "./sqlTable";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { connectionService, integrationService } from "/app/src/services";
import { buildParams } from "/app/src/helpers";
import { handlePromiseError } from "/app/src/helpers/api";

interface ConnectionDetailsFormValues {
  connectionId: number | undefined;
  sqlTable: string | undefined;
}

/**
 *
 * @param param0 integration
 * @returns Connection details for the integration
 */
export default function ConnectionDetails({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutateAsync: updateIntegration } = useMutation({
    mutationFn: (updatedIntegration: Integration) => {
      return integrationService
        .updateSingle(updatedIntegration.id, updatedIntegration)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(["integration", integration.id], response);
    },
  });

  const onSubmit = useCallback(
    async (values: ConnectionDetailsFormValues) => {
      return await updateIntegration({
        id: integration.id,
        connectionId: values.connectionId,
        token: values.sqlTable,
      });
    },
    [integration.id, updateIntegration],
  );

  const { data: connections } = useQuery({
    queryKey: [
      "connections",
      buildParams({ type: "[or]REST;GraphQL;MSSQL;IBM2" }),
    ],
    queryFn: () =>
      connectionService.getAll(
        buildParams({ type: "[or]REST;GraphQL;MSSQL;IBM2" }),
      ),
    initialData: { connections: [] },
    select: (data) => data.connections,
  });

  const updateConnectionDetailsForm: (
    props: FormikProps<ConnectionDetailsFormValues>,
  ) => JSX.Element = useCallback(
    ({ dirty, isSubmitting }) => (
      <Form layout="vertical">
        <Row className="pb-3">
          <Col className="text-lg font-semibold" span={8}>
            {t("translation:connection_details")}
          </Col>
          <Col span={8} offset={8}>
            <Row justify="end">
              <SubmitButton
                disabled={!dirty || isSubmitting}
                loading={isSubmitting}
                type="primary"
              >
                {t("translation:save")}
              </SubmitButton>
            </Row>
          </Col>
        </Row>
        <Row justify="start" gutter={16} className="flex items-center">
          <Col span={8}>
            <h3>{t("translation:linked_connection")}</h3>
            <p>{t("translation:linked_connection_description")}</p>
          </Col>
          <Col span={16}>
            <Form.Item
              name="connectionId"
              label="Choose a Connection"
              hasFeedback={false}
            >
              <Select
                name="connectionId"
                placeholder={t("translation:select_connection")}
                style={{ width: "100%" }}
                size="large"
              >
                {connections.map((connection) => (
                  <Select.Option key={connection.id} value={connection.id}>
                    {connection.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <SQLTable />
      </Form>
    ),
    [connections, t],
  );

  return (
    <Formik
      component={updateConnectionDetailsForm}
      initialValues={{
        connectionId: integration.connectionId,
        sqlTable: integration.token,
      }}
      onSubmit={onSubmit}
    />
  );
}
