import React, { useCallback, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import { integrationService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { App, Integration } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
import { useNumberId } from "/app/src/hooks/useNumberId";
import NextButton from "/app/src/components/NextUi/Button";
import ImportTemplate from "./importTemplate";
export default function Controls({
  app,
  name,
  children,
  toggled,
  setToggled,
  canImport = false,
}: {
  app: App;
  name: string;
  children?: React.ReactNode;
  toggled: boolean;
  setToggled: (toggled: boolean) => void;
  canImport?: boolean;
}) {
  const { t } = useTranslation();

  const { integrationId } = useNumberId();

  //get single integration
  const { data: integration } = useQuery({
    queryKey: ["integration", Number(integrationId)],
    queryFn: () => {
      return integrationService.getSingle(integrationId);
    },
    enabled: Boolean(integrationId),
    initialData: { integration: {} },
    select: (data: { integration: Integration }) => {
      return data.integration;
    },
  });

  useEffect(() => {
    if (integrationId) {
      if (toggled) {
        //toggle the new integration form to hidden
        setToggled(false);
      }
    }
  }, [setToggled, toggled, integrationId]);

  const toggleOn = useCallback(() => {
    setToggled(true);
  }, [setToggled]);

  const toggleOff = useCallback(() => {
    setToggled(false);
  }, [setToggled]);

  return (
    <>
      <div className="app" id={name}>
        <div className="controls">
          <div className="icon"> </div>

          {integration?.id ? (
            <div className="title">
              <Link to={`/apps/${app.id}`}>{app.name}</Link>
              <span className="separator">{">"}</span>
              <h1>{integration?.name}</h1>
            </div>
          ) : (
            <div className="title">
              <h1>{app.name}</h1>
            </div>
          )}

          <div className="controlButtons flex items-center space-x-4">
            {canImport && !integration?.id && <ImportTemplate appId={app.id} />}
            {integration?.id ? (
              <div />
            ) : toggled ? (
              <NextButton
                size="sm"
                variant="bordered"
                color="primary"
                onClick={toggleOff}
              >
                {t("translation:cancel")}
              </NextButton>
            ) : (
              <NextButton
                size="sm"
                variant="solid"
                color="primary"
                onClick={toggleOn}
              >
                {t("translation:new_integration")}
              </NextButton>
            )}
          </div>
        </div>
      </div>
      {toggled ? children : ""}
      <Outlet />
    </>
  );
}
